(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlDateFiltersPopup', {
            template: require('./DateFiltersPopupComponent.html'),
            controller: ['$timeout', '$scope', '$element', 'ToastFactory', DateFiltersPopupController],
            controllerAs: 'ctrl',
            bindings: {
                onFilterSelected: '<',
                options: '<',
                startDate: '<',
                endDate: '<',
                removeSelection: '<'
            }
        });

    function DateFiltersPopupController($timeout, $scope, $element, ToastFactory) {
        const ctrl = this;

        ctrl.CUSTOM_OPTION_ID = 6;

        ctrl.$onInit = init;
        ctrl.select = select;
        ctrl.applyCustom = applyCustom;
        ctrl.clearFilter = clearFilter;
        ctrl.isCustomDateSelected = isCustomDateSelected;
        ctrl.onClose = onClose;

        function init() {
            ctrl.prevSelection = ctrl.options.find(option => option.selected);
        }

        function select(item) {
            if (item.id !== ctrl.CUSTOM_OPTION_ID) {
                ctrl.onFilterSelected && ctrl.onFilterSelected(item);
                $timeout(() => {
                    ctrl.closeAction && ctrl.closeAction();
                });
            }
        }

        function applyCustom() {
            if (ctrl.startDate && ctrl.endDate && !moment(ctrl.startDate).isSameOrBefore(ctrl.endDate)) {
                ToastFactory.error('DATE_FILTER.START_DATE_ERROR');
            } else {
                ctrl.onFilterSelected && ctrl.onFilterSelected(ctrl.selectedItem, ctrl.startDate, ctrl.endDate);
                ctrl.closeAction && ctrl.closeAction();
            }
        }

        function isCustomDateSelected() {
            return ctrl.options.find(x => x.selected)?.id === ctrl.CUSTOM_OPTION_ID;
        }

        function clearFilter() {
            ctrl.onFilterSelected && ctrl.onFilterSelected();
            ctrl.closeAction && ctrl.closeAction();
        }

        function onClose() {
            if (ctrl.selectedItem?.id === ctrl.CUSTOM_OPTION_ID && !ctrl.startDate && !ctrl.endDate) {
                if (ctrl.prevSelection) {
                    ctrl.options.find(option => option.id === ctrl.selectedItem.id).selected = false;
                    ctrl.options.find(option => option.id === ctrl.prevSelection.id).selected = true;
                } else {
                    ctrl.removeSelection && ctrl.removeSelection();
                }
            }
            $scope.$destroy();
            $element.remove();
        }
    }
})();