(() => {
    'use strict';

    angular.module('App')
        .component('profileOverview', {
            template: require('./ProfileOverviewComponent.tpl.html'),
            controller: ['$timeout', '$filter', 'MODULE_STATE', 'MODULE_ID', 'Page', 'Profile', 'BookmarkService',
                'WalkthroughFactory', 'HeaderButtonsFactory', 'PointsLogService', 'IconService', 'ResponsiveService',
                'ProfileOverviewService', 'ConfirmPopupService', 'AttachmentsViewerService', '$rootScope',
                'ProfileSettingsService', 'DepartmentSwapService', 'events', 'LEAVE_TYPES_ICONS', 'LEAVE_TYPES_LABELS',
                'LeaveRegistrationService', 'TranslatableEntityService', 'ToastFactory', 'TrainingService',
                ProfileOverviewController],
            controllerAs: 'ctrl',
            bindings: {
                token: '<',
                isOverview: '<',
                closePopup: '<',
                profileData: '<'
            }
        });

    function ProfileOverviewController($timeout, $filter, MODULE_STATE, MODULE_ID, Page, Profile, BookmarkService,
                                       WalkthroughFactory, HeaderButtonsFactory, PointsLogService, IconService, ResponsiveService,
                                       ProfileOverviewService, ConfirmPopupService, AttachmentsViewerService, $rootScope,
                                       ProfileSettingsService, DepartmentSwapService, events, LEAVE_TYPES_ICONS, LEAVE_TYPES_LABELS,
                                       LeaveRegistrationService, TranslatableEntityService, ToastFactory, TrainingService) {

        var ctrl = this,
            screenResizeWatcher,
            departmentSwapWatcher,
            pressTimer,
            longPressTriggered;

        ctrl.ResponsiveService = ResponsiveService;
        ctrl.DepartmentSwapService = DepartmentSwapService;

        ctrl.$onInit = onInit;
        ctrl.$onDestroy = onDestroy;

        ctrl.openProfileImage = openProfileImage;
        ctrl.openPointLog = PointsLogService.openPopup;
        ctrl.openHighScore = openHighScore;
        ctrl.contactLinkMouseDown = contactLinkMouseDown;
        ctrl.contactLinkMouseUp = contactLinkMouseUp;

        ctrl.logout = logout;
        ctrl.getLeaveIcon = getLeaveIcon;
        ctrl.getLeaveType = getLeaveType;
        ctrl.expandCollapseMessage = expandCollapseMessage;
        ctrl.toggleTranslation = toggleTranslation;

        if (!ctrl.token) {
            ctrl.profile = Profile.getProfile();
        }

        function onInit() {
            ctrl.isDesktop = ResponsiveService.isDesktop();
            screenResizeWatcher = ResponsiveService.watch(() => {
                ctrl.isDesktop = ResponsiveService.isDesktop();
                initScreenSizeData();
            })

            ctrl.BookmarkService = BookmarkService;
            ctrl.isAllDataLoaded = false;

            if (!ctrl.token) {
                initData(ctrl.profile);

                ctrl.isLoadingProfileData = true;
                ProfileSettingsService.getFullProfile().then(res => {
                    initData(res);
                    ctrl.isLoadingProfileData = false;
                }).catch(() => {
                    ctrl.isLoadingProfileData = false;
                });
            } else {
                initData(ctrl.profileData.data);
            }
        }

        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
        }

        function expandCollapseMessage() {
            ctrl.isExpanded = !ctrl.isExpanded;
        }

        function toggleTranslation() {
            if (ctrl.isTranslationLoading) {
                return;
            }

            const translateRequest = () => {
                ctrl.isTranslationLoading = true;
                return LeaveRegistrationService.translateLeaveMessage(ctrl.profile.ActiveLeave.Token).then(res => res.data.Comment);
            };

            TranslatableEntityService.switchCurrentLocale(ctrl.profile.ActiveLeave.TranslatableEntity, translateRequest)
                .then((translatableEntity) => {
                    ctrl.profile.ActiveLeave.TranslatableEntity = translatableEntity;
                    ctrl.isTranslationLoading = false;
                    checkOverflow();

                    if (ctrl.isOverflowing) {
                        ctrl.isExpanded = true;
                    }
                }).catch(() => {
                ctrl.isTranslationLoading = false;
                ToastFactory.error('ERROR.GENERAL');
            });
        }

        function initData(data) {
            ctrl.profile = data;

            initScreenSizeData();

            const currenUserToken = Profile.getProfile().UserToken;
            const isOwnProfile = ctrl.profile.UserToken === currenUserToken;

            ctrl.contactLinks = [];
            if (!ctrl.isOverview) {
                ctrl.contactLinks = _.filter(ProfileOverviewService.initContactButtons(ctrl.profile, isOwnProfile, contactLinkCloseCallback), {show: true});
            }

            ctrl.showContact = ctrl.contactLinks.length > 0;

            ctrl.highscoreLevel = null;
            if (ctrl.profile.HighscoreLevelId && ctrl.profile.HighscoreLevelName && ctrl.profile.HighscoreLevelName.length > 0) {
                ctrl.highscoreLevel = {
                    show: true,
                    style: {
                        backgroundColor: ctrl.profile.HighscoreLevelColor
                    }
                };
            }

            ctrl.showUserCustomFields = false;

            if (ctrl.profile.UserCustomFields && ctrl.profile.UserCustomFields.length > 0) {
                ctrl.showUserCustomFields = _.some(ctrl.profile.UserCustomFields, 'Value');
            }

            if (isOwnProfile && ctrl.isOverview) {
                initModuleLinks();

                ctrl.showDepartmentSwap = ctrl.profile.HasMultiDepartment === true;
                if (ctrl.showDepartmentSwap) {
                    ctrl.filteredDepartmentName = ctrl.profile.FilterDepartmentName;
                    ctrl.isDepartmentSwapped = ctrl.filteredDepartmentName && ctrl.filteredDepartmentName.length;

                    departmentSwapWatcher = $rootScope.$on(events.DEPARTMENT_CHANGED, () => {
                        ctrl.filteredDepartmentName = Profile.getProfile().FilterDepartmentName;
                        ctrl.isDepartmentSwapped = ctrl.filteredDepartmentName && ctrl.filteredDepartmentName.length;
                    });
                }

                TrainingService.setIsNewTraining(ctrl.profile.NewTrainingInAppEnabled);
            } else {
                ctrl.showDepartmentSwap = false;
            }

            if (ctrl.profile.ActiveLeave) {
                ctrl.profile.ActiveLeave.TranslatableEntity._currentLocale =
                    selectCurrentLocale(ctrl.profile.ActiveLeave.TranslatableEntity);
                ctrl.profile.ActiveLeave.TranslatableEntity._currentCultureId = ctrl.profile.ActiveLeave.TranslatableEntity._currentLocale.CultureId;
                checkOverflow();
            }

            ctrl.isAllDataLoaded = Profile.isAllDataLoaded(ctrl.profile);
        }

        function contactLinkMouseUp(event, item) {
            $timeout.cancel(pressTimer);
            if (!longPressTriggered) {
                item.action(event);
            }
        }

        function contactLinkMouseDown(event, item) {
            longPressTriggered = false;
            pressTimer = $timeout(function () {
                handleLongPress(event, item);
                longPressTriggered = true;
            }, 600);
        }

        function handleLongPress(event) {
            const targetBtn = event.target.closest('li');
            if (targetBtn) {
                const selectedSubtext = targetBtn.querySelector('.subtext');
                window.getSelection().selectAllChildren(selectedSubtext);
            }
        }

        function checkOverflow() {
            ctrl.shortMessage = truncateToClosestWord(ctrl.profile.ActiveLeave.TranslatableEntity._currentLocale.Comment);
            ctrl.longMessage = ctrl.profile.ActiveLeave.TranslatableEntity._currentLocale.Comment;
            ctrl.isOverflowing = ctrl.shortMessage.length !== ctrl.longMessage.length;

            function truncateToClosestWord(text) {
                if (text.length <= 100) return text.trim();

                let truncated = text.slice(0, 100);
                const lastSpaceIndex = truncated.lastIndexOf(" ");

                if (lastSpaceIndex > 0) {
                    truncated = truncated.slice(0, lastSpaceIndex);
                }

                return truncated.trim();
            }
        }

        function initModuleLinks() {
            ctrl.moduleLinks = [];

            if (ctrl.BookmarkService.isEnabled()) {
                ctrl.moduleLinks.push({
                    name: 'BOOKMARK.TITLE',
                    icon: 'bookmark',
                    showOnDesktop: false,
                    iconPrefix: '',
                    onClick: () => Page.stateGo('bookmarks')
                });
            }

            if (ctrl.profile.IsRemindersEnabled) {
                ctrl.moduleLinks.push({
                    name: 'REMINDERS.REMINDERS',
                    icon: 'alarm-clock',
                    showOnDesktop: true,
                    onClick: () => {
                        Page.stateGo('reminders');
                    }
                });
            }

            if (ctrl.profile.ModuleLinks && ctrl.profile.ModuleLinks.length > 0) {
                _.forEach(ctrl.profile.ModuleLinks, link => {
                    ctrl.moduleLinks.push({
                        name: link.Name,
                        icon: IconService.getIconByModuleId(link.ModuleId),
                        showOnDesktop: true,
                        onClick: () => openModuleLink(link)
                    });
                });
            }
        }

        function initScreenSizeData() {
            if (ctrl.isOverview) {
                if (ctrl.isDesktop) {
                    HeaderButtonsFactory.resetButtonsList();
                } else {
                    HeaderButtonsFactory.createButtonsList(() => [
                        {
                            icon: 'settings',
                            onClick: openSettings
                        }
                    ]);
                }
            }

            if (ctrl.profile.ShowBadgesOnProfile) {
                const showCount = ctrl.isDesktop ? 5 : 4;
                const initialBadges = _.take(ctrl.profile.Badges, showCount);

                ctrl.trainingBadges = {
                    show: initialBadges.length > 0 || ctrl.isOverview,
                    showCount: showCount,
                    canShowAll: initialBadges.length !== ctrl.profile.Badges.length,
                    showAll: false,
                    badges: initialBadges,
                    toggleShowAll: () => {
                        if (ctrl.trainingBadges.canShowAll) {
                            ctrl.trainingBadges.showAll = !ctrl.trainingBadges.showAll

                            if (ctrl.trainingBadges.showAll) {
                                ctrl.trainingBadges.badges = ctrl.profile.Badges;
                            } else {
                                ctrl.trainingBadges.badges = _.take(ctrl.profile.Badges, ctrl.trainingBadges.showCount);
                            }
                        }
                    }
                };
            } else {
                ctrl.trainingBadges = {
                    show: false
                };
            }
        }

        function onDestroy() {
            HeaderButtonsFactory.resetButtonsList();
            screenResizeWatcher();
            if (departmentSwapWatcher) {
                departmentSwapWatcher();
            }
        }

        function openProfileImage(media) {
            AttachmentsViewerService.openViewerWithMedia([media]);
        }

        function openHighScore() {
            if (ctrl.isOverview && ctrl.profile.DefaultHighscoreModuleToken) {
                Page.stateGo(MODULE_STATE[MODULE_ID.HIGHSCORE], ctrl.profile.DefaultHighscoreModuleToken);
            }
        }

        function openModuleLink(link) {
            switch (link.ModuleId) {
                case MODULE_ID.WALKTHROUGH:
                    WalkthroughFactory.loadWalkthrough();
                    break;
                default:
                    Page.stateGo(MODULE_STATE[link.ModuleId], link.AccountModuleToken);
            }
        }

        function logout() {
            if (ctrl.isOverview) {
                var opts = {
                    message: $filter('translate')('PROFILE.LOGOUT_LABEL')
                };

                ConfirmPopupService.open(opts).then(function () {
                    Page.stateGo('logout');
                });
            }
        }

        function contactLinkCloseCallback() {
            ctrl.closePopup && ctrl.closePopup();
            $rootScope.$broadcast('ClosePopups');
        }

        function getLeaveType(id) {
            return LEAVE_TYPES_LABELS[id];
        }

        function getLeaveIcon(id) {
            return LEAVE_TYPES_ICONS[id];
        }

        function openSettings() {
            Page.stateGo('profileSettings');
        }
    }
})();