(() => {
    'use strict';

    angular.module('App')
        .component('leaveRegistration', {
            template: require('./LeaveRegistrationComponent.html'),
            controller: ['$scope', 'LeaveRegistrationService', 'ToastFactory', 'PopupWrapperService',
                'LEAVE_TYPES_ICONS', 'LEAVE_TYPES_LABELS', LeaveRegistrationComponentController],
            controllerAs: 'ctrl'
        });

    function LeaveRegistrationComponentController($scope, LeaveRegistrationService, ToastFactory, PopupWrapperService,
                                                  LEAVE_TYPES_ICONS, LEAVE_TYPES_LABELS) {
        const ctrl = this;
        let destroySelectedYearWatcher;

        ctrl.$onInit = onInit;
        ctrl.openCreateLeave = openCreateLeave;
        ctrl.changeTab = changeTab;
        ctrl.formatDate = formatDate;
        ctrl.openLeave = openLeave;
        ctrl.calculateDuration = calculateDuration;

        ctrl.leaveTypesIcons = LEAVE_TYPES_ICONS;
        ctrl.leaveTypesLabels = LEAVE_TYPES_LABELS;

        function onInit() {
            loadLeaves();
            destroySelectedYearWatcher = $scope.$watch('ctrl.currentYear', () => {
                if (ctrl.currentYear) {
                    filterLeaves(ctrl.leaves);
                }
            }, true);
        }

        function filterLeaves(leaves) {
            ctrl.currentYearLeaves = leaves.filter(leave => leave.Years.includes(ctrl.currentYear) && leave.IsActive);
            ctrl.upcomingYearLeaves = leaves.filter(leave => leave.Years.includes(ctrl.currentYear) && leave.IsUpcoming);
            ctrl.pastYearLeaves = leaves.filter(leave => leave.Years.includes(ctrl.currentYear) && leave.IsPast);
        }

        function loadLeaves() {
            ctrl.isLoading = true;
            LeaveRegistrationService.getRegisteredLeaves().then(({data}) => {
                ctrl.leaves = data.Leaves;
                ctrl.years = data.Years;

                if (data.Years?.length && (!ctrl.currentYear || !data.Years.includes(ctrl.currentYear))) {
                    const yearNow = moment().year();
                    const currentYear = data.Years.find(year => year === yearNow);
                    currentYear ? changeTab(currentYear) : changeTab(data.Years[0]);
                } else {
                    filterLeaves(ctrl.leaves);
                }
            }).catch(() => {
                ToastFactory.error('ERROR');
            }).finally(() => ctrl.isLoading = false);
        }

        function openCreateLeave() {
            if (ctrl.pageLoading) {
                return;
            }

            ctrl.pageLoading = true;
            LeaveRegistrationService.getLeaveRegistrationCreatePage().then(({data}) => {
                PopupWrapperService.createDynamic(
                    '<leave-registration-popup-component' +
                    ' data="data" ' +
                    ' on-close="onClose">' +
                    '</leave-registration-popup-component>',
                    {
                        data: data,
                        onClose: onPopupClosed
                    }
                );
            }).finally(() => ctrl.pageLoading = false);
        }

        function openLeave(leave) {
            if (ctrl.pageLoading) {
                return;
            }

            ctrl.pageLoading = true;
            LeaveRegistrationService.getLeaveRegistrationEditPage(leave.LeaveRegistrationToken).then(({data}) => {
                PopupWrapperService.createDynamic(
                    '<leave-registration-popup-component' +
                    ' data="data" ' +
                    ' is-edit="isEdit" ' +
                    ' on-close="onClose">' +
                    '</leave-registration-popup-component>',
                    {
                        data: data,
                        isEdit: true,
                        onClose: onPopupClosed
                    }
                );
            }).finally(() => ctrl.pageLoading = false);
        }

        function calculateDuration(minutes) {
            const minutesInHour = 60;
            const minutesInDay = minutesInHour * 24; // 1440 minutes in a day
            const minutesInMonth = minutesInDay * 30; // 43200 minutes in a 30-day month
            const minutesInYear = minutesInMonth * 12; // 518400 minutes in a year

            let duration;
            let result = "";

            if (minutes >= minutesInYear) {
                const years = Math.round(minutes / minutesInYear);
                duration = moment.duration(years, 'years');
                result = duration.humanize(); 
            } else if (minutes >= minutesInMonth) {
                const months = Math.round(minutes / minutesInMonth);
                duration = moment.duration(months, 'months');
                result = duration.humanize(); 
            } else if (minutes >= minutesInDay) {
                const days = Math.round(minutes / minutesInDay);
                duration = moment.duration(days, 'days');
                result = duration.humanize(); 
            } else if (minutes >= minutesInHour) {
                const hours = Math.round(minutes / minutesInHour);
                duration = moment.duration(hours, 'hours');
                result = duration.humanize(); 
            } else if (minutes > 0) {
                duration = moment.duration(minutes, 'minutes');
                result = duration.humanize(); 
            }

            return result.trim();
        }

        function onPopupClosed() {
            loadLeaves();
        }

        function changeTab(year) {
            ctrl.currentYear = year;
        }

        function formatDate(date, allDay) {
            const dateTimeText = (date) => moment(new Date(date)).format('DD MMM HH:mm');
            const dateText = (date) => moment(new Date(date)).format('DD MMM');

            return allDay ? dateText(date) : dateTimeText(date);
        }
    }
})();