(() => {
    angular
        .module('App')
        .component('activityWidget', {
            template: require('./activityWidget.html'),
            controllerAs: 'ctrl',
            bindings: {
                allUserCount: '<',
                relevantUserCount: '<',
                activeLastWeekCount: '<',
                activeLastMonthCount: '<',
                filterModel: '<'
            },
            controller: ['$scope', 'UserManagementService', ActivityWidgetController]
        })

    function ActivityWidgetController($scope, UserManagementService) {
        const ctrl = this;

        const labels = getLabels();

        ctrl.$onInit = init;

        $scope.$watch('ctrl.filterModel', handleFilterChange);

        function init() {
            ctrl.activityInfo = '';
            ctrl.activityTitle = '';
            refreshWidget();
        }

        function handleFilterChange(newValue, oldValue) {
            if (newValue !== oldValue) {
                refreshWidget();
            }
        }

        function refreshWidget() {
            updateActivityStatus();
            updateLabels();
            updatePercentages();
        }

        function updateActivityStatus() {
            if (!ctrl.filterModel) {
                ctrl.activityStatus = null;
                return;
            }
        
            if (ctrl.filterModel.filters.Recent) {
                ctrl.activityStatus = 4; //enum for ActivityStatus.HadActivity
                return;
            }
        
            ctrl.activityStatus = ctrl.filterModel.filters.ActivityStatus.value || null;
            return;
        }

        function updatePercentages() {
            if (ctrl.relevantUserCount !== null) {
                ctrl.relevantPercent = calculatePercentage(ctrl.relevantUserCount, ctrl.allUserCount);
            } else {
                ctrl.lastWeekPercent = calculatePercentage(ctrl.activeLastWeekCount, ctrl.allUserCount);
                ctrl.lastMonthPercent = calculatePercentage(ctrl.activeLastMonthCount, ctrl.allUserCount);
            }
        }
        
        function calculatePercentage(part, total) {
            if (typeof part !== 'number' || typeof total !== 'number' || total <= 0) {
                return 0;
            }
            return Math.round((part / total) * 100);
        }

        function updateLabels() {
            if (ctrl.activityStatus === null) return;
            
            const option = UserManagementService.getActivityFilterOptions().find(option => option.value === ctrl.activityStatus);
            ctrl.activityClass = option.activityIndicator;

            const label = labels.find(label => label.value === option.value)
            if (label) {
                ctrl.activityTitle = label.title;
                ctrl.activityInfo = label.info;
            }
        }

        function getLabels() {
            // values match enum ActivityStatus
            return [
                {id: 1, value: 1, title: 'ACTIVITY_WIDGET.TITLE_ACTIVE_TODAY', info: 'ACTIVITY_WIDGET.INFO_ACTIVE_TODAY'},
                {id: 2, value: 2, title: 'ACTIVITY_WIDGET.TITLE_ACTIVE_7_DAYS', info: 'ACTIVITY_WIDGET.INFO_ACTIVE_7_DAYS'},
                {id: 3, value: 3, title: 'ACTIVITY_WIDGET.TITLE_ACTIVE_30_DAYS', info: 'ACTIVITY_WIDGET.INFO_ACTIVE_30_DAYS'},
                {id: 4, value: 4, title: 'ACTIVITY_WIDGET.TITLE_ACTIVE_EVER', info: 'ACTIVITY_WIDGET.INFO_ACTIVE_EVER'},
                {id: 5, value: 5, title: 'ACTIVITY_WIDGET.TITLE_ACTIVE_NEVER', info: 'ACTIVITY_WIDGET.INFO_ACTIVE_NEVER'},
                {id: 6, value: 7, title: 'ACTIVITY_WIDGET.TITLE_NOT_ACTIVE_7_DAYS', info: 'ACTIVITY_WIDGET.INFO_NOT_ACTIVE_7_DAYS'},
                {id: 7, value: 8, title: 'ACTIVITY_WIDGET.TITLE_NOT_ACTIVE_30_DAYS', info: 'ACTIVITY_WIDGET.INFO_NOT_ACTIVE_30_DAYS'},
            ];
        }
    }
})();