(function () {
    'use strict';
    angular
        .module('Relesys.Ui')
        .factory('ActionSheetService', ['$rootScope', '$compile', '$animate', '$q', '$document', '$log', '$timeout',
            '$window', '$injector', 'ToastFactory', 
            function ($rootScope, $compile, $animate, $q, $document, $log, $timeout, 
                $window, $injector, ToastFactory) {

                return {
                    create: create
                };

                function create(buttons, parent, header, module, onDestroy) {
                    ToastFactory.clear();

                    var $element, hasAnimated = false, touchElement, touchElementHammer,
                        scope = $rootScope.$new(true),
                        isDesktop = $window.matchMedia("screen and (min-width: 1025px)").matches;

                    try {
                        var Page = $injector.get('Page');
                    } catch (e) {}

                    // Get coordinates for parent element if provided (for desktop view)
                    var $parent = isDesktop && parent;
                    var rect = $parent && parent.getBoundingClientRect() || {};
                    
                    const buttonsHeight = getButtonLength(buttons) * 50;
                    //get height from custom css properties, remove px at the end  
                    const sheetMaxHeight = Number(getComputedStyle(document.body).getPropertyValue('--max-desktop-sheet-height')?.slice(0,-2));
                    const calculatedHeight = buttonsHeight > sheetMaxHeight ? sheetMaxHeight : buttonsHeight; 
                    
                    // Check that buttons are supplied
                    if (!angular.isArray(buttons)) {
                        $log.error('ActionSheet expect an array of buttons');
                    }

                    // Make Button Groups
                    scope.groups = angular.isArray(buttons[0]) ? buttons : [buttons];

                    // Prepare position for action menu
                    scope.attached = !!($parent && rect.bottom);
                    
                    scope.moduleToken = module?.token;
                    scope.moduleName = module?.name;

                    if (scope.attached) {
                        if ($rootScope.isRTL) {
                            if ($window.innerHeight > (rect.bottom + calculatedHeight)) {
                                scope.rect = {
                                    top: rect.bottom + 5,
                                    right: rect.left,
                                    attachedPosition: 'bottom'
                                };
                            } else {
                                scope.rect = {
                                    top: rect.top - calculatedHeight - 5,
                                    right: rect.left + rect.width / 2,
                                    attachedPosition: 'top'
                                };
                            }
                        } else {
                            if (($window.innerHeight > (rect.bottom + calculatedHeight))) {
                                scope.rect = {
                                    top: rect.bottom,
                                    right: $window.innerWidth - (rect.right - rect.width / 2),
                                    attachedPosition: 'bottom'
                                };
                            } else {
                                scope.rect = {
                                    top: rect.top - calculatedHeight - 5,
                                    right: $window.innerWidth - (rect.right - rect.width / 2),
                                    attachedPosition: 'top'
                                };
                            }
                        }

                    }

                    scope.head = header || {};
                    scope.onClick = function ($event, button, media) {
                        if (hasAnimated) {
                            if (button) {
                                if (angular.isFunction(button.onClick)) {
                                    button.onClick({button: button, media: media});
                                }
                            }

                            $timeout(function () {
                                // Revert overlay device color adjustments
                                if (Page) {
                                    Page.revertOverlayColorAdjustment();
                                }

                                // Animate the leave/hide
                                scope.$destroy();
                                touchElementHammer && touchElementHammer.destroy();
                                document.getElementsByTagName('body')[0].classList.remove('action-disable-scroll');
                                $animate.leave($element);
                            }, 100);
                        }
                    };

                    function getButtonLength(buttons) {
                        let length = 0;

                        buttons.forEach(button => {
                            if (Array.isArray(button)) {
                                length += button.length
                            } else {
                                length++
                            }
                        })

                        return length;
                    }

                    $element = $compile('<action-sheet groups="groups" attached="attached" rect="rect" head="head"' +
                        ' click="onClick" module-token="moduleToken" module-name="moduleName"></action-sheet>')(scope);

                    scope.$on('$destroy', () => {
                        onDestroy && onDestroy();
                    });

                    return {
                        show: function () {
                            if (scope.groups.length > 0) {
                                // Adjust colors for the overlay to match for every device
                                if (Page) {
                                    Page.overlayColorAdjustment('#000', '#fff');
                                }

                                $animate.enter($element, $document[0].body, $document[0].body.lastChild)
                                    .then(function () {
                                        hasAnimated = true;

                                        if (isDesktop) {
                                            $window.onscroll = function (ev) {
                                                scope.onClick(ev);
                                            };
                                        }

                                        if (!isDesktop) {
                                            document.getElementsByTagName('body')[0].classList.add('action-disable-scroll');
                                     
                                            touchElement = $element.find('.actionsheet-header').get(0) ?? $element.find('.actionsheet-divider').get(0);
                                            touchElementHammer = new Hammer(touchElement);

                                            touchElementHammer.get('pan').set({threshold: 0});
                                            touchElementHammer.on('pandown', function (ev) {
                                                scope.onClick(ev);
                                                touchElementHammer.destroy();
                                                document.getElementsByTagName('body')[0].classList.remove('action-disable-scroll');
                                            });
                                        }
                                    });
                            }
                        }
                    };
                }
            }
        ]);
})();