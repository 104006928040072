(function () {
    'use strict';

    angular.module('App')
        .component('chatSettings', {
            template: require('./ChatSettingsComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$timeout', '$filter', '$element', 'ChatPopupsService', 'ChatConnectionService',
                'ChatDataService', 'ProfileViewerService', 'ConfirmPopupService', 'ToastFactory',
                'Profile', 'ContentSafetyWarningService', 'AttachmentsViewerService', 'ActionSheetService', ChatSettingsController],
            bindings: {
                channel: '<',
                closeConversation: '<',
            }
        });

    function ChatSettingsController($rootScope, $timeout, $filter, $element, ChatPopupsService, ChatConnectionService,
                                    ChatDataService, ProfileViewerService, ConfirmPopupService, ToastFactory,
                                    Profile, ContentSafetyWarningService, AttachmentsViewerService, ActionSheetService) {
        const ctrl = this,
            pushOpenWatcher = $rootScope.$on('CHAT.OPEN_FROM_PUSH', () => close()),
            destroyChannelUpdatedWatcher = $rootScope.$on('CHAT.CHANNEL_UPDATED', (ev, channel) => {
                if (ctrl.channel.item.isGroupChat && channel.item.channelId === ctrl.channel.item.channelId) {
                    ctrl.channelName = channel.item.name;
                }
            });

        let popup;

        ctrl.currentUser = Profile.getProfile()
        ctrl.isCurrentUserAdmin = isAdmin(ctrl.currentUser.UserToken);
        ctrl.editingTitle = false;
        ctrl.saving = false;
        ctrl.showAllMembers = false;
        ctrl.visibleMembersCount = 3;
        ctrl.notificationsDisabled = !ctrl.channel.item.notificationsEnabled;

        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpenPopup = onOpenPopup;
        ctrl.isAdmin = isAdmin;
        ctrl.titleFocus = titleFocus;
        ctrl.editIconClick = editIconClick;
        ctrl.updateTitle = updateTitle;
        ctrl.setNotificationSetting = setNotificationSetting;
        ctrl.orderByCreator = orderByCreator;
        ctrl.isLeaveEnabled = isLeaveEnabled;
        ctrl.leaveChannel = leaveChannel;
        ctrl.showProfile = showProfile;
        ctrl.openAddUsers = openAddUsers;
        ctrl.openChatMembers = openChatMembers;
        ctrl.close = close;
        ctrl.isBlockedUser = isBlockedUser;
        ctrl.openGroupImage = openGroupImage;
        ctrl.onImageEdit = onImageEdit;
        ctrl.onImageUploaded = onImageUploaded;
        ctrl.isUserNotBlocked = isUserNotBlocked;
        ctrl.getNonBlockedUser = getNonBlockedUser;

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function onOpenPopup() {
            ChatConnectionService.onChannelItemEvents(
                ctrl.channel.item.channelId,
                channelEventsCallback
            )

            ctrl.chatCreator = ctrl.channel.members.find(member => member.userId === ctrl.channel.item.creatorUserId);
            ctrl.channelName = ctrl.channel.item.name;
            ctrl.hideAuthorForCurrentUser = ctrl.channel.item.hideAuthor
                && ctrl.channel.item.creatorUserId === ctrl.currentUser.UserToken;
        }

        function openChatMembers() {
            ChatPopupsService.openChatMembersPopup(ctrl.channel, ctrl.closeConversation, ctrl.close);
        }

        function channelEventsCallback(channel) {
            ctrl.channel = channel
        }

        function isAdmin(token) {
            return !!ctrl.channel.item.adminUserIds.find(userId => {
                return userId === token
            });
        }

        function isLeaveEnabled() {
            return ctrl.channel.item.adminUserIds.filter(userId => {
                return userId !== ctrl.currentUser.UserToken
            }).length || ctrl.channel.members.length === 1
        }

        function openAddUsers() {
            ChatPopupsService.openAddUsersPopup(ctrl.channel);
        }

        function leaveChannel() {
            if (!isLeaveEnabled()) {
                ConfirmPopupService.open({
                    message: $filter('translate')('CHAT.LEAVE_LAST_MODERATOR_WARNING'),
                    yesText: $filter('translate')('GOT_IT'),
                    isAlert: true
                });

            } else {
                ConfirmPopupService.open({
                    message: $filter('translate')('CHAT.EVENT.CONFIRM_LEAVE'),
                    class: 'chat-leave-confirm'
                }).then(function () {
                    ChatDataService.leaveChannel(ctrl.channel.item.channelId)
                        .then(() => {
                            $rootScope.$broadcast('CHAT.CHANNEL_REMOVED', ctrl.channel.item.channelId)
                            closeThisAndPreviousPopups(true);
                        });
                });
            }
        }

        function titleFocus(e) {
            if (ctrl.editingTitle) return;

            const el = e.currentTarget;
            ctrl.editingTitle = true;

            $timeout(function () {
                el.setSelectionRange(ctrl.channelName, ctrl.channelName);
            });
        }

        function editIconClick() {
            if (ctrl.editingTitle) return;
            angular.element('.chat-title input').focus();
        }

        function updateTitle(title) {
            ctrl.saving = true;
            ChatDataService
                .updateChannelTitle(ctrl.channel.item.channelId, title, ctrl.ignoreContentSafetyWarning)
                .then(() => {
                    ctrl.ignoreContentSafetyWarning = false;
                    ctrl.channel.item.name = ctrl.channelName;
                })
                .catch(resp => {
                    if (resp?.data?.ShowContentSafetyWarning) {
                        ContentSafetyWarningService
                            .openPopup(resp.data.SeverityLevel, resp.data.Action, 'CHAT.UPDATE_TITLE', () => {
                                ctrl.ignoreContentSafetyWarning = true;
                                updateTitle(title);
                            })
                    } else {
                        ToastFactory.error('ERROR.GENERAL');
                    }
                })
                .finally(() => {
                    ctrl.saving = false;
                    ctrl.editingTitle = false;

                });
        }
        
        function updateImage(image) {
            if (ctrl.imageSaving) return;
            
            ctrl.imageSaving = true;
            ChatDataService
                .updateChannelImage(ctrl.channel.item.channelId, image?.MediaToken, ctrl.ignoreContentSafetyWarning)
                .then(() => {
                    ctrl.ignoreContentSafetyWarning = false;
                    ctrl.channel.profileMediaModel = image;
                })
                .catch(resp => {
                    if (resp?.data?.ShowContentSafetyWarning) {
                        ContentSafetyWarningService
                            .openPopup(resp.data.SeverityLevel, resp.data.Action, 'CHAT.UPDATE_IMAGE', () => {
                                ctrl.ignoreContentSafetyWarning = true;
                                updateImage(image);
                            })
                    } else {
                        ToastFactory.error('ERROR.GENERAL');
                    }
                })
                .finally(() => {
                    ctrl.imageSaving = false;
                });
        }

        function orderByCreator(user) {
            return user.userId !== ctrl.channel.item.creatorUserId;
        }

        function setNotificationSetting() {
            ctrl.channel.item.notificationsEnabled = !ctrl.notificationsDisabled;
            ChatDataService
                .setChannelNotificationSettings(ctrl.channel.item.channelId, ctrl.channel.item.notificationsEnabled)
                .then(() => { $rootScope.$broadcast('CHAT.NOTIFICATION_SETTING_CHANGED', ctrl.channel.item.channelId, ctrl.channel.item.notificationsEnabled)})
                .catch(err => {
                    ToastFactory.error('ERROR.GENERAL')
                    ctrl.channel.item.notificationsEnabled = !ctrl.channel.item.notificationsEnabled;
                });
        }

        function showProfile(user) {
            ProfileViewerService.showProfileCard(user.userId);
        }

        function close() {
            ChatConnectionService.removeChannelItemEvents(channelEventsCallback);
            pushOpenWatcher();
            destroyChannelUpdatedWatcher && destroyChannelUpdatedWatcher();
            popup.remove(); 
            ChatPopupsService.disableBodyScrollInChat();
            $element.remove();
        }

        function closeThisAndPreviousPopups(isRemoved) {
            close();
            ctrl.closeConversation && ctrl.closeConversation(isRemoved);
        }

        function isBlockedUser(user) {
            return ctrl.channel.blockedUsers.find((blockedUserId) => blockedUserId === user.userId);
        }

        function openGroupImage() {
            if (!ctrl.channel.profileMediaModel) {
                return;
            }

            AttachmentsViewerService.openViewerWithMedia([ctrl.channel.profileMediaModel]);
        }

        function onImageEdit($event) {
            const editImageActionSheet = ActionSheetService.create([
                {
                    text: 'CHAT.IMAGE.EDIT_IMAGE',
                    icon: 'edit',
                    onClick: uploadProfileImage
                },
                {
                    text: 'CHAT.IMAGE.DELETE_IMAGE',
                    icon: 'delete',
                    iconClass: 'red',
                    onClick: deleteProfileImage
                }
            ], $event.currentTarget, null, { name: 'chat-settings' });

            editImageActionSheet.show();
        }

        function uploadProfileImage() {
            $element.find('.upload').trigger('click');
        }

        function deleteProfileImage() {
            updateImage(null);
        }

        function onImageUploaded($uploaded) {
            updateImage($uploaded);
        }

        function isUserNotBlocked(member) {
            return !ctrl.channel.blockedUsers?.includes(member.userId);
        }

        function getNonBlockedUser() {
            const nonBlockedUsers = ctrl.channel.members.filter((user) => {
                return !ctrl.channel.blockedUsers.includes(user.userId)
            });

            if (nonBlockedUsers.length === 1) return nonBlockedUsers[0];

            return nonBlockedUsers.filter((user) => user.userId !== ctrl.currentUserToken)[0];
        }
    }
})();