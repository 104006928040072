(function () {
    'use strict';

    angular.module('App')
        .component('contentTotalViews', {
            template: require('./ContentTotalViewsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$filter', 'PostReadRegistrationService', 'MODULE_ID', ContentTotalViewsController],
            bindings: {
                token: '<',
                totalViews: '<',
                moduleToken: '<'
            }
        })

    function ContentTotalViewsController($filter, PostReadRegistrationService, MODULE_ID) {
        var ctrl = this,
            title = $filter('translate')('CONTENT.SEEN_BY');


        ctrl.showViews = showViews;

        function showViews() {
            PostReadRegistrationService.showPopup(ctrl.token, title, MODULE_ID.CONTENT, 'views', ctrl.moduleToken);
        }
    }
})();