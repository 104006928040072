require("froala-editor/js/plugins/font_size.min");
require("froala-editor/js/plugins/image.min");
require("froala-editor/js/plugins/align.min");
require("froala-editor/js/plugins/link.min");
require("froala-editor/js/plugins/lists.min");
require("froala-editor/js/plugins/table.min");
require("froala-editor/js/plugins/colors.min");
require("froala-editor/js/plugins/video.min");
require("froala-editor/js/plugins/line_breaker.min");
require("froala-editor/js/plugins/paragraph_format.min");
require("froala-editor/js/plugins/files_manager.min");
require("froala-editor/js/plugins/file.min");
require("froala-editor/js/third_party/image_tui.min");

(function () {
    'use strict';

    const defaultTags = ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo',
            'blockquote', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'datalist', 'dd',
            'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure',
            'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'header', 'hgroup', 'hr', 'i', 'iframe', 'img',
            'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'menuitem',
            'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'pre', 'progress',
            'queue', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'style', 'section', 'select', 'small', 'source', 'span',
            'strike', 'strong', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead',
            'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr'],
        defaultAttr = ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen',
            'allowtransparency', 'alt', 'app-link', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave',
            'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class',
            'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data',
            'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable',
            'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high',
            'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang',
            'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen',
            'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder',
            'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed',
            'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size',
            'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'tabindex',
            'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen',
            'width', 'wrap', 'style'],
        toolbarButtons = {
            moreText: {
                buttons: ['bold', 'italic', 'underline', 'fontSize', 'textColor', 'clearFormatting'],
                buttonsVisible: 3,
            },
            moreParagraph: {
                buttons: ['alignLeft', 'alignCenter', 'alignRight', 'formatUL', 'formatOLSimple'],
                buttonsVisible: 0,
            }, moreMisc: {
                buttons: ['insertLink', 'insertVideo', 'insertFiles'],
                buttonsVisible: 0,
            },
        },
        toolbarButtonsLimited = {
            moreText: {
                buttons: ['bold', 'italic', 'underline', 'clearFormatting'],
                buttonsVisible: 3,
            },
            moreParagraph: {
                buttons: ['formatUL', 'formatOLSimple'],
                buttonsVisible: 0,
            }, moreMisc: {
                buttons: ['insertLink', 'insertVideo', 'insertFiles'],
                buttonsVisible: 0,
            },
        },
        toolbarDesktopButtons = {
            moreText: {
                buttons: ['bold', 'italic', 'underline', 'clearFormatting', 'paragraphFormat', 'fontSize', 'textColor'],
                buttonsVisible: 4,
            },
            moreMisc: {
                buttons: ['alignLeft', 'alignCenter', 'alignRight', 'formatUL', 'formatOLSimple', '|',
                    'insertFiles', 'insertVideo', 'insertLink', 'insertTable'],
                buttonsVisible: 9,
            },
        },
        toolbarDesktopButtonsLimited = ['bold', 'italic', 'underline', 'paragraphFormat', 'clearFormatting',
            '|', 'formatUL', 'formatOLSimple', '|', 'insertFiles', 'insertVideo', 'insertLink', 'insertTable'];

    angular.module('Relesys.Ui')
        .component('rlRichTextarea', {
            template: require('./RichTextareaComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$location', '$timeout', '$element', 'ToastFactory', 'BasicHelper', 'ResponsiveService', 'Page',
                RichTextareaController],
            require: {
                ngModel: 'ngModel'
            },
            bindings: {
                onInit: '<',
                uploadUrl: '<',
                uploadParams: '<',
                placeholder: '@',
                initDisabled: '<',
                onKeyboardOpen: '<',
                scrollableContainer: '@',
                limitOptions: '<'
            }
        })

    function RichTextareaController($scope, $location, $timeout, $element, ToastFactory, BasicHelper, ResponsiveService, Page) {
        const ctrl = this;
        let destroyModelWatcher, initialViewportHeight;

        window.FroalaEditor = window.FroalaEditor || require('froala-editor');

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.model = '';
            ctrl.uploadParams = {
                ...ctrl.uploadParams,
                Froala: true
            }
            const colors = Page.getSettings().CustomRteColors.length > 0 ?
                Page.getSettings().CustomRteColors.map(color => color.toUpperCase()) :
                ['#3C4858', '#4FC990', '#FED263', '#EB6262'];

            if (!colors.includes('REMOVE')) {
                if (colors.length > 5) {
                    colors.splice(5, 0, 'REMOVE');
                } else {
                    colors.push('REMOVE');
                }
            }

            ctrl.froalaOptions = {
                key: 'kRB4zB3D2A1G2B2H1I2rXYb1VPUGRHYZNRJd1JVOOb1HAc1zG2B1A2D2D6B1C1C4D1E4==',
                toolbarButtonsXS: ctrl.limitOptions ? toolbarButtonsLimited : toolbarButtons,
                toolbarButtons: ctrl.limitOptions ? toolbarDesktopButtonsLimited : toolbarDesktopButtons,
                attribution: false,

                toolbarContainer: '.rich-toolbar',
                pastePlain: true,
                pasteDeniedAttrs: ['class', 'id', 'style'],
                quickInsertEnabled: false,
                scrollableContainer: ctrl.scrollableContainer || 'body',

                colorsStep: 6,
                colorsText: colors,
                colorsHEXInput: true,

                tableInsertMaxSize: 10,

                linkText: true,
                linkInsertButtons: ['linkBack'],
                linkStyles: {
                    btn: 'Button'
                },

                htmlAllowedTags: [...defaultTags, 'media', 'hash-tag', 'user-mention', 'department-mention', 'user-group-mention'],
                htmlAllowedAttrs: [...defaultAttr, 'token'],

                filesManagerAllowedTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/svg', 'image/gif'],
                filesInsertButtons2: ['deleteAll', 'insertAll', 'minimize'],
                filesManagerUploadURL: ctrl.uploadUrl,
                filesManagerUploadParams: ctrl.uploadParams,
                filesManagerUploadMethod: 'POST',

                imageResize: (ResponsiveService.isDesktop() && !ctrl.limitOptions),
                imageMove: false,
                imagePaste: false,
                imageDefaultWidth: 0,
                imageUploadURL: ctrl.uploadUrl,
                imageUploadParams: ctrl.uploadParams,
                imageUploadMethod: 'POST',
                imageAllowedTypes: ['gif', 'jpeg', 'jpg', 'png', 'svg'],
                imageEditButtons: (ResponsiveService.isDesktop() && !ctrl.limitOptions) ?
                    ['imageReplace', 'imageAlign', 'imageDisplay', 'imageRemove'] :
                    ['imageReplace', 'imageRemove'],

                videoResize: (ResponsiveService.isDesktop() && !ctrl.limitOptions),
                videoPaste: false,
                videoMove: false,
                videoDefaultWidth: 0,
                videoUploadURL: ctrl.uploadUrl,
                videoUploadParams: ctrl.uploadParams,
                videoUploadMethod: 'POST',
                videoMaxSize: 1024 * 1024 * 500,
                videoAllowedTypes: ['avi', '3gp', 'mov', 'mp4', 'mpg', 'mpeg', 'avi', 'flv', 'wmv', 'webm', 'mkv', 'quicktime'],
                videoEditButtons: (ResponsiveService.isDesktop() && !ctrl.limitOptions) ?
                    ['videoReplace', 'videoAlign', 'videoDisplay', 'videoRemove'] :
                    ['videoReplace', 'videoRemove'],
                events: {
                    'initialized': () => {
                        ctrl.initDisabled && ctrl.froalaOptions.froalaEditor.edit.off();
                        ctrl.onInit && ctrl.onInit(ctrl.froalaOptions.froalaEditor);
                    },
                    'focus': !ResponsiveService.isDesktop() && onFocusClick,
                    'click': !ResponsiveService.isDesktop() && onFocusClick,
                    'video.error': resp => ToastFactory.error(resp.message),
                    'image.inserted': onInsert,
                    'image.replaced': onInsert,
                    'video.inserted': onInsert,
                    'video.replaced': onInsert,
                    'paste.after': function () {
                        var html = ctrl.froalaOptions.froalaEditor.html.get(false).replace(/id="isPasted"/g, '');
                        ctrl.froalaOptions.froalaEditor.html.set(html);
                    },
                    'commands.after': function (cmd) {
                        if (cmd === 'linkInsert') {
                            var host = $location.host();

                            if (ctrl.appLinkOptions.link.toLowerCase().includes(host + '/link/')) {
                                ctrl.froalaOptions.froalaEditor.link.insert(
                                    ctrl.appLinkOptions.link, ctrl.appLinkOptions.text,
                                    {'app-link': 'true', ...ctrl.appLinkOptions.attrs}
                                );
                            } else {
                                ctrl.froalaOptions.froalaEditor.link.remove();
                                ctrl.froalaOptions.froalaEditor.link.insert(
                                    ctrl.appLinkOptions.link, ctrl.appLinkOptions.text,
                                    {'app-link': 'true', ...ctrl.appLinkOptions.attrs}
                                );
                            }
                        }
                        if (cmd === 'insertFiles') {
                            $element.find('#filesList').attr('body-scroll-lock-ignore', true);
                        }
                    },
                    'link.beforeInsert': function (link, text, attrs) {
                        ctrl.appLinkOptions = {link, text, attrs}
                    }
                }
            };

            if (ResponsiveService.isMobile()) {
                ctrl.froalaOptions.tableEditButtons = [];
            } else {
                ctrl.froalaOptions.paragraphFormat = {
                    N: 'Normal',
                    H1: 'Heading 1',
                    H2: 'Heading 2',
                    H3: 'Heading 3',
                    H4: 'Heading 4',
                    H5: 'Heading 5',
                    H6: 'Heading 6',
                    PRE: 'Code'
                }
            }

            if (ctrl.placeholder && ctrl.placeholder.length) {
                ctrl.froalaOptions.placeholderText = ctrl.placeholder;
            }

            ctrl.ngModel.$render = () => {
                ctrl.model = ctrl.ngModel.$viewValue;
            };
            destroyModelWatcher = $scope.$watch('ctrl.model', (value) => {
                ctrl.ngModel.$setViewValue(value)
            });
        }

        function onFocusClick() {
            initialViewportHeight = visualViewport.height;
            $timeout(() => {
                if (visualViewport.height !== initialViewportHeight) {
                    BasicHelper.isIOS() && $element[0].scrollIntoView();
                }
            }, 300)
        }

        function onInsert(el, resp) {
            if (el && el.length) {
                if (angular.element(el).find('iframe').length) {
                    el[0].classList.add('iframe');
                } else {
                    el[0].setAttribute('token', JSON.parse(resp).Files[0].MediaToken)
                }

                const video = angular.element(el).find('video');
                if (video?.length) preventAutoplay(video);
            }

            function preventAutoplay(video) {
                if (video[0].hasAttribute('autoplay')) {
                    video[0].removeAttribute('autoplay');
                }
            }
        }

        function destroy() {
            destroyModelWatcher();
        }
    }
})();
