(() => {
    angular
        .module('App')
        .component('userManagementUserList', {
            template: require('./UserManagementUserListComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                editUser: '&',
                paging: '&',
                managerViewEnabled: '<',
                users: '<',
                activeView: '<'
            },
            controller: ['UserManagementService', UserManagementUserListController]
        })

    function UserManagementUserListController(UserManagementService) {
        const ctrl = this;

        ctrl.getFontawesomeIcon = getFontawesomeIcon;
        ctrl.onEditUser = onEditUser;
        ctrl.onPaging = onPaging;
        ctrl.getIndicatorClass = getIndicatorClass;
        ctrl.statuses = UserManagementService.getActivityIndicatorOptions();

        function getIndicatorClass(userStatus) {
            const option = ctrl.statuses.find((status) => status.value === userStatus);
            return option?.activityIndicator || null;
        }

        function onEditUser(user) {
            ctrl.editUser({user});
        }

        function onPaging() {
            ctrl.paging();
        }

        function getFontawesomeIcon() {
            switch (ctrl.activeView) {
                case 1:
                    return 'fas fa-clock';
                case 2:
                    return 'fa fa-hourglass-half';
                case 3:
                    return 'fa-solid fa-xmark';
                default:
                    return null;
            } 
        }
    }
})();   