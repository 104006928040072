(() => {
    'use strict';

    angular
        .module('App')
        .component('assigneesPopup', {
            template: require('./AssigneesPopupComponent.html'),
            controller: ['$element', 'AssigneesPopupService', 'ToastFactory', AssigneesPopupController],
            controllerAs: 'ctrl',
            bindings: {
                considerPermissions: '<',
                permissions: '<',
                selectedUsers: '<',
                matchAllUserGroups: '<',
                issueToken: '<',
                onSave: '<',
                canModerate: '<',
                privateTask: '<'
            }
        });

    function AssigneesPopupController($element, AssigneesPopupService, ToastFactory) {
        let ctrl = this, popup;

        ctrl.popupId = 'AssigneesPopupId';

        ctrl.save = save;
        ctrl.close = close;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.usersPaging = getUsers;

        function close() {
            ctrl.selectedUsers = [];
            popup.remove();
            $element.remove();
        }

        function save(selectedUsers) {
            if (!ctrl.considerPermissions) {
                AssigneesPopupService
                    .saveAssignees(ctrl.issueToken, selectedUsers)
                    .catch(() => {
                        ToastFactory.error('ERROR.GENERAL');
                    })
            }
            ctrl.onSave && ctrl.onSave(selectedUsers);
            popup.remove();
        }

        function onPopupRegistered(popupCtrl) {
            ctrl.users = AssigneesPopupService
                .getPossibleAssignees(ctrl.considerPermissions, ctrl.issueToken, ctrl.permissions, 0, 100)
                .then(resp => {
                    ctrl.moreUsers = resp.MoreUsers;

                    return resp.Users
                });

            ctrl.headerTitle = ctrl.canModerate ? (ctrl.privateTask ? 'TASK_MANAGEMENT.TASK.CHOOSE_ASSIGNEE' : 'TASK_MANAGEMENT.TASK.CHOOSE_RESPONSIBLE') :
                (ctrl.privateTask ? 'TASK_MANAGEMENT.ASSIGNEES' : 'TASK_MANAGEMENT.TASK.RESPONSIBLE');

            popup = popupCtrl;
            popup.open()
        }

        function getUsers(offset, limit, name) {
            return AssigneesPopupService
                .getPossibleAssignees(ctrl.considerPermissions, ctrl.issueToken, ctrl.permissions, offset, limit, name)
        }
    }
})();


