(function () {
    'use strict';

    angular.module('App')
        .component('barcodeReader', {
            templateUrl: '/Scripts/Components/BarcodeReader/BarcodeReaderComponent.tpl.html',
            controllerAs: 'ctrl',
            controller: ['$element', '$timeout', '$scope', 'ToastFactory', 'NativeFactory', 'Page', BarcodeReaderController],
            bindings: {
                barcode: '=?',
                onBarcodeClicked: '&',
                barcodeTypeList: '<',
                index: '<'
            }
        });

    function BarcodeReaderController($element, $timeout, $scope, ToastFactory, NativeFactory, Page) {
        var ctrl = this, isStarted, isChangeEventSet,
            barcodeTypes = [
                'code_128_reader',
                'ean_reader',
                'ean_8_reader',
                'code_39_reader',
                'code_39_vin_reader',
                'codabar_reader',
                'upc_reader',
                'upc_e_reader',
                'i2of5_reader',
                '2of5_reader',
                'code_93_reader'
            ];

        ctrl.results = [];

        ctrl.$onInit = init;
        ctrl.openReader = openReader;
        ctrl.imageUploaded = imageUploaded;
        ctrl.close = close;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.barcodeTypeList = ctrl.barcodeTypeList ? ctrl.barcodeTypeList : barcodeTypes;
        }

        function openFileReader() {
            if (!isChangeEventSet) {
                $timeout(function () {
                    $element.on("change", ".file", imageUploaded);
                    isChangeEventSet = true;
                });
            }
            $element.find('.file').val('').trigger('click');
        }

        function openReader() {
            !_.isUndefined(ctrl.onBarcodeClicked) && ctrl.onBarcodeClicked();
            if (Page.getSettings().NativeSettings.IsNativeWrapper && NativeFactory.hasMethod('scanner.scanBarcode')) {
                NativeFactory.scanner.scanBarcode(function (data) {
                    if (data.status === 1) {
                        $scope.$apply(function () {
                            ctrl.barcode = _.get(data.data, 'Result');
                        });
                    }
                }, ctrl.barcodeTypeList);
            } else {
                if (!navigator.mediaDevices || typeof navigator.mediaDevices.getUserMedia !== 'function') {
                    openFileReader();
                } else {
                    Promise.resolve(navigator.mediaDevices.getUserMedia({video: true})).then(function () {
                        openLiveReader();
                    }).catch(function () {
                        openFileReader();
                    });
                }
            }
        }

        function imageUploaded(e) {
            if (e.target.files && e.target.files.length) {
                Quagga.decodeSingle({
                    src: URL.createObjectURL(e.target.files[0]),
                    numOfWorkers: 0,  // Needs to be 0 when used within node
                    inputStream: {
                        size: 800  // restrict input-size to be 800px in width (long-side)
                    },
                    decoder: {
                        readers: ctrl.barcodeTypeList // List of active readers
                    }
                }, function (result) {
                    if (result && result.codeResult) {
                        $scope.$apply(function () {
                            ctrl.barcode = result.codeResult.code;
                        });
                    } else {
                        ToastFactory.error('SERVICEFORM.DATALIST.NOT_IDENTIFIED');
                    }
                });
            }
        }

        function openLiveReader() {
            ctrl.results = [];
            isStarted = true;
            $scope.$apply(function () {
                ctrl.showReader = true;
            });
            Quagga.init({
                inputStream: {
                    name: "Live",
                    type: "LiveStream",
                    target: $element.find('.camera').get(0),
                    area: { // defines rectangle of the detection/localization area
                        top: "35%",    // top offset
                        right: "15%",  // right offset
                        left: "15%",   // left offset
                        bottom: "35%"  // bottom offset
                    }
                },
                decoder: {
                    readers: ctrl.barcodeTypeList
                }
            }, function (err) {
                if (err) {
                    $scope.$apply(function () {
                        ctrl.showReader = false;
                    });
                    return;
                }
                Quagga.start();

                Quagga.onProcessed(processed);
            });
        }

        function processed(result) {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                    result.boxes.filter(function (box) {
                        return box !== result.box;
                    }).forEach(function (box) {
                        Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {
                            color: "green",
                            lineWidth: 2
                        });
                    });
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {
                        color: 'red',
                        lineWidth: 3
                    });
                    ctrl.results.push(result.codeResult.code);
                    if (ctrl.results.length === 3) {
                        if (ctrl.results[0] === ctrl.results[1] && ctrl.results[0] === ctrl.results[2]) {
                            $scope.$apply(function () {
                                ctrl.barcode = result.codeResult.code;
                                ctrl.showReader = false;
                                isStarted = false;
                                Quagga.stop();
                                Quagga.offProcessed(processed);
                            });
                        }
                        ctrl.results = [];
                    }
                }
            }
        }

        function close() {
            $timeout(function () {
                ctrl.showReader = false;
                if (isStarted) {
                    Quagga.stop();
                    Quagga.offProcessed(processed);
                }
            });
        }

        function destroy() {
            if (isStarted) {
                Quagga.stop();
                Quagga.offProcessed(processed);
            }
            $element.off("change");
            ctrl.showReader = false;
        }
    }
})();