(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider',
            function ($stateProvider) {
                $stateProvider
                    .state('blockedUsers', {
                        parent: 'base',
                        url: '/blockedUsers',
                        template: '<blocked-users></blocked-users>',
                        resolve: {},
                        params: {
                            data: null
                        },
                        controller: 'BlockedUsersController as ctrl'
                    });
            }
        ])
        .controller('BlockedUsersController', ['$stateParams', '$translate', 'Page',
            function ($stateParams, $translate, Page) {
                $translate('PROFILE.BLOCK.BLOCKED_USERS').then((translation) => {
                    Page.setTitle(translation);
                });

                Page.showBackButton();
            }]);
})();