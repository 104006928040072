(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlSearchResultPlaceholder', {
            template: require('./SearchResultPlaceholderComponent.html'),
            controller: [SearchResultPlaceholderController],
            controllerAs: 'ctrl',
            bindings: {
                type: '@'
            }
        });

    function SearchResultPlaceholderController() {
    }
})();