type IconPrefix = 'fab' | 'fal' | 'far' | 'fas';
type IconObject = { prefix?: IconPrefix; icon: string; };

export const RELESYS_ICONS = {
    'instagram': {prefix: 'fab', icon: 'fa-instagram'},
    'facebook': {prefix: 'fab', icon: 'fa-facebook-f'},
    'facebook-square': {prefix: 'fab', icon: 'fa-facebook-square'},
    'twitter': {prefix: 'fab', icon: 'fa-twitter'},
    'youtube': {prefix: 'fab', icon: 'fa-youtube'},
    'linkedin': {prefix: 'fab', icon: 'fa-linkedin-in'},
    'linkedin-square': {prefix: 'fab', icon: 'fa-linkedin'},
    'globus': {icon: 'fa-globe'},
    'notifications': {icon: 'fa-bell'},
    'notifications-slash': {icon: 'fa-bell-slash'},
    'home': {icon: 'fa-home-alt'},
    'delete': {icon: 'fa-trash'},
    'delete-alt': {icon: 'fa-trash-alt'},
    'edit': {icon: 'fa-pen'},
    'add': {icon: 'fa-plus'},
    'upload': {icon: 'fa-arrow-from-bottom'},
    'download': {icon: 'fa-arrow-to-bottom'},
    'thumbs-up': {icon: 'fa-thumbs-up'},
    'bookmark': {icon: 'fa-bookmark'},
    'post': {icon: 'fa-paper-plane'},
    'search': {prefix: 'fal', icon: 'fa-search'},
    'search-minus': {prefix: 'fal', icon: 'fa-search-minus'},
    'burger': {icon: 'fa-bars'},
    'options-header': {icon: 'fa-ellipsis-v'},
    'options-app': {prefix: 'far', icon: 'fa-ellipsis-h'},
    'settings': {icon: 'fa-cog'},
    'spinner': {icon: 'fa-spinner-third'},
    'filter': {icon: 'fa-filter'},
    'user': {icon: 'fa-user-alt'},
    'back': {icon: 'fa-chevron-left'},
    'next': {icon: 'fa-chevron-right'},
    'close': {icon: 'fa-times'},
    'help': {icon: 'fa-question'},
    'arrow-bottom': {icon: 'fa-chevron-down'},
    'arrow-top': {icon: 'fa-chevron-up'},
    'comment': {icon: 'fa-comment'},
    'comments': {icon: 'fa-comments'},
    'camera': {icon: 'fa-camera'},
    'star': {icon: 'fa-star'},
    'trophy': {icon: 'fa-trophy'},
    'finger': {icon: 'fa-hand-point-up'},
    'department': {icon: 'fa-sitemap'},
    'heart': {icon: 'fa-heart'},
    'tag': {icon: 'fa-tag'},
    'tasks': {icon: 'fa-tasks'},
    'content': {icon: 'fa-file'},
    'report': {icon: 'fa-file-alt'},
    'login': {icon: 'fa-sign-in'},
    'logout': {icon: 'fa-sign-out'},
    'survey': {icon: 'fa-question'},
    'image': {prefix: 'fas', icon: 'fa-file-image'},
    'clock': {icon: 'fa-clock'},
    'workmood': {icon: 'fa-laugh'},
    'iframe': {icon: 'fa-window'},
    'dutyplan': {icon: 'fa-clipboard-check'},
    'membercard': {icon: 'fa-address-card'},
    'walkthrough': {icon: 'fa-window-restore'},
    'policy': {icon: 'fa-lock-alt'},
    'training': {icon: 'fa-graduation-cap'},
    'calendar': {icon: 'fa-calendar'},
    'plus-circle': {icon: 'fa-plus-circle'},
    'dot-circle': {icon: 'fa-dot-circle'},
    'circle': {icon: 'fa-circle'},
    'browser': {icon: 'fa-browser'},
    'save': {icon: 'fa-check'},
    'chat-create': {icon: 'fa-edit'},
    'add-user': {icon: 'fa-user-plus'},
    'book': {icon: 'fa-book'},
    'book-blank': {icon: 'fa-book-blank'},
    'books': {icon: 'fa-books'},
    'book-alt': {icon: 'fa-book-alt'},
    'book-solid': {prefix: 'fas', icon: 'fa-book'},
    'barcode': {icon: 'fa-barcode-read'},
    'remove': {icon: 'fa-minus'},
    'send': {icon: 'fa-paper-plane'},
    'user-admin': {icon: 'fa-user-crown'},
    'user-remove': {icon: 'fa-user-slash'},
    'power-off': {icon: 'fa-power-off'},
    'eye-crossed': {icon: 'fa-eye-slash'},
    'eye': {icon: 'fa-eye'},
    'alert': {icon: 'fa-exclamation-triangle'},
    'pin': {icon: 'fa-thumbtack'},
    'attach': {icon: 'fa-paperclip'},
    'pdf': {prefix: 'fas', icon: 'fa-file-pdf'},
    'video': {prefix: 'fas', icon: 'fa-file-video'},
    'audio': {prefix: 'fas', icon: 'fa-file-audio'},
    'word': {prefix: 'fas', icon: 'fa-file-word'},
    'excel': {prefix: 'fas', icon: 'fa-file-excel'},
    'powerpoint': {prefix: 'fas', icon: 'fa-file-powerpoint'},
    'minus-circle': {icon: 'fa-minus-circle'},
    'alarm-clock': {icon: 'fa-alarm-clock'},
    'repeat': {icon: 'fa-repeat'},
    'subtask': {prefix: 'far', icon: 'fa-diagram-subtask'},
    'subtask-add': {prefix: 'fal', icon: 'fa-clipboard-list-check'},
    'subtask-sort': {prefix: 'fal', icon: 'fa-sort'},
    'image-polaroid': {prefix: 'far', icon: 'fa-image-polaroid'},
    'file-lines': {prefix: 'fal', icon: 'fa-file-lines'},
    'grip-lines': {prefix: 'fal', icon: 'fa-grip-lines'},
    'backspace': {icon: 'fa-backspace'},
    'lock': {icon: 'fa-lock-alt'},
    'lock-without-hole': {icon: 'fa-lock'},
    'question-circle': {icon: 'fa-question-circle'},
    'close-circle': {icon: 'fa-times-circle'},
    'users': {icon: 'fa-users'},
    'task-management': {icon: 'fa-clipboard-check'},
    'exclamation': {icon: 'fa-exclamation'},
    'exclamation-circle': {prefix: 'fal', icon: 'fa-exclamation-circle'},
    'phone': {icon: 'fa-phone'},
    'sms': {icon: 'fa-sms'},
    'comment-sms': {icon: 'fa-comment-sms'},
    'email': {icon: 'fa-envelope'},
    'share': {icon: 'fa-share-alt'},
    'shield': {icon: 'fa-shield'},
    'shield-check': {icon: 'fa-shield-check'},
    'shield-keyhole': {icon: 'fa-shield-keyhole'},
    'check-circle': {icon: 'fa-check-circle'},
    'sticky-note': {icon: 'fa-sticky-note'},
    'calendar-plus': {icon: 'fa-calendar-plus'},
    'calendar-check': {icon: 'fa-calendar-check'},
    'calendar-times': {icon: 'fa-calendar-times'},
    'calender-star': {icon: 'fa-calendar-star'},
    'arrow-up': {icon: 'fa-arrow-up'},
    'arrow-down-alt': {icon: 'fa-arrow-down'},
    'copy': {icon: 'fa-copy'},
    'link': {icon: 'fa-link'},
    'info': {icon: 'fa-info-circle'},
    'crown': {icon: 'fa-crown'},
    'undo': {icon: 'fa-undo'},
    'cap': {prefix: 'fas', icon: 'fa-graduation-cap'},
    'file': {prefix: 'fas', icon: 'fa-file'},
    'list': {prefix: 'fas', icon: 'fa-list'},
    'list-ul': {prefix: 'fas', icon: 'fa-list-ul'},
    'bell': {prefix: 'fas', icon: 'fa-bell'},
    'teams': {prefix: 'fas', icon: 'fa-user-friends'},
    'language': {icon: 'fa-language'},
    'hourglass': {icon: 'fa-hourglass-half'},
    'hourglass-end': {icon: 'fa-hourglass-end'},
    'user-lock': {icon: 'fa-user-lock'},
    'play': {prefix: 'fas', icon: 'fa-play'},
    'expand': {prefix: 'fas', icon: 'fa-expand-alt'},
    'poll': {prefix: 'fas', icon: 'fa-poll'},
    'check-double': {icon: 'fa-check-double'},
    'print': {icon: 'fa-print'},
    'toggle': {icon: 'fa-toggle-on'},
    'ticket': {prefix: 'fal', icon: 'fa-ticket'},
    'marker': {prefix: 'fal', icon: 'fa-map-marker-alt'},
    'zip': {prefix: 'fas', icon: 'fa-file-archive'},
    'triangle-down': {prefix: 'fas', icon: 'fa-caret-down'},
    'triangle-up': {prefix: 'fas', icon: 'fa-caret-up'},
    'triangle-right': {prefix: 'fas', icon: 'fa-caret-right'},
    'glasses': {icon: 'fa-glasses'},
    'sort': {icon: 'fa-sort-size-down-alt'},
    'images': {icon: 'fa-images'},
    'at': {icon: 'fa-at'},
    'categories': {icon: 'fa-list-alt'},
    'redo': {icon: 'fa-redo'},
    'location': {prefix: 'fas', icon: 'fa-location'},
    'map-marker': {prefix: 'fas', icon: 'fa-map-marker-alt'},
    'building': {prefix: 'fas', icon: 'fa-building'},
    'tachometer': {prefix: 'fas', icon: 'fa-tachometer-fast'},
    'file-edit': {prefix: 'fas', icon: 'fa-file-edit'},
    'brush': {prefix: 'fal', icon: 'fa-paint-brush'},
    'font': {prefix: 'fal', icon: 'fa-font-case'},
    'eraser': {prefix: 'far', icon: 'fa-eraser'},
    'align-left': {prefix: 'far', icon: 'fa-align-left'},
    'align-right': {prefix: 'far', icon: 'fa-align-right'},
    'align-center': {prefix: 'far', icon: 'fa-align-center'},
    'palette': {prefix: 'fal', icon: 'fa-palette'},
    'stars': {prefix: 'fal', icon: 'fa-stars'},
    'square': {prefix: 'fas', icon: 'fa-square'},
    'square-check': {prefix: 'fas', icon: 'fa-square-check'},
    'square-close': {prefix: 'fas', icon: 'fa-square-xmark'},
    'circle-close': {prefix: 'far', icon: 'fa-circle-xmark'},
    'line': {prefix: 'fal', icon: 'fa-horizontal-rule'},
    'ban': {prefix: 'fal', icon: 'fa-ban'},
    'sliders': {prefix: 'fal', icon: 'fa-sliders-h'},
    'rss': {prefix: 'fas', icon: 'fa-rss-square'},
    'calendar-day': {prefix: 'fas', icon: 'fa-calendar-day'},
    'update-event': {icon: 'fa-calendar-edit'},
    'remove-event': {icon: 'fa-calendar-minus'},
    'calendar-upcoming': {icon: 'fa-calendar-day'},
    'sliders-active': {prefix: 'far', icon: 'fa-sliders-h'},
    'dot': {prefix: 'fas', icon: 'fa-circle'},
    'hashtag': {prefix: 'fas', icon: 'fa-hashtag'},
    'reply': {icon: 'fa-reply'},
    'face-thinking': {prefix: 'fal', icon: 'fa-face-thinking'},
    'face-tear': {prefix: 'fal', icon: 'fa-face-sad-tear'},
    'newspaper': {prefix: 'far', icon: 'fa-newspaper'},
    'folders': {prefix: 'fas', icon: 'fa-folders'},
    'folder-open': {prefix: 'fas', icon: 'fa-folder-open'},
    'bullseye-arrow': {prefix: 'fal', icon: 'fa-bullseye-arrow'},
    'arrow-up-1-9': {prefix: 'fal', icon: 'fa-arrow-up-1-9'},
    'arrow-down-1-9': {prefix: 'fal', icon: 'fa-arrow-down-1-9'},
    'grid-plus': {prefix: 'fal', icon: 'fa-grid-2-plus'},
    'poll-people': {prefix: 'fas', icon: 'fa-poll-people'},
    'archive': {icon: 'fa-archive'},
    'bookmark-published': {icon: 'fa-memo-circle-check'},
    'bookmark-archived': {icon: 'fa-box-archive'},
    'bookmark-expired': {icon: 'fa-hourglass-clock'},
    'bookmark-unpublished': {icon: 'fa-clock-rotate-left'},
    'circle-check': {prefix: 'fas', icon: 'fa-circle-check'},
    'stopwatch': {prefix: 'fal', icon: 'fa-stopwatch'},
    'fingerprint': {icon: 'fa-fingerprint'},
    'accessibility': {icon: 'fa-universal-access'},
    'party': {icon: 'fa-party-horn'},
    "bullhorn": {icon: 'fa-bullhorn'},
    "memo-info": {icon: 'fa-memo-circle-info'},
    'chart-simple': {prefix: 'fal', icon: 'fa-chart-simple'},
    'user-pen': {prefix: 'fal', icon: 'fa-user-pen'},
    'gavel': {icon: 'fa-gavel'},
    'arrow-square-right': {prefix: "fal", icon: 'fa-square-chevron-right'},
    'arrow-square-down': {prefix: "fal", icon: 'fa-square-chevron-down'},
    'circle-info': {prefix: 'fal', icon: 'fa-circle-info'},
    'leave-chat': {prefix: 'fal', icon: 'fa-arrow-right-from-bracket'},
    'leave': {prefix: 'fal', icon: 'fa-right-from-bracket'},
    'arrow-right-to-bracket': {prefix: 'fal', icon: 'fa-arrow-right-to-bracket'},
    'vertical-line': {icon: 'fa-pipe'},
    'library': {icon: 'fa-book-bookmark'},
    'default-image': {icon: 'fa-image'},
    'bookmark-remove': {icon: 'fa-bookmark-slash'},
    'comment-cross': {prefix: 'fal', icon: 'fa-message-xmark'},
    'folder-tree': {prefix: 'fas', icon: 'fa-folder-tree'},
    'folder-bookmark': {prefix: 'fal', icon: 'fa-folder-bookmark'},
    'comment-check': {prefix: 'fal', icon: 'fa-comment-check'},
    'remove-user': {icon: 'fa-user-minus'},
    'filters': {prefix: 'fal', icon: 'fa-bars-filter'},
    'filters-active': {prefix: 'fas', icon: 'fa-bars-filter'},
    'island-tropical': {prefix: 'fal', icon: 'fa-island-tropical'},
    'baby-carriage': {prefix: 'fal', icon: 'fa-baby-carriage'},
    'face-thermometer': {prefix: 'fal', icon: 'fa-face-thermometer'},
    'circle-arrow-right': {prefix: 'fal', icon: 'fa-circle-arrow-right'},
    'xmark': {prefix: 'fal', icon: 'fa-xmark'},
    'no-results': {prefix: 'fal', icon: 'fa-face-monocle'},
} as const satisfies Record<string, IconObject>;

export type RelesysIconsType = keyof typeof RELESYS_ICONS;

export type RelesysIconPrefixesType = {
    [K in RelesysIconsType]: (typeof RELESYS_ICONS)[K] extends { prefix: infer P } ? P : never;
}[RelesysIconsType];
