(() => {
    'use strict';

    angular
        .module('App.Common', ['toastr'])
        .run();
})();   

require([
    './Common/Page',
    './Common/RichMentionService',
    './Common/ToastFactory',
    './Common/LazyInitService/LazyImageLoadDirective',
    './Common/LazyInitService/LazyInitWrapperComponent',
    './Common/LazyInitService/lazyInitWrapperService',
    './Common/responsive.service',
    './Common/responsive.hybrid.service',
])