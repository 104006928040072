(() => {
    'use strict';

    angular
        .module('App')
        .component('swipe', {
            template: require('./SwipeComponent.html'),
            controller: ['$translate', '$timeout', 'ToastFactory', SwipeController],
            controllerAs: 'ctrl',
            bindings: {
                onSwipe: '&',
                isSwiped: '=',
                beforeText: '@',
                afterText: '@',
                canUndo: '<',
                disabled: '<'
            }
        });


    function SwipeController($translate, $timeout, ToastFactory) {
        var ctrl = this;

        ctrl.$onChanges = function (changes) {
            if (changes.disabled) {
                ctrl.isSwiping = false;
            }
        }

        // Texts
        if (angular.isUndefined(ctrl.beforeText) || ctrl.beforeText.length == 0) {
            $translate('SWIPE.SWIPE_TO_READ').then(function (translation) {
                ctrl.swipeBeforeText = translation;
            });
        } else {
            ctrl.swipeBeforeText = ctrl.beforeText;
        }

        if (angular.isUndefined(ctrl.afterText) || ctrl.afterText.length == 0) {
            $translate('SWIPE.READ').then(function (translation) {
                ctrl.swipeAfterText = translation;
            });
        } else {
            ctrl.swipeAfterText = ctrl.afterText;
        }

        ctrl.swipe = function () {
            ctrl.isSwiping = true;
            if ((!ctrl.isSwiped || ctrl.canUndo) && !ctrl.isLoading) {
                ctrl.isLoading = true;

                ctrl.isSwiped = !ctrl.isSwiped;

                $timeout(() => {
                    ctrl.isLoading = false;
                });

                ctrl.onSwipe && ctrl.onSwipe()
                    .catch(() => {
                        $timeout(() => {
                            ToastFactory.error('ERROR.GENERAL');
                            ctrl.isLoading = false;
                            ctrl.isSwiping = false;
                            ctrl.isSwiped = !ctrl.isSwiped;
                        }, 1000)
                    })
            }
        }
    }
})();


