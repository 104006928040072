(function () {
    'use strict';

    angular
        .module('App')
        .component('campaign', {
            template: require('./CampaignComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', 'ToastFactory', 'PopupWrapperService', 'HeaderButtonsFactory', 'CampaignService', 
                'ResponsiveService', 'Page', 'TranslatableEntityService', CampaignController],
            bindings: {
                campaign: '<'
            }
        });

    function CampaignController($rootScope, ToastFactory, PopupWrapperService, HeaderButtonsFactory, CampaignService,
                                ResponsiveService, Page, TranslatableEntityService) {
        const ctrl = this;
        let onTranslationChangedListener;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.getViewClass = getViewClass;
        ctrl.openSlot = openSlot;
        ctrl.getSlotBackground = getSlotBackground;

        function init() {
            ctrl.lockedMedia = null;
            loadCampaignSlots();

            if (ctrl.campaign.LockedSlotMedia) {
                ctrl.lockedMedia = ctrl.campaign.LockedSlotMedia;
            }

            if (ctrl.campaign.TranslatableEntity._currentLocale.Introduction && ctrl.campaign.TranslatableEntity._currentLocale.Introduction.length) {
                HeaderButtonsFactory.createButtonsList(function () {
                    return [{
                        icon: 'info',
                        place: ResponsiveService.isMobile() ? 'right' : 'title',
                        onClick: openIntroductionPopup
                    }];
                });
            } else {
                onTranslationChangedListener = $rootScope.$on('slot-translation-changed', translateCampaign);
            }
            Page.setCustomCssClass(ctrl.campaign.CampaignToken, `campaign-${ctrl.campaign.CampaignToken}`);
        }

        function translateCampaign() {
            if (ctrl.isTranslationLoading) {
                return;
            }

            const translateRequest = () => {
                ctrl.isTranslationLoading = true;
                return CampaignService.translateCampaignRequest(ctrl.campaign);
            };

            TranslatableEntityService.switchCurrentLocale(ctrl.campaign.TranslatableEntity, translateRequest).then(() => {
                ctrl.isTranslationLoading = false;
            }).catch(() => {
                ctrl.isTranslationLoading = false;
                ToastFactory.error('ERROR.GENERAL');
            });
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            Page.removeCustomCssClass(ctrl.campaign.CampaignToken, `campaign-${ctrl.campaign.CampaignToken}`);
            onTranslationChangedListener && onTranslationChangedListener();
        }

        function getViewClass() {
            // TODO - Override with actual view from campaign settings
            return 'campaign-view-biggrid';
        }

        function openSlot(slot) {
            if (slot.Availability === 1 || (slot.Availability === 3 && !ctrl.campaign.LockDate)) {
                openCampaignSlotPopup(slot);
            } else if (slot.Availability === 2) {
                ToastFactory.warning('CAMPAIGN.SLOTS.NO_ACCESS');
            } else if (slot.Availability === 3) {
                ToastFactory.warning('CAMPAIGN.SLOTS.OVERDUE');
            }
        }

        function getSlotBackground(slot) {
            var backgroundMedia;
            if (slot.Availability === 2 && ctrl.lockedMedia) {
                backgroundMedia = ctrl.lockedMedia;
            } else if(slot.IconMedia) {
                backgroundMedia = slot.IconMedia;
            }

            if (backgroundMedia) {
                return {
                    backgroundImage: 'url(' + backgroundMedia.ImageFormats['W' + $rootScope.imageWidth] + ')'
                };
            }
        }

        function loadCampaignSlots() {
            if (!ctrl.campaign.Slots.length) {
                ctrl.isLoading = true;

                CampaignService.getCampaign(ctrl.campaign.AccountModuleToken, ctrl.campaign.CampaignToken).then((res) => {
                    ctrl.campaign = res;
                    ctrl.isLoading = false;
                }).catch(() => {
                    ToastFactory.error('ERROR.GENERAL');
                    ctrl.isLoading = false;
                })
            } else {
                ctrl.isLoading = false;
            }
        }

        function openCampaignSlotPopup(slot) {
            var newScope = $rootScope.$new(true);
            newScope.slot = slot;
            newScope.campaign = ctrl.campaign;

            PopupWrapperService.createDynamic(
                '<campaign-slot ' +
                'slot="slot" ' +
                'campaign="campaign" ' +
                'on-close="onClose" ' +
                'on-popup-registered="registeredCallback"></campaign-slot>',
                {
                    slot: newScope.slot,
                    campaign: newScope.campaign,
                    registeredCallback: function (popup) {
                        slot.IsOpened = true;
                        popup.open();
                    },
                    onClose: function () {
                        $rootScope.disableVisibilitychange = false;
                    }
                }
            )
        }

        function openIntroductionPopup() {
            var newScope = $rootScope.$new(true);
            newScope.campaign = ctrl.campaign;

            PopupWrapperService.createDynamic(
                '<campaign-introduction ' +
                'campaign="campaign" ' +
                'on-close="onClose" ' +
                'on-popup-registered="registeredCallback"></campaign-introduction>',
                {
                    campaign: newScope.campaign,
                    registeredCallback: function (popup) {
                        popup.open();
                    },
                    onClose: function () {
                        $rootScope.disableVisibilitychange = false;
                    }
                }
            )
        }
    }
})();
