(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('newsTicker', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Directives/Templates/NewsTicker.tpl.html',
                scope: {
                    tickers: '='
                },
                controller: ['$scope', '$element', '$timeout', 'Page', 'ToastFactory',
                    function ($scope, $element, $timeout, Page, ToastFactory) {
                        $scope.tickerStyle = null;

                        // Init
                        InitDirective();
                        function InitDirective() {
                            $scope.scrollable = false;

                            if($scope.tickers == undefined || $scope.tickers == null){
                                $scope.tickers = [];
                            }

                            if ($scope.tickers.length > 0) {
                                StartTicker();
                            }

                            $(window).on('resize', StartTicker);
                        }

                        // Methods
                        function StartTicker() {
                            $timeout(function () {
                                var _ticker = $('.news-ticker', $element);
                                var _tickerHolder = $('.news-ticker-holder', _ticker);
                                var _tickerHolderInner = $('.news-ticker-holder-inner', _tickerHolder).eq(0);

                                $scope.scrollable = (_ticker.width() < _tickerHolderInner.width());

                                if ($scope.scrollable) {
                                    $scope.tickerStyle = {
                                        animationDuration: (_tickerHolderInner.width() / 30) + 's'
                                    };
                                } else {
                                    $scope.tickerStyle = null;
                                }
                            });
                        }

                        function tickerClick(tick) {
                            if (tick.AppLinkId) {
                                if (tick.AppLinkId.PathState) {
                                    Page.stateGoLink(tick.AppLinkId);
                                } else {
                                    ToastFactory.warning('ERROR.FORBIDDEN');
                                }
                            } else if (tick.ExternalUrl) {
                                window.open(tick.ExternalUrl, '_blank');
                            }
                        }

                        function onDestroy() {
                            $(window).off('resize', StartTicker);
                        }

                        $scope.tickerClick = tickerClick;
                        $scope.$on('$destroy', onDestroy);
                    }
                ]
            }
        }
    ]);
})();