
(function () {
    'use strict';
    var app = angular.module('App');

    app.config(['$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('learningbankitem', {
                    parent: 'base',
                    url: '/learningbankitem/:token/:extra',
                    templateUrl: '/Scripts/Controllers/Modules/LearningBank/Templates/LearningBank.tpl.html',
                    controller: 'LearningBankController as LearningBankCtrl'
                });
        }
    ]);

    app.controller('LearningBankController', ['Page', '$scope', '$sce', '$http', '$stateParams', 
        '$window', '$element', '$timeout', 'events', 'ToastFactory', 'ResponsiveService',
        function (Page, $scope, $sce, $http, $stateParams, $window, $element, $timeout, events, ToastFactory, ResponsiveService) {
            $scope.loading = true;
            $scope.openedInNewTab = false;
            $scope.showGeneralError = false;
            $scope.$on(events.DEPARTMENT_CHANGED, () => Page.stateReload());

            init();

            function init() {
                connectToLearningBank();
                Page.showBackButton();
                addIframeLoadedEventListener();
            }

            function addIframeLoadedEventListener() {
                const iframe = $element.find('#iframeComponent');

                if (iframe.length === 0) {
                    $scope.$apply(() => $scope.loading = false);
                    return;
                }

                iframe[0].addEventListener('load', () => {
                    $scope.$apply(() => $scope.loading = false)
                });
            }

            function connectToLearningBank() {
                $http.get('/LearningBank/Get/' + $stateParams.token + '?accountModuleToken=' + $stateParams.extra)
                    .then(response => {
                        if (!response.data.ErrorMessage && response.data.OpenInNewTab) {
                            openInNewTab(response.data);
                            $scope.loading = false;
                        } else {
                            initialiazeIframeSetup(response.data)
                        }
                    }).catch(()=> {
                        ToastFactory.error('ERROR.GENERAL');
                        $scope.loading = false;
                        $timeout(() => {
                            $scope.showGeneralError = true;
                        }, 500);
                    })
            }

            function initialiazeIframeSetup(data) {
                $scope.error = data.ErrorMessage;
                $scope.url = $sce.trustAsResourceUrl(data.Url);

                if (data.ErrorMessage) {
                    $scope.loading = false
                }
                
                Page.setTitle(data.Title);
                $timeout(() => setIframeMaxHeight(), 0);
            }

            function openInNewTab(data) {      
                Page.setTitle(data.Title);
                $scope.openedInNewTab = true;

                $timeout(() => {
                    if (NativeHelper.isIOS()) {
                        let a = document.createElement("a");
                        a.href = data.Url;
                        a.target = "_blank";
                        a.click();
                    } else {
                        window.open(data.Url, "_blank");
                    }
                }, 0)
            }

            function setIframeMaxHeight() {
                if (ResponsiveService.isDesktop()) return;

                const iframeContainer = $element.find('.learningbank-iframe-container');
                if (iframeContainer.length === 0) return;
            
                const header = $element.find('header');
                const bottom = $element.find('.base-bottom');
            
                const headerHeight = header.length > 0 ? header.height() : 55; 
                const bottomHeight = bottom.length > 0 ? bottom.height() : 50;             
                const maxHeight = $window.innerHeight - headerHeight - bottomHeight;
            
                iframeContainer.css('max-height', `${maxHeight}px`);
            }
        }
    ]);
})();