(() => {
    'use strict'

    angular
        .module('App')
        .component('bookmarksList', {
            template: require('./BookmarksListComponent.tpl.html'),
            controller: ['$rootScope', '$scope', '$element', '$timeout', 'ActionSheetService', 'BookmarkService', 'ResponsiveService', 'TimeLocale', 'Page', 'ToastFactory',
                BookmarksListController],
            controllerAs: 'ctrl',
            bindings: {
                isDropdown: '<',
                dropdownVisibility: '<',
                bookmarksCount: '=?',
                hideSeeMoreBtn: '=?'
            } 
        });

    function BookmarksListController($rootScope, $scope, $element, $timeout, ActionSheetService, BookmarkService, ResponsiveService, TimeLocale, Page, ToastFactory) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.openBookmark = openBookmark;
        ctrl.showBookmarkOptions = showBookmarkOptions;
        ctrl.getIcon = getIcon;
        ctrl.BookmarkService = BookmarkService;
        ctrl.TimeLocale = TimeLocale;
        ctrl.displayedBookmarks = [];
        ctrl.allBookmarks = [];

        let currentPage = 0;
        let pageSize = 10 // default;
        let toastedBookmarkId, toastResetTimeout;
        const pathStatesBookmarks = ['contentItem', 'contentLibrary', 'socialPost'];

        const stateChangeErrorListener = $rootScope.$on('$stateChangeError', (event, toState, toParams) => {toastCustomMessage(event, toState, toParams)});
        const stateChangeSuccessListener = $rootScope.$on('$stateChangeSuccess', (event, toState, toParams) => {closeBookmarksDropdown(event, toState, toParams)});
        $scope.$watch('ctrl.dropdownVisibility', handleDropdownVisibilityChange);
        $scope.$watch('ctrl.filteredBookmarks', handleBookmarksChange);

        function init() {
            calculatePageSize();
            if (!ctrl.isDropdown) {
                getBookmarks();
            }
            attachScrollListener();
        }

        function handleBookmarksChange(newList, oldList) {
            const isDeleteAction = ctrl.recentDelete && oldList && oldList.length > newList.length;
            
            if (isDeleteAction) {
                syncDeletedStatus(newList, oldList);
                ctrl.recentDelete = false;        
                const scrollContainer = getScrollContainer();

                if (isScrolledToBottom(scrollContainer) && !allMatchingBookmarksDisplayed()) {
                    resetBookmarks();
                }

                if (allMatchingBookmarksDisplayed() && (!ctrl.filteredBookmarks || ctrl.filteredBookmarks.length === 0)) {
                    ctrl.hideSeeMoreBtn = true;
                }
            } else {
                resetBookmarks();
            }
        }        

        function resetBookmarks() {
            currentPage = 0;
            ctrl.displayedBookmarks = [];
            lazyLoadOnScreen();
        }

        function handleDropdownVisibilityChange(newValue, oldValue) {
            if (newValue === true) {
                getBookmarks();
            }
        }

        function ifToReactToStateChangeEvent(toState, toParams) {
            return pathStatesBookmarks.includes(toState.name) 
            && toParams.data 
            && toParams.data.BookmarkId
            && ( 
                !ctrl.isDropdown && !ctrl.dropdownVisibility
                || ctrl.isDropdown && ctrl.dropdownVisibility === true 
            )
        }
        
        function toastCustomMessage(event, toState, toParams) {
            if (ifToReactToStateChangeEvent(toState, toParams) 
                && toastedBookmarkId !== toParams.data.BookmarkId)
            {
                toastBookmarkUnavailable(toParams.data);
                toastedBookmarkId = toParams.data.BookmarkId;
                
                if (toastResetTimeout) {
                    clearTimeout(toastResetTimeout);
                }
                toastResetTimeout = setTimeout(() => {
                    toastedBookmarkId = undefined;
                }, 5000)
            }
        }

        function closeBookmarksDropdown(event, toState, toParams) {
            if (ctrl.dropdownVisibility && ifToReactToStateChangeEvent(toState, toParams)) {
                BookmarkService.toggleDropdownVisibility(false);
                toastedBookmarkId = undefined;
                if (toastResetTimeout) {
                    clearTimeout(toastResetTimeout);
                }
            }
        }

        function syncDeletedStatus(newList, oldList) {
            const deletedItems = oldList.filter(item => item.deleted === true);

            deletedItems.forEach(deletedItem => {
                const newItem = newList.find(item => item.BookmarkId === deletedItem.BookmarkId);
                if (newItem) {
                    newItem.deleted = true;
                }
            });
        }

        function getBookmarks(hideShimmer) {
            ctrl.isLoading = hideShimmer ? false : true;

            BookmarkService.getBookmarks()
                .then((response) => setAllBookmarks(response))
                .catch(() => setAllBookmarks([]))
                .finally(() =>  {
                    ctrl.isLoading = false;
                });
        }

        function setAllBookmarks(newList) {

            newList = newList.map(bookmark => {
                let mediaFileData = getFileData(bookmark.Media);

                return {
                    ...bookmark,
                    mediaIcon: mediaFileData.icon,
                    mediaClass: mediaFileData.class || '',
                    mediaText: mediaFileData.text || null
                }
            })

            ctrl.allBookmarks = newList;
            ctrl.filteredBookmarks = [...newList]
            ctrl.bookmarksCount = ctrl.allBookmarks.length;
        }

        function openBookmark(bookmark) {
            BookmarkService.getBookmarkLink(bookmark.BookmarkId)
                .then(({appLink}) => {
                    if (appLink && appLink.PathState) {
                        if (pathStatesBookmarks.includes(appLink.PathState)) {
                            Page.stateGo(appLink.PathState, appLink.PathToken, appLink.PathExtra, 'bookmarks', bookmark);
                            return;
                        }
                    }
                    toastBookmarkUnavailable(bookmark);
                })
                .catch(() => toastBookmarkUnavailable(bookmark))
        }

        function showBookmarkOptions(bookmark, event) {

            event.preventDefault();
            event.stopPropagation();

            var buttons = [
                {
                    text: 'BOOKMARK.DELETE',
                    icon: 'bookmark-remove',
                    onClick: function () {
                        deleteBookmark(bookmark);
                    }
                }];

            var actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, {name: 'bookmark'});
            actionSheet.show();
        }

        function deleteBookmark(bookmark, allowUndo = true) {
            if (allowUndo) {
                showUndoToast(bookmark, (cancelDelete) => {
                    if (!cancelDelete) {
                        proceedWithDeletion(bookmark);
                    }
                });
            } else {
                proceedWithDeletion(bookmark);
            }
        }

        function proceedWithDeletion(bookmark) {
            BookmarkService.deleteBookmark(bookmark.BookmarkId)
                .then(() => {
                    ctrl.allBookmarks = ctrl.allBookmarks.filter(bk => bk.BookmarkId !== bookmark.BookmarkId);
                    ctrl.displayedBookmarks = ctrl.displayedBookmarks.filter(bk => bk.BookmarkId !== bookmark.BookmarkId);
                    ctrl.bookmarksCount = ctrl.allBookmarks.length;
                    ctrl.recentDelete = true;
            });
        }

        function toastBookmarkUnavailable(bookmark) {
            ToastFactory.warning(
                'BOOKMARK.TOAST.BOOKMARK_UNAVAILABLE', 
                null,
                {
                    buttons: [ 
                        { 
                            text: 'BOOKMARK.REMOVE', 
                            onClick: () => deleteBookmark(bookmark, false) 
                        } 
                    ]

                }
            );
        }

        function showUndoToast(bookmark, callback) {
            let cancelDelete = false;
            
            bookmark.deleted = true;
            ToastFactory.info(
                'BOOKMARK.TOAST.BOOKMARK_REMOVED',
                null,
                {
                    buttons: [ 
                        { 
                            text: 'BOOKMARK.UNDO', 
                            onClick: () => { 
                                cancelDelete = true;
                                bookmark.deleted = false;
                                $scope.$apply();
                                callback(true);
                            } 
                        } 
                    ]
                }
            );
        
            $timeout(() => {
                if (!cancelDelete) {
                    callback(false);
                }
            }, 5000);
        }

        function getIcon(status) {
            switch (status) {
                case 1:
                    return 'newspaper';
                case 2:
                    return 'library';
                case 3:
                    return 'comments';
                default:
                    return 'newspaper';
            }
        }

        function getFileData(media) {
            if (!media) return {icon: 'image'}

            switch (media.MediaTypeId) {
                case 1:
                    return {icon: ''}
                case 2:
                    return {icon: 'video',  class: 'icon-on-thumbnail'}
                case 3:
                    return {icon: 'file', class: 'file-icon-with-text icon-on-thumbnail', text: 'PDF'}
                case 6:
                    return {icon: 'audio'}
                case 7:
                case 8:
                    return {icon: 'excel'}
                case 9:
                    return {icon: 'zip'}
                case 10:
                    return {icon: 'word'}
                case 11:
                    return {icon: 'powerpoint'}
                case 12:
                    return {icon: 'file', class: 'file-icon-with-text', text: 'AI'}
                case 13:
                    return {icon: 'file', class: 'file-icon-with-text', text: 'PSD'}
                case 14:
                    return {icon: 'image'}
                case 15:
                    return {icon: 'file', class: 'file-icon-with-text', text: 'Aa'}
                default:
                    return {icon: 'file'}
            }
        }
      
        function calculatePageSize() {
            const itemsInLine = ctrl.isDropdown ? 2 : 3;
            let approximatePageSize = Math.ceil(window.innerHeight / 175 * itemsInLine);

            if (ctrl.isDropdown) {
                const isEven = approximatePageSize % 2 === 0;
                pageSize = isEven ? approximatePageSize : approximatePageSize + 1;
                return;
            }

            while (approximatePageSize % 3 !== 0) {
                approximatePageSize++;
            }

            pageSize = approximatePageSize;
        }

        function lazyLoadOnScreen() {
            if (!ctrl.filteredBookmarks || ctrl.filteredBookmarks.length === 0) {
                ctrl.hideSeeMoreBtn = true;
                return;
            }

            ctrl.hideSeeMoreBtn = false;

            if (allMatchingBookmarksDisplayed()) {
                ctrl.isLoading = false;
                $scope.$apply();
                return;
            }

            const nextPage = ctrl.filteredBookmarks.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
            ctrl.displayedBookmarks = [...ctrl.displayedBookmarks, ...nextPage];
            if (currentPage !== 0) {
                ctrl.isLoading = true;
                $scope.$apply();
            }

            currentPage++;
            ctrl.isLoading = false;
        };

        function attachScrollListener() {
            const scrollContainer = getScrollContainer();
            if (scrollContainer) {
                angular.element(scrollContainer).on('scroll', function() {
                    if (isScrolledToBottom(scrollContainer)) {
                        lazyLoadOnScreen();
                    }
                });

                $scope.$on('$destroy', function() {
                    angular.element(scrollContainer).off('scroll');
                });
            }
        }

        function getScrollContainer() {
            const booksList = $element[0];
            let scrollContainer = window;

            if (ctrl.isDropdown === true) {
                scrollContainer = booksList.closest('.app-header-dropdown-body');
            }

            return scrollContainer;
        }

        function isScrolledToBottom(container) {
            let tolerableDistanceToBottom = 550;
            let scrollTop, scrollHeight, clientHeight;
        
            if (container === window) {
                scrollTop = window.scrollY;
                scrollHeight = document.documentElement.scrollHeight;
                clientHeight = window.innerHeight;
            } else {
                scrollTop = container.scrollTop;
                scrollHeight = container.scrollHeight;
                clientHeight = container.clientHeight;
            }
        
            const isAtBottom = scrollTop + clientHeight + tolerableDistanceToBottom >= scrollHeight;
            return isAtBottom;
        }

        function allMatchingBookmarksDisplayed() {
            return ctrl.displayedBookmarks.length === ctrl.filteredBookmarks.length
        }
        
        function destroy() {
            stateChangeErrorListener && stateChangeErrorListener();
            stateChangeSuccessListener && stateChangeSuccessListener();
        }
    }
})();