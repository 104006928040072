(function () {
    'use strict';

    angular.module('App')
        .component('contentCategories', {
            template: require('./ContentCategories.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$stateParams', 'Page', 'ContentWrapperService', 'ActionSheetService', ContentCategoriesController],
            bindings: {
                contentOverview: '<'
            }
        });

    function ContentCategoriesController($stateParams, Page, ContentWrapperService, ActionSheetService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.onToggleFollow = onToggleFollow;
        ctrl.selectCategory = selectCategory;
        ctrl.openCategoryFilters = openCategoryFilters;
        ctrl.showCategory = showCategory;

        function init() {
            ctrl.showFilters =
                ctrl.contentOverview.Settings.AllowToHideCategoriesWithoutPosts;
            ctrl.loadCategories = true;

            ContentWrapperService
                .getCategories($stateParams.token)
                .then(({Categories}) => {
                    ctrl.categories = Categories.map(category => {
                        return {
                            ...category,
                            selected: category.ContentCategoryToken === $stateParams?.data?.previousCategory,
                            currentLocale: selectCurrentLocale(category.TranslatableEntity)
                        }
                    });
                    ctrl.loadCategories = false;
                })
        }

        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
        }

        function selectCategory(category) {
            const token = category.ContentCategoryToken || null;
            if (category.ContentCategoryToken) {
                ctrl.contentOverview.CategoryToken = category.ContentCategoryToken;
                ctrl.contentOverview.CategoryId = category.ContentCategoryId;
            }
            ctrl.contentOverview.PageSubTitle = category.currentLocale.Title;
            ctrl.contentOverview.isDataPreloaded = true;
            Page.stateGo('content', $stateParams.token, token, ctrl.contentOverview);
        }

        function onToggleFollow(event, category) {
            event.stopPropagation();
            ContentWrapperService
                .setCategoryFollowing(category.ContentCategoryToken, !category.IsUnfollowed)
                .then(() => {
                    category.IsUnfollowed = !category.IsUnfollowed;
                })
        }

        function openCategoryFilters(event) {
            ActionSheetService
                .create(getButtons(), event && event.currentTarget, { description: 'CONTENT.FILTER.FILTER_CATEGORIES' })
                .show()

            function getButtons() {
                return [{
                    text: 'CONTENT.SHOW_ALL_CATEGORIES',
                    icon: 'list',
                    iconClass: ctrl.showAllCategories ? 'red label-color' : '',
                    rightIconClass: ctrl.showAllCategories ? 'red' : '',
                    rightIcon: ctrl.showAllCategories ? 'dot-circle' : 'circle',
                    rightPrefix: 'far',
                    onClick: () => ctrl.showAllCategories = true
                }, {
                    text: 'CONTENT.SHOW_CATEGORIES_WITH_POSTS',
                    icon: 'tasks',
                    iconClass: !ctrl.showAllCategories ? 'red label-color' : '',
                    rightIconClass: !ctrl.showAllCategories ? 'red' : '',
                    rightIcon: !ctrl.showAllCategories ? 'dot-circle' : 'circle',
                    rightPrefix: 'far',
                    onClick: () => ctrl.showAllCategories = false
                }]
            }
        }

        function showCategory(category) {
            if (ctrl.showAllCategories) {
                return true;
            } else {
                return !category.IsUnfollowed && category.HasPosts || !category.ContentCategoryToken
            }
        }
    }
})();
