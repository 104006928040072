(function () {
    'use strict';

    angular.module('App')
        .component('campaignIntroduction', {
            template: require('./CampaignIntroductionComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'TranslatableEntityService', 'CampaignService', 'ToastFactory', CampaignIntroductionController],
            bindings: {
                campaign: '<',
                onPopupRegistered: '<',
                onClose: '<'
            }
        });

    function CampaignIntroductionController($element, TranslatableEntityService, CampaignService, ToastFactory) {
        var ctrl = this;
        var popup;

        ctrl.popupId = 'CampaignIntroductionPopupId';
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.toggleTranslation = toggleTranslation;
        ctrl.isTranslationLoading = false;

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;
        }

        function close() {
            ctrl.onClose && ctrl.onClose();
            popup.remove();
            $element.remove();
        }

        function toggleTranslation() {
            if (ctrl.isTranslationLoading) {
                return;
            }

            const translateRequest = () => {
                ctrl.isTranslationLoading = true;
                return CampaignService.translateCampaignRequest(ctrl.campaign);
            };

            TranslatableEntityService.switchCurrentLocale(ctrl.campaign.TranslatableEntity, translateRequest).then((translatableEntity) => {
                ctrl.campaign.TranslatableEntity = translatableEntity;
                ctrl.isTranslationLoading = false;
            }).catch(() => {
                ctrl.isTranslationLoading = false;
                ToastFactory.error('ERROR.GENERAL');
            });
        }
    }

})();
