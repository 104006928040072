import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { RlButtonComponent } from "../RelesysUI/Components/button/relesys-button.component";
import "../RelesysUI/Components/button/relesys-button.hybrid.component";
import { AppComponent } from './app.component';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        UpgradeModule,
        RlButtonComponent,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class RelesysAppModule {}
