(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('hashtagStamped', [
        function () {
            return {
                restrict: 'A',
                link: function ($scope, $element, $attrs) { // stamp is taken from parent scope
                    registerStampChangeWatcher();
                    if (!$scope.stamp) return;

                    if (!isStampPositionCompatible($scope.stamp.Position)) return;
                    createDomElements($scope.stamp.Position)

                    function registerStampChangeWatcher() {
                        $scope.$watch('stamp.CssClassName', (newCss, oldCss) => {
                            if (newCss !== oldCss) onStampChange();
                        });
                    }

                    function createDomElements(stampPosition) {
                        const stampElement = document.createElement('div');
                        stampElement.classList.add('hashtag-stamp', stampPosition);
                    
                        setStampUrl(stampElement, $scope.stamp.Url);
                        attachDivsForFloating(stampElement, 6);
                    
                        observeHeightChanges($element[0], () => {
                            setFloatDistance(stampElement);
                        });
                    
                        $element[0].appendChild(stampElement);
                    }

                    function onStampChange() {
                        if (!$scope.stamp) {
                            return removeOldStamp();
                        };

                        const stampPosition = $scope.stamp.Position;
                        if (!isStampPositionCompatible(stampPosition)) {
                            return removeOldStamp();
                        }

                        const stampElement = $element[0].querySelector('.hashtag-stamp');
                        stampElement ? updateDomElements(stampElement, stampPosition) : createDomElements(stampPosition);
                    }

                    function removeOldStamp() {
                        const stampElement = $element[0].querySelector('.hashtag-stamp');
                        stampElement?.remove();
                    }
                    
                    function updateDomElements(stampElement, stampPosition) {
                        stampElement.className = 'hashtag-stamp';
                        stampElement.classList.add(stampPosition);
                        setStampUrl(stampElement, $scope.stamp.Url);
                    }

                    function setStampUrl(element, url) {
                        element.style.setProperty('--stamp-url', `url(${url})`);
                    }

                    function attachDivsForFloating(stampElement, count) {
                        if (!stampElement) return;
                    
                        for (let i = 0; i < count; i++) {
                            const childDiv = document.createElement("div");
                            stampElement.appendChild(childDiv);
                        }
                    }

                    function isStampPositionCompatible(position) {
                        if ($attrs.allowedStampPosition === position) return true;

                        const allowedPositions = $scope.$eval($attrs.allowedStampPosition);
                        if (Array.isArray(allowedPositions)) {
                            return allowedPositions.includes(position);
                        }
                    
                        return false;
                    }
                    
                    function setFloatDistance(stampElement) {
                        const containerHeight = $element[0].offsetHeight;
                        stampElement.style.setProperty('--float-time', `${containerHeight/100}s`);
                        stampElement.style.setProperty('--float-distance', `-${containerHeight - 63}px`);
                    }

                    function observeHeightChanges(element, callback) {
                        const resizeObserver = new ResizeObserver(() => {
                            callback();
                        });

                        resizeObserver.observe(element);

                        $scope.$on('$destroy', () => {
                            resizeObserver.disconnect();
                        });
                    }
                }
            }
        }
    ]);
})();
