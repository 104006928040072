(function () {
    'use strict';

    angular.module('App')
        .config(['$stateProvider', 
            function ($stateProvider) {
                $stateProvider
                    .state('policyView', {
                        parent: 'base',
                        url: '/policy/:token',
                        template: '<div class="policy-view-page" view-page view-page-scrolling="false"><policy-view policy="ctrl.policy"></policy-view></div>',
                        resolve: {
                            policy: ['$stateParams', 'PolicyViewService', 'ToastFactory', function($stateParams, PolicyViewService, ToastFactory) {
                                if (!$stateParams.data){
                                    return PolicyViewService.getPolicy($stateParams.token)
                                    .then(response => {
                                        return response || {};
                                    })
                                    .catch(() => {
                                        ToastFactory.error('ERROR.PAGE_NOT_LOADED');
                                        return {};
                                    });
                                } else {
                                    return $stateParams.data || {};
                                }
                            }]
                        },
                        params: {
                            backState: null,
                            data: null
                        },
                        controller: 'PolicyViewController as ctrl'
                    });
            }
        ])
        .controller('PolicyViewController', ['$translate', 'policy', 'Page',
            function ($translate, policy, Page) {
                const ctrl = this;
                ctrl.policy = policy;

                $translate('PROFILE.PRIVACY_POLICY').then(function (translation) {
                    Page.setTitle(translation);
                });

                Page.showBackButton();
            }]);

})();