(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlSorting', {
            template: require('./SortingComponent.html'),
            controller: ['$timeout', '$element', 'ActionSheetCustomService', SortingPopupController],
            controllerAs: 'ctrl',
            bindings: {
                onSelect: '<',
                sortingOptions: '<',
                headerTitle: '@',
                selectedOptionId: '<',
                filterBtnStyle: '<',
                sortActive: '=?'
            }
        });

    function SortingPopupController($timeout, $element, ActionSheetCustomService) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onChanges = onChanges;
        ctrl.openSortingPopup = openSortingPopup;
        ctrl.onSortSelected = onSortSelected;
        ctrl.defaultBtnTitle = 'SEARCH.SORT';

        function init() {
            ctrl.sortingOptions = ctrl.sortingOptions || [
                {id: 1, icon: 'bullseye-arrow', title: 'SORTING.MOST_RELEVANT'},
                {id: 2, icon: 'arrow-up-1-9', title: 'SORTING.NEWEST_FIRST'},
                {id: 3, icon: 'arrow-down-1-9', title: 'SORTING.OLDEST_FIRST'},
            ];
            initSort();
        }

        function onChanges(changes) {
            if (isSortCleared(changes.selectedOptionId) || 
                isOptionsListChanged(changes.sortingOptions)) 
            {
                initSort();
            }
        }
        
        function isSortCleared(selectedOptionId) {
            return (
                selectedOptionId &&
                !selectedOptionId.isFirstChange() &&
                !selectedOptionId.currentValue
            );
        }
        
        function isOptionsListChanged(options) {
            return options && (options.previousValue !== options.currentValue);
        }        

        function openSortingPopup() {
            ActionSheetCustomService.create(
                `<rl-sorting-popup 
                     options="options"
                     on-sort-selected="onSortSelected"
                     header-title="{{ headerTitle }}"
                 ></rl-sorting-popup>`,
                {
                    options: ctrl.sortingOptions,
                    headerTitle: ctrl.headerTitle,
                    onSortSelected: onSortSelected
                },
                $element[0]
            );
        }

        function initSort() {    
            unselectAll();        

            if (hasSelectedOptionId()) {
                selectOption();
            } else if (ctrl.sortingOptions?.length){
                showInitialSort();
                ctrl.sortingOptions[0].selected = true;
            } else {
                ctrl.sortActive = false;
            }
        }

        function showInitialSort() {
            ctrl.sortActive = false;
            ctrl.sortBtnTitle = ctrl.defaultBtnTitle;
        }

        function hasSelectedOptionId() {
            return typeof ctrl.selectedOptionId === 'number' && !isDefault(ctrl.selectedOptionId);
        }

        function unselectAll() {
            ctrl.sortingOptions = ctrl.sortingOptions.map(opt => ({...opt, selected: false}));
        }

        function isDefault(optionId) {
            return optionId === ctrl.sortingOptions[0]?.id;
        }

        function selectOption() {
            ctrl.sortingOptions = ctrl.sortingOptions.map(option => {
                const isSelected = Number(option.id) === Number(ctrl.selectedOptionId);
                
                if (isSelected) {
                    ctrl.sortBtnTitle = option.title;
                }
                
                return { ...option, selected: isSelected };
            });
            ctrl.sortActive = true;
        }
        
        function onSortSelected(option) {
            if (isDefault(option?.id)) {
                showInitialSort();
            } else {
                ctrl.sortActive = true;
                ctrl.sortBtnTitle = setSortBtnTitle();
            }
            $timeout(() => {
                ctrl.onSelect && ctrl.onSelect(option);
            })
        }

        function setSortBtnTitle() {
            return ctrl.sortingOptions.find(option => option.selected)?.title || ctrl.defaultBtnTitle;
        }
    }
})();