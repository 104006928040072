(() => {
    'use strict';

    angular
        .module('App')
        .component('popupWrapper', {
            template: ['$attrs', $attrs => {
                const templates = {
                    'full': require('./PopupWrapperComponent.tpl.html'),
                    'clean': require('./PopupWrapperComponentClean.tpl.html'),
                    'half': require('./PopupWrapperComponentHalf.tpl.html')
                };

                if ($attrs.template) {
                    return templates[$attrs.template];
                } else {
                    return templates['full'];
                }
            }],
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$scope', '$timeout', '$element', 'PopupWrapperService', 'Page', 'ToastFactory', PopupWrapperController],
            bindings: {
                popupId: '@',
                innerClass: '@',
                headerTitle: '<',
                headerSubTitle: '@',
                hideFooter: '<',
                onClose: '<',
                onOpen: '<',
                onRenderComplete: '<',
                template: '@',
                isFlex: '<',
                popupCtrl: '=?',
                onPopupRegistered: '<',
                hiddenMode: '<',
                moduleName: '@',
                moduleToken: '@',
                showWarningOnLeave: '<',
                shimmer: '<'
            },
            transclude: {
                'footer': '?popupFooter'
            }
        });

    function PopupWrapperController($rootScope, $scope, $timeout, $element, PopupWrapperService, Page, ToastFactory) {
        const ctrl = this;
        let openTimeout, closeListener;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.remove = remove;
        ctrl.open = open;
        ctrl.hide = hide;
        ctrl.close = close;
        ctrl.onLeave = onLeave;

        function init() {
            ctrl.popupCtrl = ctrl;
            ctrl.isVisible = false;
            ctrl.popupClass = generatePopupClass();

            PopupWrapperService.registerEvent(ctrl, ctrl.onPopupRegistered);
        }

        function generatePopupClass() {
            let popupClass = '';

            if (ctrl.moduleName) {
                popupClass += `${ctrl.moduleName}-popup`;

                if (ctrl.moduleToken) {
                    popupClass += ` ${ctrl.moduleName}-popup-${ctrl.moduleToken}`;
                } else {
                    popupClass += ` ${ctrl.moduleName}-popup-${Page.getCurrentToken()}`;
                }
            }

            return popupClass
        }

        function destroy() {
            PopupWrapperService.unRegisterEvent(ctrl);
            enableBodyScroll();

            $element.remove();
            closeListener && closeListener();
        }

        function remove() {
            destroy();
        }

        function open(data) {
            ToastFactory.clear();

            ctrl.show = false;
            ctrl.onOpen && ctrl.onOpen(data, ctrl);
            ctrl.isVisible = true;
            openTimeout = $timeout(function () {
                ctrl.show = true;
                if (ctrl.onRenderComplete) {
                    ctrl.onRenderComplete();
                }
                document.body.style.overflow = 'hidden';
            }, 300);
        }

        function hide() {
            ctrl.isVisible = false;
            enableBodyScroll();
        }
        
        function onLeave($event) {
            $event && $event.stopPropagation();
            PopupWrapperService.showConfirmationPopupOnLeave()
                .then(() => {
                    close(null, $event);
                })
        }

        function close(data, $event) {
            $event && $event.stopPropagation();
            enableBodyScroll();
            if (ctrl.onClose) {
                ctrl.onClose(data, ctrl);
            } else {
                ctrl.isVisible = false;
            }
        }

        function enableBodyScroll() {
            $timeout.cancel(openTimeout);
            document.body.style.removeProperty('overflow');
        }

        closeListener = $rootScope.$on('ClosePopups', (event, skipBackNavigation) => {
            ctrl.isVisible && close(skipBackNavigation);
        });
    }
})();
