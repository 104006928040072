(function () {
    'use strict';
    angular.module('App').factory('UserManagementService', [
        '$stateParams', '$http', 'PopupWrapperService',
        function ($stateParams, $http, PopupWrapperService) {
            return {
                validate: validate,
                openFiltersPopup: openFiltersPopup,
                openCreateUserPopup: openCreateUserPopup,
                openUpdateUserPopup: openUpdateUserPopup,
                approveUser: approveUser,
                declineUser: declineUser,
                disableUser: disableUser,
                enableUser: enableUser,
                getCleanFilterModel: getCleanFilterModel,
                getSortingOptions: getSortingOptions,
                getActivityFilterOptions: getActivityFilterOptions,
                getSettings: getSettings,
                getUser: getUser,
                getUsers: getUsers,
                getActivityIndicatorOptions: getActivityIndicatorOptions
            }

            function validate(model, settings) {
                const validationData = [];

                !model.Name &&
                validationData.push({
                    Message: "USER_MANAGEMENT.ERROR.NAME_REQUIRED",
                    Name: "Name",
                    Type: 2
                });

                model.SendLoginInfoSms && !model.Phone &&
                validationData.push({
                    Message: "USER_MANAGEMENT.ERROR.PHONE_REQUIRED",
                    Name: "Phone",
                    Type: 2
                });

                model.SendLoginInfoEmail && !model.Email &&
                validationData.push({
                    Message: "USER_MANAGEMENT.ERROR.EMAIL_REQUIRED",
                    Name: "Email",
                    Type: 2
                });

                if (!settings.DefaultRoleList.length) {
                    (!model.UserGroups || model.UserGroups.length === 0) &&
                    validationData.push({
                        Message: "USER_MANAGEMENT.ERROR.USERGROUP_REQUIRED",
                        Name: "UserGroups",
                        Type: 2
                    });
                }

                !model.DepartmentId &&
                validationData.push({
                    Message: "USER_MANAGEMENT.ERROR.DEPARTMENT_REQUIRED",
                    Name: "DepartmentId",
                    Type: 2
                });

                return validationData;
            }

            function openFiltersPopup(allowedDepartments, filterModel, onFiltered, activityManagerViewEnabled) {
                PopupWrapperService.createDynamic(
                    `<user-management-filters-popup 
                                        on-filtered="onFiltered"
                                        allowed-departments="allowedDepartments"
                                        model="filterModel"
                                        activity-manager-view-enabled="activityManagerViewEnabled"
                                   ></user-management-filters-popup>`,
                    {
                        onFiltered: onFiltered,
                        allowedDepartments: allowedDepartments,
                        filterModel: filterModel,
                        activityManagerViewEnabled: activityManagerViewEnabled
                    }
                )
            }
            function openCreateUserPopup(accountModuleToken, userToken, updateUsersOnClose) {
                PopupWrapperService.createDynamic(
                    `<user-management-create 
                                        account-module-token="accountModuleToken"
                                        user-token="userToken"
                                        update-users-on-close="updateUsersOnClose"
                                   ></user-management-create>`,
                    {
                        accountModuleToken: accountModuleToken,
                        userToken: userToken,
                        updateUsersOnClose: updateUsersOnClose,
                    }
                )
            }

            function openUpdateUserPopup(accountModuleToken, userToken, updateUsersOnClose) {
                PopupWrapperService.createDynamic(
                    `<user-management-update 
                                        account-module-token="accountModuleToken"
                                        user-token="userToken"
                                        update-users-on-close="updateUsersOnClose"
                                   ></user-management-update>`,
                    {
                        accountModuleToken: accountModuleToken,
                        userToken: userToken,
                        updateUsersOnClose: updateUsersOnClose,
                    }
                )
            }

            function getSettings(accountModuleToken) {
                return $http.get('/UserManagement/GetSettings?token=' + accountModuleToken);
            }

            function getUser(userToken, accountModuleToken) {
                return $http.get('/UserManagement/GetUser?token=' + userToken +
                    '&accModuleToken=' + accountModuleToken);
            }

            function getUsers(token) {
                return $http.get('/UserManagement/GetUsers/' + token);
            }
            
            function approveUser(userToken) {
                return $http.post('/UserManagement/ApproveDeclineUser', {
                    AccountModuleToken: $stateParams.token,
                    UserToken: userToken,
                    Approve: true
                }).then(resp => resp.data)
            }

            function declineUser(userToken) {
                return $http.post('/UserManagement/ApproveDeclineUser', {
                    AccountModuleToken: $stateParams.token,
                    UserToken: userToken,
                    Decline: true
                }).then(resp => resp.data)
            }

            function disableUser(userToken) {
                return changeActiveDisabledStatus(userToken, false);
            }

            function enableUser(userToken) {
                return changeActiveDisabledStatus(userToken, true);
            }

            function changeActiveDisabledStatus(userToken, makeEnabled) {
                return $http.post('/UserManagement/SetActiveState', 
                    {
                        token: userToken,
                        accModuleToken: $stateParams.token,
                        enable: makeEnabled
                    }
                ).then(resp => resp.data)
            }

            function getCleanFilterModel() {
                return {
                    sorting: {
                        SortProperty: getSortingOptions()[0]
                    },
                    filters: {
                        ActivityStatus: getActivityFilterOptions()[0]
                    }
                }
            }

            function getSortingOptions() {
                return [
                    {id: 0, name: 'USER_MANAGEMENT.FILTERS.NAME', value: 1, icon: 'user', class: ''},
                    {id: 1, name: 'USER_MANAGEMENT.FILTERS.ACTIVITY', value: 2, icon: 'clock', class: ''},
                ]
            }

            function getActivityFilterOptions() {
                // values match enum ActivityStatus
                return [
                    {id: 0, name: 'USER_MANAGEMENT.FILTERS.ALL', value: null, activityIndicator: 'all'},
                    {id: 1, name: 'USER_MANAGEMENT.FILTERS.ACTIVE_TODAY', value: 1, activityIndicator: 'today'},
                    {id: 2, name: 'USER_MANAGEMENT.FILTERS.ACTIVE_7_DAYS', value: 2, activityIndicator: 'week'},
                    {id: 3, name: 'USER_MANAGEMENT.FILTERS.ACTIVE_30_DAYS', value: 3, activityIndicator: 'month'},
                    {id: 4, name: 'USER_MANAGEMENT.FILTERS.NOT_ACTIVE_7_DAYS', value: 7, activityIndicator: 'no-activity-in-week'},
                    {id: 5, name: 'USER_MANAGEMENT.FILTERS.NOT_ACTIVE_30_DAYS', value: 8, activityIndicator: 'no-activity-in-month'},
                    {id: 6, name: 'USER_MANAGEMENT.FILTERS.HAD_ACTIVITY', value: 4, activityIndicator: 'had-activity'},
                    {id: 7, name: 'USER_MANAGEMENT.FILTERS.NO_ACTIVITY', value: 5, activityIndicator: 'no-activity'}
                ]
            }

            function getActivityIndicatorOptions() {
                return [
                    {value: 1, activityIndicator: 'today'},
                    {value: 2, activityIndicator: 'week'},
                    {value: 3, activityIndicator: 'month'},
                    {value: 4, activityIndicator: 'had-activity'},
                    {value: 5, activityIndicator: 'no-activity'},
                    {value: 6, activityIndicator: 'pending'},
                    {value: 9, activityIndicator: 'disabled'},
                ]
            }
        }]);
})();
