(function () {
    'use strict';

    var app = angular.module('App');


    app.factory('MentionService', ['$q', '$http', 'Profile', function ($q, $http, Profile) {

        return {
            getSocialUsers: getSocialUsers,
            getContentUsers: getContentUsers,
            formatMentions: formatMentions,
            searchUsers: searchUsers,
            getHashTags: getHashTags,
            sortHashtagsSuggestions: sortHashtagsSuggestions,
            parseMentionsInText: parseMentionsInText,
            parseMentionsInTextForEdit: parseMentionsInTextForEdit,
            mentionsFromString: mentionsFromString,
            parseMentionToText: parseMentionToText,
        };

        function getSocialUsers(id, loadUsersForPost, token) {
            if (loadUsersForPost) {
                if (id === null || id === undefined) {
                    return null;
                }

                return $http.post('/Social/GetAvailableUsersAndDepartmentsForSocialPost', {
                    socialPostId: id
                }).then(resp => [...resp.data.Users.filter((user) => !user.IsBlocked), ...resp.data.Departments, ...resp.data.UserGroups]);
            } else {
                if ((token === null || token === undefined)) {
                    return null;
                }

                return $http.post('/Social/GetAvailableUsersAndDepartmentsForSocialGroup', {
                    token: token,
                    socialGroupId: id
                }).then(resp => [...resp.data.Users.filter((user) => !user.IsBlocked), ...resp.data.Departments, ...resp.data.UserGroups]);
            }
        }

        function getContentUsers(contentId, loadUsersForPost, token) {
            loadUsersForPost = loadUsersForPost === true;

            const url = loadUsersForPost ? '/ContentModule/GetUsersAndDepartmentsWithAccessByPost' :
                '/ContentModule/GetUsersAndDepartmentsWithAccessByModule';

            if (loadUsersForPost == false && (_.isString(token) === false || token.length == 0)) {
                return null;
            }

            if (loadUsersForPost && (_.isNumber(contentId) === false || contentId <= 0)) {
                return null;
            }

            return $http.post(url, {
                token: token,
                contentId: contentId
            }).then(resp => [...resp.data.Users.filter((user) => !user.IsBlocked), ...resp.data.Departments, ...resp.data.UserGroups]);
        }
        
        function mentionsFromString(text) {
            const tokens = text.match(/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/gm);
            return [...new Set(tokens)];
        }

        function formatMentions(srcModel, isMultipleLanguagesEnabled) {
            const TaggedUsers = [], TaggedDepartments = [], TaggedUserGroups = [];

            if (isMultipleLanguagesEnabled) {
                srcModel.selectedLanguages.forEach(format)
            } else {
                format(srcModel)
            }

            function format(model) {
                model.mentions?.forEach(mention => {
                    if (mention.UserId && !_.find(TaggedUsers, mention)) {
                        TaggedUsers.push(mention.UserId);
                    }

                    if (mention.userId && !_.find(TaggedUsers, mention)) {
                        TaggedUsers.push(mention.userId);
                    }
                    if (mention.DepartmentId && !_.find(TaggedDepartments, mention)) {
                        TaggedDepartments.push(mention.DepartmentId);
                    }

                    if (mention.UserGroupId && !_.find(TaggedUserGroups, mention)) {
                        TaggedUserGroups.push(mention.UserGroupId);
                    }
                });
            }

            return {
                TaggedUsers: TaggedUsers,
                TaggedDepartments: TaggedDepartments,
                TaggedUserGroups: TaggedUserGroups,
            }
        }

        function searchUsers(string, users) {
            var filtered = [],
                count = 0;

            return $q(function (resolve) {
                filtered = users.filter(user => {
                    const name = ((user?.name ? user.name : '') + (user.UserName ? user.UserName : '') +
                            (user.DepartmentId ? ' ' + user.DepartmentName : '') +
                            (user.UserGroupName ? ' ' + user.UserGroupName : '')).toLowerCase(),
                        isMatch = name.split(' ').some(subName => {
                            return user.userId !== Profile.getProfile().UserToken &&
                                subName.substring(0, string.length).toLowerCase() === string.toLowerCase()
                        })

                    if (isMatch) {
                        count++
                    }

                    return count < 50 && isMatch
                });
                resolve(filtered);
            })
        }

        function getHashTags(token) {
            return $http.get('/Hashtag/GetHashtagSuggestions/' + token)
                .then(resp => resp.data);
        }

        function sortHashtagsSuggestions(hashtags, textSrc) {
            const text = textSrc.toLowerCase().trim().substring(1);

            function sortByPosts(a, b) {
                return b.PostsCount - a.PostsCount
            }

            return hashtags.filter(tag => {
                return tag.Name.toLowerCase().indexOf(text.toLowerCase()) === 0;
            }).sort(function (a, b) {
                if (a.Featured && b.Featured) {
                    return sortByPosts(a, b)
                } else if (a.Featured) {
                    return -1
                } else if (b.Featured) {
                    return 1
                } else {
                    return sortByPosts(a, b)
                }
            });
        }

        function parseMentionsInTextForEdit(text, users, departments, userGroups) {
            const tokens = text.match(/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/gm);
            let user, department, userGroup;

            if (tokens?.length) {
                tokens.forEach(token => {
                    user = null;
                    department = null;
                    userGroup = null;

                    if (users) {
                        user = users.find(el => el.UserToken === token || el.userId === token);
                    }
                    if (departments) {
                        department = departments.find(el => el.DepartmentToken === token);
                    }
                    if (userGroups) {
                        userGroup = userGroups.find(el => el.UserGroupToken === token);
                    }

                    if (user?.name) {
                        text = text.replace('@' + token, '@' + user.name);
                    }
                    if (user?.UserName) {
                        text = text.replace('@' + token, '@' + user.UserName);
                    }
                    if (department) {
                        text = text.replace('@' + token, '@' + department.DepartmentName);
                    }
                    if (userGroup) {
                        text = text.replace('@' + token, '@' + userGroup.UserGroupName);
                    }
                })
            }

            return text;
        }
        
        function parseMentionToText(text, users) {
            const tokens = text.match(/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/gm);
            let user;
            
            if (tokens?.length) {
                tokens.forEach(token => {
                    user = null;
                    
                    if (users) {
                        user = users.find(el => (el.UserToken || el.userId)?.toLowerCase() === token.toLowerCase());
                    }

                    if (user?.name) {
                        text = text.replace('@' + token, '@' + user.name);
                    } else {
                        text = text.replace('@' + token, '@Blocked user');
                    }
                });
            }

            return text;
        }

        function parseMentionsInText(text, users, departments, userGroups, hashtagsEnabled) {
            const tokens = text.match(/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/gm);
            let user, department, userGroup;

            if (hashtagsEnabled) {
                text = text.replace(/(^|\s)(#(?:[\S])+)/ig, '$1<span class="post-hashtag">$2</span>');
            }

            if (tokens?.length) {
                tokens.forEach(token => {
                    user = null;
                    department = null;
                    userGroup = null;

                    if (users) {
                        user = users.find(el => (el.UserToken || el.userId)?.toLowerCase() === token.toLowerCase());
                    }
                    if (departments) {
                        department = departments.find(el => el.DepartmentToken?.toLowerCase() === token.toLowerCase());
                    }
                    if (userGroups) {
                        userGroup = userGroups.find(el => el.UserGroupToken?.toLowerCase() === token.toLowerCase());
                    }

                    if (user?.name) {
                        text = text.replace('@' + token,
                            '<span class="post-tag">@' + user.name + '<b class="post-tag-user-token">' + token + '</b></span>');
                    }

                    if (user?.UserName) {
                        text = text.replace('@' + token,
                            '<span class="post-tag">@' + user.UserName + '<b class="post-tag-user-token">' + token + '</b></span>');
                    }
                    if (department) {
                        text = text.replace('@' + token,
                            '<span class="post-tag">@' + department.DepartmentName + '<b class="post-tag-department-token">' + token + '</b></span>');
                    }
                    if (userGroup) {
                        text = text.replace('@' + token,
                            '<span class="post-tag">@' + userGroup.UserGroupName + '<b class="post-tag-user-group-token">' + token + '</b></span>');
                    }
                    
                    if (!user && !departments && !userGroup) {
                        text = text.replace('@' + token,
                            '<span class="tag-label">@Blocked user</span>');
                    }
                });
            }

            return text;
        }
    }]);
})();