(function () {
    'use strict';

    angular
        .module('App')
        .component('addEventUpdate', {
            template: require('./AddEventUpdateComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$timeout', '$element', 'AddEventUpdateService', 'CalendarRestService',
                'ToastFactory', 'TranslationService', 'Page', AddEventUpdateController],
            bindings: {
                eventToken: '<',
                isUpdate: '<',
                onEventUpdateCreated: '<',
            }
        });

    function AddEventUpdateController($scope, $timeout, $element, AddEventUpdateService, CalendarRestService,
                                      ToastFactory, TranslationService, Page) {
        var ctrl = this, popup, isEventUpdateSaving = false, translationWatcher, initializing;
        let eventUpdateChangeWatcher;

        ctrl.data = {};
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.save = save;
        ctrl.validateUserLanguage = validateUserLanguage;
        ctrl.translatedText = [];
        ctrl.getTranslation = getTranslation;
        ctrl.isTranslateDisabled = isTranslateDisabled;
        ctrl.requireUserLanguage = !Page.getSettings().CulturesRework;
        ctrl.initUpdateEventChangeWatcher = initUpdateEventChangeWatcher;

        function onPopupRegistered(popupSrc) {
            popup = popupSrc;
            popup.open();
        }

        function validateUserLanguage() {
            ctrl.isUserLanguageValid = ctrl.requireUserLanguage ? ctrl.eventUpdate.selectedLanguages[0].BodyText : ctrl.eventUpdate.selectedLanguages.some(language => language.BodyText);
        }

        function onOpen() {
            initializing = true;
            ctrl.eventUpdate = {};
            ctrl.eventUpdate.selectedLanguages = [];
            ctrl.isUserLanguageValid = false;

            if (ctrl.isUpdate) {
                CalendarRestService.getEditEventUpdateData(ctrl.eventToken).then(data => {
                    ctrl.languages = data.languages;
                    ctrl.eventUpdate.EventMedias = data.attachments;
                    ctrl.eventUpdate.selectedLanguages = data.translations;
                    ctrl.translatedText = _.cloneDeep(ctrl.eventUpdate.selectedLanguages);
                    ctrl.isUserLanguageValid = !!ctrl.eventUpdate.selectedLanguages[0].BodyText;
                    ctrl.settings = data.settings;
                    
                    $timeout(() => {
                        ctrl.settings.AllowAutomaticTranslation && initTranslationWatcher();
                    })
                })
            } else {
                CalendarRestService.getCreateEventUpdateData(ctrl.eventToken).then(data => {
                    ctrl.languages = data.languages;
                    ctrl.settings = data.settings;
                    
                    $timeout(() => {
                        ctrl.settings.AllowAutomaticTranslation && initTranslationWatcher();
                    })
                })
            }
        }

        function close() {
            popup.remove()
            $element.remove()
            eventUpdateChangeWatcher && eventUpdateChangeWatcher();
        }
        
        function initUpdateEventChangeWatcher() {
            let initializing = true;

            eventUpdateChangeWatcher = $scope.$watch(() => ctrl.eventUpdate,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function save() {
            const eventUpdateModel = AddEventUpdateService.prepareDataForServer(ctrl.eventUpdate);

            if (!isEventUpdateSaving) {
                isEventUpdateSaving = true;


                if (ctrl.eventUpdateToken) {
                    CalendarRestService
                        .editEventUpdate(eventUpdateModel, ctrl.eventToken)
                        .then(onSaveSuccess)
                        .catch(onSaveError);
                } else {
                    CalendarRestService
                        .createEventUpdate(eventUpdateModel, ctrl.eventToken)
                        .then(onSaveSuccess)
                        .catch(onSaveError);
                }
            }
        }

        function onSaveSuccess(data) {
            isEventUpdateSaving = false;
            ToastFactory.success(ctrl.eventUpdateToken ? 'CALENDAR.EVENT_UPDATE.UPDATED' : 'CALENDAR.EVENT_UPDATE.CREATED');
            ctrl.onEventUpdateCreated && ctrl.onEventUpdateCreated(data);
            close();
        }

        function onSaveError(error) {
            isEventUpdateSaving = false;
            ToastFactory.error(error.Message);
        }

        function getTranslation() {
            if (ctrl.translatedText?.length && isShowTranslationChangeWarning()) {
                const currentCultureId = ctrl.eventUpdate.selectedLanguages[ctrl.currentIndex]?.CultureId;
                const languages = ctrl.languages
                    .filter(culture => culture.AllowAutoTranslation && culture.CultureId !== currentCultureId)
                    .map(culture => culture.Name).join(', ');
                TranslationService.confirmTranslations(languages).then(translate)
            } else {
                translate();
            }
        }

        function translate() {
            const eventToken = ctrl.eventToken;
            const selectedLanguages = ctrl.eventUpdate.selectedLanguages;
            ctrl.isTranslating = true;

            TranslationService.translateEventUpdate(eventToken, selectedLanguages, ctrl.currentIndex).then(translations => {
                ctrl.eventUpdate.selectedLanguages = ctrl.eventUpdate.selectedLanguages.map(language => ({ ...language, AutoTranslated: false}));
                
                translations.translations && translations.translations.forEach(translation => {
                    const locale = ctrl.eventUpdate.selectedLanguages.find(culture => culture.CultureId === translation.CultureId);

                    locale.BodyText = translation.BodyText;
                    locale.AutoTranslated = true;
                })
                
                ctrl.translatedText = _.cloneDeep(ctrl.eventUpdate.selectedLanguages);
            }).finally(() => {
                ctrl.isTranslating = false;
                ctrl.translateDisabled = true;
                ctrl.eventUpdate.selectedLanguages[ctrl.currentIndex].AutoTranslated = false;
            })
        }

        function isTranslateDisabled() {
            return ctrl.isTranslating || ctrl.translateDisabled
        }

        function initTranslationWatcher() {
            translationWatcher = $scope.$watch(() => {
                    return ctrl.eventUpdate.selectedLanguages[ctrl.currentIndex];
                },
                (newVal) => {
                    const currentItem = ctrl.translatedText.find((item) => item.CultureId === newVal?.CultureId);
                    ctrl.translateDisabled = !newVal ||
                        (newVal?.BodyText ?? '').trim() === (currentItem?.BodyText ?? '').trim() ||
                        ctrl.eventUpdate.selectedLanguages.length < 2;

                    if (newVal && !ctrl.translateDisabled) {
                        ctrl.eventUpdate.selectedLanguages[ctrl.currentIndex].AutoTranslated = false;
                    }
                }, true
            )
        }

        function isShowTranslationChangeWarning() {
            return ctrl.eventUpdate.selectedLanguages
                .some(lang => {
                    const item = ctrl.translatedText?.find((item) => item.CultureId === lang.CultureId);
                    return lang.BodyText?.trim() !== item?.BodyText?.trim();
                })
        }
    }
})();