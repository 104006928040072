(() => {
    'use strict';

    angular
        .module('App')
        .component('notificationViewer', {
            template: require('./NotificationViewer.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['NotificationFactory', 'HeaderButtonsFactory', 'PopupWrapperService', 'ActionSheetService',
                'ToastFactory', 'ResponsiveService', notificationViewerController],
            bindings: {}
        });


    function notificationViewerController(NotificationFactory, HeaderButtonsFactory, PopupWrapperService,
                                          ActionSheetService, ToastFactory, ResponsiveService) {
        const ctrl = this;
        
        ctrl.settingsPopupId = 'NotificationsSettingsPopup';

        ctrl.getHeaderButtons = function () {
            return [
                {
                    icon: 'options-app',
                    onClick: function ($event) {
                        ctrl.showOptions($event);
                    }
                }
            ];
        };

        ctrl.modalPage = {};
        ctrl.showUndo = false;
        ctrl.disablingNotification = null;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.openSettings = openSettings;
        ctrl.showOptions = showOptions;
        ctrl.markAllAsRead = markAllAsRead;

        function init() {
            // Reload data
            HeaderButtonsFactory.createButtonsList(ctrl.getHeaderButtons);
            NotificationFactory.markAllAsSeen();
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
        }

        function showOptions(event) {
            var buttons = [{
                text: 'NOTIFICATION.MARK_ALL_AS_READ',
                icon: 'save',
                iconClass: 'icon-shape',
                onClick: function () {
                    ctrl.markAllAsRead();
                }
            }, {
                text: 'NOTIFICATION.NOTIFICATION_SETTINGS',
                icon: 'settings',
                iconClass: 'icon-shape',
                onClick: function () {
                    ctrl.openSettings();
                }
            }];

            var actionSheet;
            if (ResponsiveService.isDesktop()) {
                actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, { name: 'notification-viewer'});
            } else {
                actionSheet = ActionSheetService.create(buttons, null, null, { name: 'notification-viewer'});
            }
            actionSheet.show();
        }

        function openSettings() {
            PopupWrapperService.getPopup(ctrl.settingsPopupId).open();
        }

        function markAllAsRead() {
            NotificationFactory.markAllAsRead().then(function () {
                // do nothing
            }, function () {
                ToastFactory.error('ERROR.GENERAL');
            });
        }
    }
})();