(function () {
    'use strict';

    angular
        .module('App')
        .component('fileUploader', {
            template: require('./FileUploaderComponent.tpl.html'),
            controllerAs: 'ctrl',
            transclude: true,
            controller: ['$element', '$scope', '$http', 'Page', 'Profile', 'ToastFactory', FileUploaderController],
            bindings: {
                acceptTypes: '@',
                multiple: '<',
                maxSize: '@',
                onAdd: '&',
                onError: '&',
                onProgress: '&',
                onDone: '&',
                onRemove: '&',
                removeFile: '=',
                browse: '=?',
                disabled: '<'
            }
        });

    function FileUploaderController($element, $scope, $http, Page, Profile, ToastFactory) {
        var ctrl = this, clientUploadStartTime = 0;

        ctrl.fpInstance = null;

        // event handling
        ctrl.browseFiles = browseFiles;

        // Init events
        this.$onInit = function () {
            createFilePondInstance();

            // Hook up to outside events
            ctrl.removeFile = removeFile;
            ctrl.browse = browseFiles;

            // Listen for input changes
            var input = $('input[type=file]', $element);
            input.on('change', function (ev) {
                var files = ev.originalEvent.target.files;
                for (var i = 0; i < files.length; i++) {
                    ctrl.fpInstance.addFile(files.item(i));
                }

                $scope.$apply();
            });
        };

        this.$onDestroy = function () {
            try {
                // Attempt to destroy the FilePond instance to not leak memory
                ctrl.fpInstance.destroy();
                ctrl.fpInstance = null;
            } catch (ex) {
                // We'll do no more work
                console.error(ex);
            }
        };

        function createFilePondInstance() {
            // Get defaults
            var settings = Page.getSettings();
            var profile = Profile.getProfile();

            // Accepted file types
            var acceptedFileTypes = [];
            if (_.isString(ctrl.acceptTypes) && ctrl.acceptTypes.length > 1) {
                acceptedFileTypes = ctrl.acceptTypes.replace(' ', '').split(',');
            }
            var allowFileTypeValidation = (acceptedFileTypes.indexOf('*/*') < 0 && acceptedFileTypes.length > 0);

            // Remove accepted files types if empty
            if (allowFileTypeValidation === false) {
                // For our Android native app, we require the */* wildcard type on versions below 1.3.41,
                // so we keep that here for backward compatibility
                if (NativeHelper.isAndroid() === true) {
                    acceptedFileTypes = ['*/*'];
                } else {
                    acceptedFileTypes = [];
                }
            }

            // File Size
            var maxFileSize = "500MB";
            if (_.isString(ctrl.maxSize) && ctrl.maxSize.length > 2) {
                maxFileSize = ctrl.maxSize;
            }
            ctrl.maxSize = maxFileSize;

            // Multiple
            var allowMultiple = true;
            if (_.isString(ctrl.multiple)) {
                allowMultiple = ctrl.multiple.toLowerCase() === 'true';
            } else if (_.isBoolean(ctrl.multiple)) {
                allowMultiple = ctrl.multiple;
            }

            // Create instance
            ctrl.fpInstance = FilePond.create({
                maxParallelUploads: 3,
                allowMultiple: allowMultiple,
                allowRevert: false,
                allowPaste: false,
                allowFileTypeValidation: allowFileTypeValidation,
                acceptedFileTypes: acceptedFileTypes,
                allowFileSizeValidation: true,
                maxFileSize: maxFileSize,
                server: {
                    url: settings.MediaServerDomain,
                    process: {
                        url: '/Upload',
                        method: 'POST',
                        headers: {'X-Requested-With': 'XMLHttpRequest'},
                        ondata: function (formData) {
                            formData.append('AccountToken', profile.AccountToken);
                            formData.append('UserToken', profile.UserToken);
                            return formData;
                        },
                        onload: function (response) {
                            try {
                                var resp = JSON.parse(response);
                                logUploadTime(performance.now(), resp);
                                return resp;
                            } catch (ex) {
                                // Do nothing
                            }
                        }
                    },
                    fecth: null,
                    revert: null,
                    load: null,
                    restore: null
                },
                instantUpload: true,
                onwarning: onWarning,
                onerror: onError,
                onaddfilestart: onAddFileStart,
                onaddfileprogress: onAddFileProgress,
                onprocessfilestart: onProcessFileStart,
                onprocessfileprogress: onProcessFileProgress,
                onprocessfile: onProcessFile
            });
        }

        function logUploadTime(clientUploadEndTime, resp) {
            var clientUploadTime = parseInt(clientUploadEndTime - clientUploadStartTime),
                mediaId = _.get(resp, 'Files[0].MediaId');

            mediaId && $http.post(Page.getSettings().MediaServerDomain + '/Upload/SetClientUploadTime', {
                MediaId: mediaId,
                ClientUploadTime: clientUploadTime
            })
        }

        // Normal events
        function browseFiles() {
            ctrl.fpInstance.browse();
        }

        function removeFile(id, mediaId) {
            var file = ctrl.fpInstance.getFile(id);

            ctrl.onRemove && ctrl.onRemove({id: id});
            file && file.abortLoad();
            file && file.abortProcessing();
            ctrl.fpInstance.removeFile(id);
        }

        // File Pond events
        function onWarning(err, file, status) {
            //console.warn(err.main);
        }

        function onError(err, file, status) {
            // Show error here
            var errMessage = err.main ? err.main.toLowerCase() : '';
            if (errMessage === 'file is of invalid type') {
                // Invalid file type
                ToastFactory.error('UPLOAD.WRONG_FILE');
            } else if (errMessage === 'file is too large') {
                // File is too large
                ToastFactory.errorTranslatedReplace('UPLOAD.FILE_SIZE', '[SIZE]', ctrl.maxSize);
            } else {
                // Any other errors
                ToastFactory.error('UPLOAD.FAILED');
            }

            // Remove file afterwards
            ctrl.onRemove && ctrl.onRemove({id: file.id});
            ctrl.fpInstance.removeFile(file.id);
            file = null;
        }

        function onAddFileStart(file) {
            // The file was added to the queue for uploading
            // uploads start instant if possible
            file.progress = 0;
            // Trigger callback
            if (ctrl.onAdd) {
                ctrl.onAdd({
                    id: file.id,
                    file: {
                        name: file.file.name,
                        size: file.file.size,
                        type: file.file.type
                    }
                });
            }
            $scope.$apply();
        }

        function onAddFileProgress(file, progress) {
            // We don't handle this currently
        }

        function onProcessFileStart(file) {
            clientUploadStartTime = performance.now();
            // We've started uploading the file here
            // Send progress event
            if (ctrl.onProgress) {
                ctrl.onProgress({id: file.id, progress: 0});
                $scope.$apply();
            }
        }

        function onProcessFileProgress(file, progress) {
            // The file upload progress have changed
            var progressInt = parseInt(progress * 100);
            if (file.progress !== progressInt) {
                file.progress = progressInt;
                ctrl.onProgress && ctrl.onProgress({id: file.id, progress: progressInt});
                $scope.$apply();
            }
        }

        function onProcessFile(error, file) {
            // The file was done. It could both be an error or a success, we need to check the objects to be sure
            var serverResponse = file.serverId;

            if (error === null && serverResponse && serverResponse.Status === 1) {
                // It went well
                if (ctrl.onDone) {
                    // We clone the properties to ensure we don't reference the file object
                    var responseClone = _.cloneDeep(serverResponse.Files[0]);
                    ctrl.onDone({id: file.id, response: responseClone});
                }
                ctrl.fpInstance.removeFile(file.id);
                file = null;
            } else if (serverResponse) {
                // System error, we don't know how to validate these yet
                if (serverResponse.Status === 3) {
                    ToastFactory.error('UPLOAD.WRONG_FILE');
                } else if (serverResponse.ErrorCode) {
                    ToastFactory.error(serverResponse.ErrorCode);
                } else {
                    ToastFactory.error('UPLOAD.FAILED');
                }
                ctrl.onRemove && ctrl.onRemove({id: file.id});
                ctrl.fpInstance.removeFile(file.id);
                file = null;
            } else {
                // Remove file afterwards
                ctrl.onRemove && ctrl.onRemove({id: file.id});
                ctrl.fpInstance.removeFile(file.id);
                file = null;
            }

            $scope.$apply();
        }
    }
})();
