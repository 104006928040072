(() => {
    angular
        .module('App')
        .component('userManagementCreate', {
            template: require('./UserManagementCreate.html'),
            controllerAs: 'ctrl',
            bindings: {
                accountModuleToken: '<',
                userToken: '<',
                updateUsersOnClose: '<'
            },
            controller: ['$rootScope', '$scope', '$stateParams', '$element', 'Page',
                '$translate', '$timeout', '$http', 'events', 'ToastFactory', 'ProfileSettingsService', 'UserManagementService',  UserManagementCreatePopupController]
        })
    function  UserManagementCreatePopupController($rootScope, $scope, $stateParams, $element, Page, $translate, $timeout, $http,
                                                  events, ToastFactory, ProfileSettingsService, UserManagementService) {
        const ctrl = this;
        let userChangeWatcher, popup;

        ctrl.close = close;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onClose = onClose;
        ctrl.onOpen = onOpen;
        ctrl.createUser = createUser;
        ctrl.hideErrorMsg = hideErrorMsg;
        ctrl.clearToasts = clearToasts;
        ctrl.jobTitleChanged = jobTitleChanged;
        ctrl.isLoading = true;
        ctrl.reinitUserGroupSelector = false;
        ctrl.isProcessing = false;

        // ------- Open & Init -------
        function onPopupRegistered(popup) {
            popup.open();
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;
            UserManagementService.getSettings($stateParams.token).then((data) => {
                setUserSettings(data);
                ctrl.isLoading = false;
            });
        }

        function clearToasts() {
            ToastFactory.clear();
        }

        function setUserSettings(userSettings) {
            // Reset validation data
            ctrl.validationData = [];
            initUserChangeWatcher();

            // Hook up to for submit event
            const userSubmit = $rootScope.$on('GlobalFormSubmit', function () {
                ctrl.validationData = UserManagementService.validate(ctrl.userCreateModel, ctrl.settings);
                if (ctrl.validationData.length === 0) {
                    createUser();
                }
            });
            const userCancel = $rootScope.$on('GlobalFormCancel', function () {
                Page.stateGoPrevious('userManagement', $stateParams.token);
            });
            $scope.$on('$destroy', userSubmit);
            $scope.$on('$destroy', userCancel);

            // Create Model
            const country = userSettings.data.Country;
            ctrl.userCreateModel =  {
                AccountModuleId: userSettings.data.Settings.AccountModuleId,
                DepartmentId: userSettings.data.Settings.PredefineDepartmentOnCreationView ? userSettings.data.DepartmentId : null,
                Name: '',
                TitleObj: '',
                PhoneObj: {
                    number: '',
                    country: country.CountryId,
                },                
                PhoneCountryId:  country.CountryId,
                PhoneCode: country.PhoneCode,
                Email: '',
                Alias: '',
                UserGroups: userSettings.data.Settings.DefaultRoleList,
                SendLoginInfo: true,
                AdditionalDepartments: []
            };

            // handle custom user alias label (can be defined under UserModuleSettings)
            if (!(userSettings.data.UserAliasLabel)) {
                $translate('USER_MANAGEMENT.ALIAS').then(function (translation) {
                    userSettings.data.Settings.UserAliasLabel = translation;
                });
            } else {
                userSettings.data.Settings.UserAliasLabel = userSettings.data.UserAliasLabel;
            }

            // add "formatet" array with job titles
            userSettings.data.Settings.PredefinedJobTitleOptionsList = userSettings.data.PredefinedJobTitleOptionsList

            // Data
            ctrl.settings = userSettings.data.Settings;
            ctrl.additionalDepartmentsEnabled = userSettings.data.AdditionalDepartmentsEnabled;
            ctrl.departmentSettings = {
                departments: userSettings.data.AllowedDepartments
            };
            ctrl.allowedGroups = userSettings.data.AllowedUserGroups;
            ctrl.userGroupSettings = {
                usergroups: userSettings.data.AllowedUserGroups,
                locked: userSettings.data.Settings.DefaultRoleList,
                selected: userSettings.data.Settings.DefaultRoleList,
            };
            if (userSettings.data.Settings.PredefinedJobTitlesEnabled) {
                ctrl.titleOptions = getTitleOptions(userSettings.data.Settings);
            }

            ctrl.phoneCodes = [];
            ProfileSettingsService.getPhoneCodes().then(phoneCodes => {
                ctrl.phoneCodes = phoneCodes;           
            })

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
                Page.stateGoBack('userManagement', $stateParams.token);
            });
        }

        function initUserChangeWatcher() {
            let initializing = true;

            userChangeWatcher = $scope.$watch(() => ctrl.userCreateModel,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function getTitleOptions(settings) {
            return settings.PredefinedJobTitleOptionsList.map((
                (option, index) => {
                    return {
                        id: index,
                        name: option.JobTitle,
                        value: option.JobTitle,
                        selected: false
                    }
                }
            ))   
        }

        // ------- Job title changes -------
        function jobTitleChanged() {
            ctrl.hideErrorMsg('Title');
            ctrl.hideErrorMsg('UserGroups');
            if (!ctrl.settings.PredefinedJobTitlesEnabled) return;

            setRequiredForTitleUserGroups();
        }

        function setRequiredForTitleUserGroups() {
            $timeout(() => {
                const userTitle = ctrl.titleOptions.find(titleObj => titleObj.selected);
                ctrl.userCreateModel.UserGroups = ctrl.settings.PredefinedJobTitleOptionsList.find(option => option.JobTitle === userTitle.name)?.UserGroupIds || [];

                if (isLockedGroupsNotChanged(ctrl.userCreateModel.UserGroups, ctrl.userGroupSettings?.locked)) {
                    refreshUserGroupSelector();
                }

                ctrl.userGroupSettings = {
                    usergroups: ctrl.allowedGroups,
                    locked: ctrl.userCreateModel.UserGroups,
                    selected: ctrl.userCreateModel.UserGroups
                };
            })
        }

        function isLockedGroupsNotChanged(newLockedGroups, oldLockedGroups) {
            if (newLockedGroups?.length !== oldLockedGroups?.length) return false;
            if (newLockedGroups.length === 0) return true;
            return newLockedGroups.every((value, index) => value === oldLockedGroups[index]);
        }

        function refreshUserGroupSelector() {
            ctrl.reinitUserGroupSelector = !ctrl.reinitUserGroupSelector;
        }

        // ------- Save & Validate -------
        function createUser() {
            if (!ctrl.isSaving) {

                ctrl.isSaving = true;
                ctrl.isProcessing = true;
                Page.startLoading();
                formatUserModel();
                ctrl.validationData = [];

                $http.post('/UserManagement/CreateUser', ctrl.userCreateModel)
                .then(() => {
                    ToastFactory.success('USER_MANAGEMENT.USER_SAVED');
                    ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.userCreateModel);
                    ctrl.close();
                }).catch((response) => {
                    if (response.status === 400) {
                        ctrl.validationData = response.data;
                        ToastFactory.error('ERROR.FORM_VALIDATION');
                    } else {
                        ToastFactory.error('ERROR.GENERAL');
                    }
                }).finally(() => {
                    ctrl.isSaving = false;
                    Page.stopLoading();
                    ctrl.isProcessing = false;
                });
            }
        }

        function hideErrorMsg(propertyName) {
            ctrl.validationData = ctrl.validationData.filter(error => error.Name !== propertyName);
        }

        function formatUserModel() {
            formatPhoneNumber();
            formatTitle();

            function formatPhoneNumber() {
                ctrl.userCreateModel.PhoneCountryId = ctrl.userCreateModel.PhoneObj.country;
                ctrl.userCreateModel.Phone = ctrl.userCreateModel.PhoneObj.number;
            }
    
            function formatTitle() {
                if (!ctrl.settings.PredefinedJobTitlesEnabled) {
                    ctrl.userCreateModel.Title = ctrl.userCreateModel.TitleObj;
                    return;
                }
    
                ctrl.userCreateModel.Title = ctrl.userCreateModel.TitleObj?.value || '';
            }
        }

        // ------- Close -------
        function onClose() {
            $rootScope.disableVisibilitychange = false;
        }

        function close() {
            ctrl.onClose && ctrl.onClose();
            popup.remove();
            $element.remove();
            userChangeWatcher && userChangeWatcher();
        }
    }
})();