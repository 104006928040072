(() => {
    'use strict';

    angular
        .module('App')
        .component('reportPopup', {
            template: require('./ReportPopupComponent.html'),
            controller: ['$timeout', '$element', '$scope', 'ToastFactory', 'Page', ReportPopupController],
            controllerAs: 'ctrl',
            bindings: {
                onCloseReport: '<',
                onReportSubmit: '<',
                moduleType: '<',
                itemId: '<'
            }
        });

    function ReportPopupController($timeout, $element, $scope, ToastFactory, Page) {
        const ctrl = this;

        ctrl.reportCategories = [
            {
                title: 'REPORT.HATEFUL',
                helpText: 'REPORT.HATEFUL_HELP_TEXT',
                selected: false,
                value: 1
            },
            {
                title: 'REPORT.SEXUAL',
                helpText: 'REPORT.SEXUAL_HELP_TEXT',
                selected: false,
                value: 2
            },
            {
                title: 'REPORT.VIOLENT',
                helpText: 'REPORT.VIOLENT_HELP_TEXT',
                selected: false,
                value: 3
            },
            {
                title: 'REPORT.SELF_HARM',
                helpText: 'REPORT.SELF_HARM_HELP_TEXT',
                selected: false,
                value: 4
            },
            {
                title: 'REPORT.OTHER',
                helpText: 'REPORT.OTHER_HELP_TEXT',
                selected: false,
                value: 5
            },
        ];

        ctrl.showNext = false;
        ctrl.anonymousReport = false;
        ctrl.comment = '';
        ctrl.commentRequired = false;
        ctrl.disableAnonymity = ctrl.moduleType === 'messageItem';

        ctrl.submit = submit;
        ctrl.close = close;
        ctrl.nextScreen = nextScreen;
        ctrl.back = back;
        ctrl.selectReportCategory = selectReportCategory;
        ctrl.isSubmitDisabled = isSubmitDisabled;
        ctrl.$onInit = init;
        
        function init() {
            Page.setMainContainerInert(true);
        }

        function submit() {
            if (ctrl.disableSubmit) {
                return;
            }
            
            ctrl.disableSubmit = true;
            const model = {
                Category: ctrl.selectedCategory.value,
                Comment: ctrl.comment,
                IsAnonymous: ctrl.anonymousReport
            };
            ctrl.onReportSubmit(model, ctrl.itemId)
                .then(() => close(true))
                .catch(() => ToastFactory.error('ERROR.GENERAL'))
                .finally(() => ctrl.disableSubmit = false);
        }

        function isSubmitDisabled() {
            return ctrl.commentRequired && !ctrl.comment?.trim().length;
        }

        function selectReportCategory(reportCategory) {
            ctrl.selectedCategory = reportCategory;
            ctrl.reportCategories = ctrl.reportCategories.map(category => ({
                ...category,
                selected: category.value === reportCategory.value
            }));
            ctrl.commentRequired = ctrl.disableAnonymity || ctrl.moduleType === 'userProfile' || ctrl.selectedCategory?.value === 5;
            ctrl.nextScreen();
        }

        function nextScreen() {
            if (!ctrl.selectedCategory) return;

            ctrl.showNext = true;
        }

        function back() {
            ctrl.showNext = false;
        }

        function close(submitted) {
            Page.setMainContainerInert(false);
            $scope.$destroy();
            $element.remove();
            ctrl.onCloseReport(submitted);
        }
    }
})();