(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('appLink', ['$http', 'Page', 'ToastFactory', 'ResponsiveService',
        function ($http, Page, ToastFactory, ResponsiveService) {
            return {
                restrict: 'A',
                link: function ($scope, $element) {
                    $element.on('click', function ($event) {
                        const href = $event.target.href || $event.currentTarget.href;

                        if (href.indexOf('/link/') !== -1 && (!ResponsiveService.isDesktop() || $event.currentTarget.getAttribute('target') !== '_blank')) {
                            $event.preventDefault();
                            $event.stopPropagation();

                            $http.get(href + '/details').then(resp => {
                                if (resp.data.PathState) {
                                    Page.stateGoLink(resp.data, true);
                                } else {
                                    ToastFactory.error('ERROR.PAGE_NOT_LOADED');
                                }
                            }).catch(() => {
                                ToastFactory.error('ERROR.PAGE_NOT_LOADED');
                            });
                        }
                    });
                }
            }
        }
    ]);
})();