(() => {
    'use strict';

    angular
        .module('App')
        .component('enterPinCode', {
            template: require('./EnterPinCodeComponent.html'),
            controller: ['$filter', '$timeout', '$scope', '$window', 'PinCodeService', 'ToastFactory', 'Profile', '$q', '$rootScope', 'events',
                EnterPinCodeController],
            controllerAs: 'ctrl',
            bindings: {
                profile: '<',
                allowBiometric: '<',
                onSecure: '&',
                onBiometricAvailable: '&'
            }
        });

    function EnterPinCodeController($filter, $timeout, $scope, $window, PinCodeService, ToastFactory, Profile, $q, $rootScope, events) {
        var ctrl = this;
        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        ctrl.pinTitle = 'PIN.ENTER_PIN';
        ctrl.showKeyboard = true;
        ctrl.showButtons = false;
        ctrl.isSubmitting = false;

        ctrl.submitCode = submitCode;
        ctrl.tryAgain = tryAgain;
        ctrl.forgotPin = forgotPin;
        ctrl.onBiometricClick = onBiometricClick;
        
        ctrl.biometricIsStored = null;
        ctrl.showBiometricButton = false;

        function init() {
            // Listen for keyboard events
            document.addEventListener('keydown', pinKeyboardEvent, true);

            if(ctrl.allowBiometric || ctrl.onBiometricAvailable){
                const userToken = ctrl.profile && ctrl.profile.UserToken || null;

                PinCodeService.getBiometricPinStatus(userToken).then(res => {
                    ctrl.biometricIsStored = res.isStored;
                    ctrl.showBiometricButton = ctrl.biometricIsStored && ctrl.allowBiometric

                    // Prompt for biometric automatically - Wait a short while to let any transitions finish first
                    if(ctrl.showBiometricButton){
                        $timeout(onBiometricClick, 250);
                    }
                }, () => {
                    ctrl.biometricStatus = null;
                    ctrl.showBiometricButton = false;
                });

            }
        }

        function destroy() {
            // Remove keyboard listener
            document.removeEventListener('keydown', pinKeyboardEvent, true);
        }

        function pinKeyboardEvent(ev) {
            // Only register enter events buttons are visible
            if (ctrl.showButtons) {
                var key = ev.key || ev.keyCode;
                // If key entered is 'enter'
                if ((_.isNumber(key) && key === 13) || key.toString().toLowerCase() === 'enter') {
                    tryAgain();
                    $scope.$apply();
                }
            }
        }

        function submitCode(pin, isBiometricSubmit) {
            var biometricPromise;

            if(isBiometricSubmit){
                biometricPromise = $q.defer();
            }

            if(ctrl.isSubmitting){
                return;
            }

            ctrl.isSubmitting = true;
            ctrl.showKeyboard = false;
            ctrl.pinTitle = $filter('translate')('PIN.SUBMITTING');

            const pinCodeSanitized = pin.join('');

            PinCodeService.verifyPinCode(pinCodeSanitized).then((res) => {
                if (res.response.data.success) {
                    ctrl.pinTitle = 'PIN.WELCOME_BACK';
                    ctrl.showKeyboard = false;
                    ctrl.isPinValid = true;

                    var resAction = null;

                    PinCodeService.loadDataAfterPin().finally(() => {
                        // Set the result action
                        if (res.redirect) {
                            resAction = function () {
                                $rootScope.$broadcast(events.PINCODE_DIDHIDE);
                                $window.location.replace(res.redirect);
                            };
                        } else {
                            resAction = function () {
                                $rootScope.$broadcast(events.PINCODE_DIDHIDE);
                                PinCodeService.goToState();
                            }; 
                        }

                        const userToken = res.response.data.userToken;
                        if (ctrl.biometricIsStored === false && 
                            isBiometricSubmit !== true && 
                            ctrl.onBiometricAvailable && 
                            PinCodeService.mayAskForSavingBiometric(userToken)) {

                            // Biometric can be set up so we should ask
                            ctrl.onBiometricAvailable({
                                userToken: userToken,
                                pinCode: pinCodeSanitized,
                                nextAction: resAction
                            });

                        } else if (res.response.data.isSecure) {
                            // Device is secure, show that message first
                            ctrl.onSecure({secureAction: resAction});
                        } else {
                            // Run result now
                            $timeout(resAction);
                        }
                    });

                    
                } else {
                    const remainingAttempts = res.response.data.remainingAttempts || 0;

                    ctrl.pinTitle = $filter('translate')('PIN.WRONG_CODE', { NUMBER: remainingAttempts });
                    console.log(remainingAttempts);
                    ctrl.isPinInvalid = true;
                    ctrl.showKeyboard = false;

                    if(isBiometricSubmit){
                        biometricPromise.reject(ctrl.profile.UserToken);
                    }

                    if(remainingAttempts !== 0){
                        ctrl.showButtons = true;
                        ctrl.isSubmitting = false;
                    }else{
                        Profile.clearCache();
                        $timeout(function(){ 
                            $window.location.replace(res.redirect);
                        }, 500);
                    }
                }
            },  () => {
                ctrl.pinTitle = $filter('translate')('ERROR.GENERAL');
                ctrl.isPinInvalid = true;
                ctrl.showKeyboard = false;
                ctrl.showButtons = true;
                ctrl.isSubmitting = false;
            });

            if(isBiometricSubmit){
                return biometricPromise.promise;
            }
        }

        function tryAgain() {
            ctrl.pinCodeModel = [null, null, null, null];
            ctrl.showButtons = false;
            ctrl.isPinInvalid = false;
            ctrl.showKeyboard = true;
            ctrl.pinTitle = 'PIN.ENTER_PIN';
        }

        function forgotPin() {
            PinCodeService.forgotPin();
        }

        function onBiometricClick(){
            if(ctrl.showBiometricButton){
                const userToken = ctrl.profile && ctrl.profile.UserToken || null;

                PinCodeService.getBiometricPin(userToken).then(res => {
                    submitBiometricPin(userToken, res.pinCode);
                }, (err) => {
                    if (err.isCancelEvent) {
                        return;
                    }
                    
                    if (err.errorCode) {
                        ToastFactory.error(res.errorCode);
                    } else if (err.errorMessage) {
                        ToastFactory.error(err.errorMessage);
                    } else {
                        ToastFactory.error('ERROR.GENERAL');
                    }
                });
            }
        }

        function sanitizeBiometricPin(pinCode){
            if(pinCode.length > 4){
                pinCode = pinCode.substring(0, 4);
            }
            
            let newModel = pinCode.split('');
            
            if(newModel.length < 4){
                for(var i = 0; i = 4 - newModel.length; i++){
                    newModel.push(null);
                }
            }
            return newModel;
        }

        function submitBiometricPin(userToken, pinCode){
            var newModel = sanitizeBiometricPin(pinCode);

            if(newModel.join('').length === 4){
                ctrl.pinCodeModel = newModel;

                var submitPromise = submitCode(ctrl.pinCodeModel, true)
                if(submitPromise){
                    // Handle a bad code submit and remove the stored biometric
                    submitPromise.catch(() => {
                        ToastFactory.warning('PIN.BIOMETRIC.ERROR.PIN_INVALID');
                        
                        PinCodeService.clearBiometricPin(userToken, false);

                        ctrl.biometricIsStored = false;
                        ctrl.showBiometricButton = false;
                    });
                }
            }
        }
    }
})();


