(function () {
    'use strict';

    angular.module('App')
        .component('walkthroughSlidePolicy', {
            templateUrl: '/Scripts/Directives/Walkthrough/Templates/Walkthrough-Slide-Policy.tpl.html',
            controller: ['PolicyViewService', WalkthroughSlidePolicyController],
            controllerAs: 'ctrl',
            bindings: {
                slide: '=',
                policiesCount: '<',
                policyIndex: '<',
                parent: '=',
                done: '&'
            }
        });
                
    function WalkthroughSlidePolicyController(PolicyViewService) {

        const ctrl = this;
        ctrl.$onInit = init;
        ctrl.policy = null;
        ctrl.approve = approve;

        function init() {
            ctrl.policy = ctrl.slide.SlideData.Policy;
            ctrl.policyNumber = ctrl.policyIndex + 1;
        }

        function approve() {
            if (!ctrl.slide.Loading) {
                ctrl.slide.Loading = true;

                PolicyViewService.agreeWithPolicy(ctrl.policy.PolicyToken).then(response => {
                    if (response.status === 200) {
                        done();
                    } else {
                        ctrl.policy = response.data;
                        ToastFactory.error('WALKTHROUGH.TERMS.WRONG_VERSION');
                    }
                }).finally(() => {
                    ctrl.slide.Loading = false;
                })
            }
        }

        function done() {
            ctrl.parent.removeClass('swiper-no-swiping');
            ctrl.done();
        }
    }
})();