(() => {
    'use strict';

    angular
        .module('App')
        .factory('LandingPageService', ['$rootScope', '$timeout', 'Page', 'ResponsiveService', LandingPageService]);

    function LandingPageService($rootScope, $timeout, Page, ResponsiveService) {
        return {
            getLandingPageBackground: getLandingPageBackground,
            createCustomClassString: createCustomClassString,
            hasBackground: hasBackground,
            scrollTo: scrollTo,
            getTileForDevice: getTileForDevice,
            getScrollPosition: getScrollPosition,
            onScroll: onScroll,
            onOrientationchange: onOrientationchange,
            sortTiles: sortTiles,
            getTileBackground: getTileBackground
        }

        function getLandingPageBackground(landingPage) {
            let background;
            if (ResponsiveService.isMobile() && landingPage.BackgroundMediaId) {
                background = landingPage.BackgroundMediaId.ImageFormats['W' + $rootScope.imageWidth]
            }

            if (!ResponsiveService.isMobile() && landingPage.DesktopBackgroundMediaId) {
                background = landingPage.DesktopBackgroundMediaId.ImageFormats['W' + $rootScope.imageWidth]
            }

            return {
                backgroundImage: 'url(' + background + ')',
                backgroundSize: 'cover'
            };
        }

        function createCustomClassString(arr, prefix) {
            return arr
                .map(item => prefix + '-' + item)
                .join(' ');
        }

        function scrollTo() {
            if (Page.getDirection() === 'backward') {
                let backState = Page.getBackHistoryState();
                const scrollPosition = backState?.scrollPosition;
                if (scrollPosition) {
                    $timeout(function () {
                        angular.element('html, body').animate({scrollTop: scrollPosition}, 50);
                        backState.scrollPosition = null;
                    });
                } else {
                    $timeout(function () {
                        angular.element('html, body').animate({scrollTop: 0}, 50);
                    });
                }
            } else if (getScrollPosition() !== 0) {
                $timeout(function () {
                    angular.element('html, body').animate({scrollTop: 0}, 50);
                });
            }
        }

        function hasBackground(page) {
            return ResponsiveService.isMobile() && page.BackgroundMediaId?.MediaId > 0 ||
                !ResponsiveService.isMobile() && page.DesktopBackgroundMediaId?.MediaId > 0
        }

        function getTileForDevice(tile) {
            const isTabletTilesEnabled = Page.getSettings().EnableTileTabletCoordinates;

            switch (ResponsiveService.getState()) {
                case 'desktop':
                    return tile.DeviceSizePercentages.Desktop
                case 'bigTablet.landscape':
                    return getCurrentOrPrevious(tile.DeviceSizePercentages.LargeTabletLandscape, tile.DeviceSizePercentages.Desktop)
                case 'bigTablet.portrait':
                    return getCurrentOrPrevious(tile.DeviceSizePercentages.LargeTabletPortrait, tile.DeviceSizePercentages.Mobile)
                case 'smallTablet.landscape':
                    return getCurrentOrPrevious(tile.DeviceSizePercentages.TabletLandscape, tile.DeviceSizePercentages.Mobile)
                case 'smallTablet.portrait':
                    return getCurrentOrPrevious(tile.DeviceSizePercentages.TabletPortrait, tile.DeviceSizePercentages.Mobile)
                case 'mobile':
                    return tile.DeviceSizePercentages.Mobile
            }

            function getCurrentOrPrevious(current, prev) {
                if (isTabletTilesEnabled) {
                    return current
                }
                return current || prev
            }
        }

        function getScrollPosition() {
            return document.querySelector('html').scrollTop;
        }

        function onScroll() {
            if (getScrollPosition() > 0) {
                document.querySelector('.scrollable-arrow').classList.add('hide')
            } else {
                document.querySelector('.scrollable-arrow').classList.remove('hide')
            }
        }

        function onOrientationchange() {
            document.querySelector('.base-container').classList.add('loading');
            $timeout(() => {
                document.querySelector('.base-container').classList.remove('loading');
            }, 500);
        }

        function sortTiles(first, second) {
            const yDiff =getTileForDevice(first)['PosY'] - getTileForDevice(second)['PosY'],
                xDiff = getTileForDevice(first)['PosX'] - getTileForDevice(second)['PosX'];
            return yDiff !== 0 ? yDiff : xDiff;
        }

        function getTileBackground(tile) {
            const width = document.querySelector('body').clientWidth,
                widthFraction = getTileForDevice(tile).Width / 100,
                size = (width * widthFraction) <= 100 ? 250 : ((width * widthFraction) < 600 ? 600 : 1500);

            return 'url(' + tile.BackgroundMediaId.ImageFormats['W' + size] + ')';
        }
    }
})();