(() => {
    'use strict';

    angular
        .module('App')
        .component('moduleSearchFilters', {
            template: require('./ModuleSearchFiltesComponent.html'),
            controllerAs: 'ctrl',
            controller: [ModuleSearchFiltersController],
            bindings: {
                onSearch: '<',
                searchString: '=?',
                searchPlaceholder: '@',
                searchFocused: '<',
                noSearchResults: '<'
            },
            transclude: true,
        })

    function ModuleSearchFiltersController() {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.$postLink = postLink;

        function init() {
            if (ctrl.searchFocused) {
                document.getElementById("search-input").focus();
            }
        }

        function postLink() {
            ctrl.isTranscludePresent = isTranscludePresent();
        }

        function isTranscludePresent() {
            const filtersEl = document.querySelector(".module-search-filters");
            return !!filtersEl?.childElementCount;
        }
    }
})();