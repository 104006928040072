(function () {
    'use strict';

    angular.module('App')
        .component('conversation', {
            template: require('./ConversationComponent.tpl.html'),
            controllerAs: 'ctrl',
            bindings: {
                channelId: '<',
                channel: '<',
                messageId: '<',
                closeChannel: '<'
            },
            controller: ['$window', '$rootScope', '$scope', '$element', '$timeout', '$interval', '$filter', 'ChatDataService',
                'Profile', 'ChatPopupsService', 'ChatConnectionService', 'ToastFactory', 'ProfileViewerService', 'Page',
                'ConversationService', 'PopupWrapperService', 'ResponsiveService', 'MentionService', 'ContentSafetyWarningService',
                'MESSAGE_STATUS', ConversationController]
        });

    function ConversationController($window, $rootScope, $scope, $element, $timeout, $interval, $filter, ChatDataService,
        Profile, ChatPopupsService, ChatConnectionService, ToastFactory,
        ProfileViewerService, Page, ConversationService, PopupWrapperService,
        ResponsiveService, MentionService, ContentSafetyWarningService, MESSAGE_STATUS) {

        let popup, scrollContainer, lastScrollPosition, getChannelPromise, loadPreviousMessages,
            loadNextMessages, loadAfterMessageId, isTyping = false, userTypingTimeout, lastToMessageScroll;

        const ctrl = this, markAsReadDebounced = _.debounce(markAsRead, 1000);
        const removePushOpenWatcher = $rootScope.$on('CHAT.OPEN_FROM_PUSH', () => close()),
            destroyBlockedUsersUpdateWatcher = $rootScope.$on('CHAT.BLOCKED_USERS_UPDATE', (ev, blockedUserId, block) => {
                $scope.$applyAsync(() => {
                    if (block) {
                        ctrl.channel.blockedUsers.push(blockedUserId);
                    } else {
                        ctrl.channel.blockedUsers = ctrl.channel.blockedUsers.filter(id => blockedUserId !== id);
                    }
                    ctrl.messages = ctrl.messages.map((message) =>
                        ({ ...message, isBlocked: ctrl.channel.blockedUsers.includes(message.item.authorId) }))
                })
            }),
        destroyChannelUpdatedWatcher = $rootScope.$on('CHAT.CHANNEL_UPDATED', (ev, channel) => {
            if (ctrl.channel.item.isGroupChat && channel.item.channelId === ctrl.channel.item.channelId) {
                ctrl.channel.profileImage = channel.profileImage;
                ctrl.channel.profileMediaModel = channel.profileMediaModel;
                ctrl.channel.item.name = channel.item.name;
            }
        });

        let typingTimeouts = {}; // Object to store timeouts for each user

        ctrl.isDifferentDays = ConversationService.isDifferentDays;
        ctrl.isFirstMessageInRow = ConversationService.isFirstMessageInRow;
        ctrl.mentions = [];
        ctrl.inMemoryMessages = [];

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.onOpenPopup = onOpenPopup;
        ctrl.onPopupRendered = onPopupRendered;
        ctrl.close = close;
        ctrl.showChatInfo = showChatInfo;
        ctrl.sendMessage = sendMessage;
        ctrl.onKeydown = onKeydown;
        ctrl.onBlur = onBlur;
        ctrl.openAddUsers = openAddUsers;
        ctrl.onMediaUpdated = onMediaUpdated;
        ctrl.removeMedia = removeMedia;
        ctrl.openChannelInfo = openChannelInfo;
        ctrl.goToReplyingMessage = goToReplyingMessage;
        ctrl.cancelEditing = cancelEdit;
        ctrl.openRepliedContent = openRepliedContent;
        ctrl.clearReplyingMessage = clearReplyingMessage;
        ctrl.scrollButtonClick = scrollButtonClick;
        ctrl.replyToMessage = replyToMessage;
        ctrl.editMessage = editMessage;
        ctrl.getMember = getMember;
        ctrl.onOpenOptions = onOpenOptions;
        ctrl.isUserNotBlocked = isUserNotBlocked;
        ctrl.parseMentions = parseMentions;
        ctrl.onRenderCompleted = onRenderCompleted;
        ctrl.getNonBlockedUser = getNonBlockedUser;
        ctrl.resetPosition = resetPosition;
        ctrl.showMessageStatus = showMessageStatus;
        ctrl.openMembersPopup = openMembersPopup;
        ctrl.onResend = onResend;
        ctrl.openProfile = openProfile;
        ctrl.hideLeaveMessage = hideLeaveMessage;
        ctrl.expandCollapseMessage = expandCollapseMessage;
        ctrl.showAwayIcon = showAwayIcon;

        ctrl.shimmer = {
            containerCount: 7,
            minWidth: 40,
            maxWidth: 100,
            lineCount: 1
        }

        function init() {
            const conversation = $element.find('.popup-content')[0];
            ctrl.messages = [];
            ctrl.users = [];
            ctrl.channel = ctrl.channel || {};
            ctrl.messageViews = {};
            ctrl.uploadedMedia = [];
            ctrl.messageReadStats = [];
            ctrl.unsafeMessageClientId = null;
            ctrl.isDesktop = ResponsiveService.isDesktop();

            ctrl.currentUserToken = Profile.getProfile().UserToken;


            if (!ctrl.isDesktop) {
                setMobileEvents(conversation);
            } else {
                setDesktopEvents(conversation);
            }

            if (ctrl.channel && ctrl.channel.length) {
                ctrl.currentChannelCompanion = ChatDataService.getChannelCompanion(ctrl.channel);
                ctrl.hideAuthorForCurrentUser = ctrl.channel.item.hideAuthor
                    && ctrl.channel.item.creatorUserId === ctrl.currentUserToken;
            }

            if (ctrl.channel.item) {
                usersForMention();
            }

            $element.on('click', '.post-tag', showMentionProfile);

            // Reconnect if needed
            ChatConnectionService && !ChatConnectionService.isConnected() && ChatConnectionService.reconnect();
        }

        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
        }

        function getNonBlockedUser() {
            const nonBlockedUsers = ctrl.channel.members.filter((user) => {
                return !ctrl.channel.blockedUsers.includes(user.userId)
            });

            if (nonBlockedUsers.length === 1) return nonBlockedUsers[0];

            return nonBlockedUsers.filter((user) => user.userId !== ctrl.currentUserToken)[0];
        }

        function setMobileEvents(conversation) {
            ctrl.btnHammer = new Hammer(conversation);
            ctrl.btnHammer.get('press').set({ time: 300 });
            ctrl.btnHammer.on('press', showMobileMenu);
            ctrl.btnHammer.on('tap', hideMobileMenu)

            function showMobileMenu(event) {
                const messageTarget = event.target.closest('.message-body-wrapper');
                if (messageTarget) {
                    event.preventDefault();

                    $scope.$apply(() => {
                        ctrl.messages = ctrl.messages.map(message => ({
                            ...message,
                            isMenuPressed: message.item.messageId === event.target.closest('.message-wrapper').getAttribute('data-index')
                        }))
                    })

                    animateMenu(messageTarget);
                }
            }

            function animateMenu(messageTarget) {
                const messageContainer = messageTarget.closest('.message');
                const reactionsListContainer = messageContainer.querySelector('.reactions-list');
                const messageActionsheetContainer = messageTarget.closest('.message-actionsheet-wrapper');
                const rect = messageActionsheetContainer.getBoundingClientRect();

                const popupHeaderContainer = messageTarget.closest('.messages-list-wrapper').previousElementSibling;
                const popupHeaderContainerHeight = popupHeaderContainer.getBoundingClientRect().height;

                const messageReplyingContainer = messageContainer.querySelector('.replying-message');
                const replyingMessageHeight = messageReplyingContainer ? messageReplyingContainer.getBoundingClientRect().height : 0;

                const reactionsListHeight = reactionsListContainer ? reactionsListContainer.getBoundingClientRect().height : 55;
                const gap = 45;

                const requiredBottomSpace = gap;
                const requiredTopSpace = gap + reactionsListHeight + popupHeaderContainerHeight + replyingMessageHeight;

                const viewportHeight = window.innerHeight;
                const distanceToBottom = viewportHeight - rect.bottom;
                const distanceToTop = rect.top;

                if (isMessageTooLong(rect.height, viewportHeight, reactionsListHeight + replyingMessageHeight, gap)) {
                    const moveDist = window.innerHeight - rect.bottom - requiredBottomSpace + popupHeaderContainerHeight;
                    hideHeaderPopup(messageTarget);
                    animateMessage(messageContainer, moveDist, 'bottom');
                    return;
                }

                if (distanceToBottom < requiredBottomSpace) {
                    const moveDist = requiredBottomSpace - distanceToBottom;
                    animateMessage(messageContainer, -moveDist, 'up');
                }

                if (distanceToTop < requiredTopSpace) {
                    const moveDist = requiredTopSpace - distanceToTop;
                    hideHeaderPopup(messageTarget);
                    animateMessage(messageContainer, moveDist, 'down');
                }
            }

            function isMessageTooLong(messageHeight, viewportHeight, reactionsListHeight, gap) {
                const requiredGap = gap * 2;
                const minScreenHeight = messageHeight + reactionsListHeight + requiredGap;
                return viewportHeight < minScreenHeight;
            }

            function animateMessage(container, moveDistance, direction) {
                if (direction === 'bottom') {
                    container.classList.add('reactions-overlay');
                }
                container.style.setProperty('--move-dist', moveDistance + 'px');
            }

            function hideHeaderPopup(messageTarget) {
                const headerPopup = messageTarget.closest('.messages-list-wrapper').previousElementSibling;
                headerPopup.style.display = 'none';
            }

            function hideMobileMenu(event) {
                if (event.target.closest('.message-form')) {
                    $scope.$apply(() => {
                        resetPosition();
                        ctrl.messages = ctrl.messages.map(message => ({
                            ...message,
                            isMenuPressed: false
                        }))
                    })
                }
            }
        }

        function setDesktopEvents(conversation) {
            let lastHoveredMessage;
            const throttledMove = _.throttle((moveEvent) => {
                const
                    hoveredElement = moveEvent.target.closest('.message-body-wrapper'),
                    message = moveEvent.target.closest('.message'),
                    desktopMenu = moveEvent.target.closest('desktop-message-menu');

                if (hoveredElement && !message.classList.contains('hover')) {
                    lastHoveredMessage && lastHoveredMessage.classList.remove('hover');
                    lastHoveredMessage = message;
                    lastHoveredMessage.classList.add('hover');
                } else if (!hoveredElement && !desktopMenu && lastHoveredMessage && !lastHoveredMessage.classList.contains('highlighted')) {
                    lastHoveredMessage.classList.remove('hover');
                    lastHoveredMessage = undefined;
                }
            }, 200);
            conversation.addEventListener('mousemove', throttledMove);
        }

        function resetPosition() {
            const highlightedMessage = document.querySelector('.message.highlighted');
            if (highlightedMessage) {
                const headerPopup = highlightedMessage.closest('.messages-list-wrapper').previousElementSibling;
                if (headerPopup) {
                    headerPopup.style.display = 'flex';
                }
            }
        }

        function showMentionProfile(ev) {
            ev.stopPropagation();
            var userToken = $(ev.currentTarget).find(".post-tag-user-token");
            if (userToken && userToken[0]) {
                ProfileViewerService.showProfileCard(userToken[0].innerHTML);
            }
        }

        function onPopupRegistered(popupCtrl) {
            popup = popupCtrl;
            popup.open();
        }

        function onOpenPopup() {
            getChannelPromise = ChatDataService.getChannel(ctrl.channelId)
                .then(channels => {
                    ctrl.channel = Object.assign(ctrl.channel, channels);
                    ctrl.channelLoaded = true;

                    if (ctrl.channel.item) {
                        usersForMention();
                    }

                    ctrl.currentChannelCompanion = ctrl.currentChannelCompanion
                        || ChatDataService.getChannelCompanion(ctrl.channel);
                    ctrl.hideAuthorForCurrentUser = ctrl.channel.item.creatorUserId === ctrl.currentUserToken
                        && ctrl.channel.item.hideAuthor;

                    initEvents();
                    markAsRead();

                    $rootScope.$broadcast('CHAT.CHANNEL_MARKED_AS_READ', ctrl.channelId);

                    ctrl.membersAway = ctrl.channel.members
                        .filter(member => member.away && member.userId !== ctrl.currentUserToken)
                        .map(member => member.name.split(' ')[0]);

                    if (ctrl.channel.activeLeave) {
                        ctrl.channel.activeLeave.TranslatableEntity._currentLocale = selectCurrentLocale(ctrl.channel.activeLeave.TranslatableEntity);
                    }

                    if (ctrl.channel.activeLeave || ctrl.membersAway.length) {
                        checkOverflow();
                    }

                    return channels
                });
        }

        function hideLeaveMessage() {
            ctrl.hideLeave = true;
        }

        function openProfile() {
            ProfileViewerService.showProfileCard(ctrl.currentChannelCompanion.userId);
        }

        function usersForMention() {
            ctrl.members = ctrl.channel.members?.filter(member => !(ctrl.channel.blockedUsers ?? []).includes(member.userId));
        }

        function parseMentions(message, members) {
            return message && MentionService.parseMentionToText(message, members);
        }

        function initEvents() {
            if (ctrl.channel.item.isGroupChat) {
                ChatConnectionService.onChannelItemEvents(ctrl.channel.item.channelId, channelEventsCallback)
            }

            ConversationService.onNewMessage(ctrl.channel.item.channelId, onNewMessage);
            ConversationService.onReactionUpdate(ctrl.channel.item.channelId, onReactionUpdate);
            ConversationService.onUserStartedTyping(ctrl.channel.item.channelId, onUserStartedTyping);
            ConversationService.onUserStoppedTyping(ctrl.channel.item.channelId, onUserStoppedTyping);
            ConversationService.onUpdateMessage(onMessageUpdate);
            ConversationService.onAllChannelsMarkedAsRead(onChannelMarkAsRead);
            ConversationService.onChannelMarkedAsRead(ctrl.channel.item.channelId, onChannelMarkAsRead);
        }

        function onPopupRendered() {
            ctrl.initialLoad = true;
            getChannelMessages().then(() => {
                $('.message-form button').on('touchend', forceSubmit);
                $window.addEventListener('resize', onResize);

                if (window.visualViewport) {
                    window.visualViewport.addEventListener("resize", onResize)
                }

                getChannelPromise.then(channel => {
                    scrollContainer = $element.find('.messages-list-wrapper')[0];
                    lastScrollPosition = scrollContainer ? scrollContainer.scrollTop : 0;
                    generateMessageReads(channel)

                    if (ctrl.messageId) {
                        goFromPushToMessage(ctrl.messageId);
                    } else {
                        scrollToBottom(() => {
                            ctrl.initialLoad = false;
                            scrollToBottom();
                            addScrollListeners();
                        });
                    }
                })
            })
        }

        function addScrollListeners() {
            $timeout(() => {
                scrollContainer.addEventListener('scroll', handleScroll);
                scrollContainer.addEventListener('scroll', handleScrollButton);
            }, 200)
        }

        function handleScroll() {
            if (ctrl.fetchingMessages) {
                return
            }

            if (isScrollTop()) {
                if (scrollContainer.scrollTop <= 0 && loadPreviousMessages) {
                    const sh = scrollContainer.scrollHeight;
                    ctrl.fetchingMessages = true;
                    getChannelMessages({ beforeMessageId: ctrl.messages[0].item.messageId })
                        .then(({ messages }) => {
                            if (messages.length) {
                                $timeout(() => {
                                    scrollContainer.scrollTop = scrollContainer.scrollHeight - sh;
                                    ctrl.fetchingMessages = false;
                                })
                            }
                        });
                }
            } else {
                if (loadNextMessages && (scrollContainer.scrollTop + scrollContainer.offsetHeight) >= fromLoadPosition()) {
                    ctrl.fetchingMessages = true;
                    getChannelMessages({ afterMessageId: loadAfterMessageId })
                        .then(_ => ctrl.fetchingMessages = false);
                }
            }

            function fromLoadPosition() {
                return $element.find('.load-messages-after')[0].offsetTop
            }
        }

        function handleScrollButton() {
            $scope.$apply(() => {
                ctrl.showScrollButton = scrollContainer.scrollTop + scrollContainer.offsetHeight <= scrollContainer.scrollHeight - 10;
            })
        }

        function goToReplyingMessage(message, event) {
            event.stopPropagation();

            if (message.isReplyMessageBlocked) {
                return;

            }

            const messageEl = getMessageElement();
            if (messageEl.length) {
                scrollToMessage(messageEl, true)
            } else {
                scrollContainer.removeEventListener('scroll', handleScroll);
                ctrl.fetchingMessages = true;
                getChannelMessages({ aroundMessageId: message.replyToMessage.messageId }, true)
                    .then(() => {
                        const interval = $interval(() => {
                            const message = getMessageElement();
                            if (message.length) {
                                scrollToMessage(message, true);
                                $interval.cancel(interval);
                                ctrl.fetchingMessages = false;
                                $timeout(() => {
                                    scrollContainer.addEventListener('scroll', handleScroll);
                                }, 20)
                            }
                        }, 100)
                    });
            }
            lastToMessageScroll = $element.find('.message-wrapper[data-index=' + message.item.messageId + ']');

            function getMessageElement() {
                return $element.find('.message-wrapper[data-index=' + message.replyToMessage.messageId + ']');
            }
        }

        function goFromPushToMessage(messageId) {
            const deregisterWatch = $scope.$watch('ctrl.messages', () => {
                const domRendered = $element.find('.message-wrapper');

                if (!domRendered.length) return;
                deregisterWatch();

                const messageEl = getMessageElement();
                if (messageEl.length) {
                    scrollToMessage(messageEl, true);
                    addScrollListeners();
                } else {
                    loadAndScrollToMessage(messageId);
                }
            });

            function loadAndScrollToMessage(messageId) {
                if (!scrollContainer) return;

                removeScrollListener();
                getChannelMessages({ aroundMessageId: messageId }, true)
                    .then(() => {
                        const interval = $interval(() => {
                            const message = getMessageElement();
                            if (message.length) {
                                scrollToMessage(message, true);
                                $interval.cancel(interval);
                                addScrollListeners();
                            }
                        }, 100)
                    });
            }

            function getMessageElement() {
                return $element.find('.message-wrapper[data-index=' + messageId + ']');
            }

            function removeScrollListener() {
                scrollContainer.removeEventListener('scroll', handleScroll);
            }
        }

        function scrollButtonClick() {
            scrollContainer.removeEventListener('scroll', handleScroll);
            if (lastToMessageScroll) {
                scrollToMessage(lastToMessageScroll)
                lastToMessageScroll = null;
            } else {
                scrollToBottom(() => {
                    $timeout(() => {
                        scrollContainer.addEventListener('scroll', handleScroll);
                    }, 20)
                });
            }
        }

        function getChannelMessages(options = {}, loadReplyMessage) {
            let haveLoadedMessage;

            return ChatDataService.getChannelMessages(ctrl.channelId, options)
                .then(({ messages, users }) => {
                    haveLoadedMessage = messages.some(newMessage => ctrl.messages.find(message => message.item.messageId === newMessage.item.messageId))
                    loadPreviousMessages = messages.length === (options.limit || 50);

                    if (options.afterMessageId) {
                        const index = ctrl.messages.findIndex(message => message.item.messageId === options.afterMessageId);
                        ctrl.messages.splice(index, 0, ...addMessages(messages));
                    } else {
                        ctrl.messages = [...addMessages(messages), ...ctrl.messages];
                    }
                    ctrl.users = [...addUsers(users), ...ctrl.users];

                    if (loadReplyMessage || options.afterMessageId) {
                        const lastMessageIndex = ctrl.messages.findIndex(message => message.loadNextMessages);
                        if (lastMessageIndex !== -1) {
                            delete ctrl.messages[lastMessageIndex].loadNextMessages;
                        }
                        if (!haveLoadedMessage) {
                            messages[messages.length - 1].loadNextMessages = true;
                            loadAfterMessageId = messages[messages.length - 1].item.messageId;
                        }
                        loadNextMessages = !haveLoadedMessage
                    }

                    return {
                        messages
                    }
                })

            function addUsers(users) {
                return users.filter(newUser => !ctrl.users.find(user => user.userId === newUser.userId))
            }

            function addMessages(messages) {
                return messages.filter(newMessage => !ctrl.messages.find(message => message.item.messageId === newMessage.item.messageId))
            }
        }

        function isScrollTop() {
            if (lastScrollPosition > scrollContainer.scrollTop) {
                lastScrollPosition = scrollContainer.scrollTop;
                return true
            } else {
                lastScrollPosition = scrollContainer.scrollTop;
                return false
            }
        }

        function channelEventsCallback(channel) {
            ctrl.channel = channel;
            usersForMention();
        }

        function onNewMessage({ item, replyToMessage }) {
            const newMessage = { item, replyToMessage, isBlocked: ctrl.channel.blockedUsers?.includes(item.authorId) };
            if (item.authorId !== ctrl.currentUserToken) {
                
                if (ctrl.typingUsers?.length > 0){
                    const index = ctrl.typingUsers.findIndex(user => user.userId === item.authorId);
                    if (index !== -1) {
                        ctrl.typingUsers.splice(index, 1);
                    }
                    // Remove the user's timeout from the object
                    if (typingTimeouts[item.authorId]) {
                        clearTimeout(typingTimeouts[item.authorId]);
                    }
                }

                markAsReadDebounced();

                if (item.mediaIds?.length) {
                    ChatDataService.getMedias(item.mediaIds).then(medias => newMessage.medias = medias);
                }
                getReplyToMedias(newMessage, replyToMessage);

                if (!isMessageDisplayed(item.messageId)) {
                    ctrl.messages.push(newMessage);
                }
            } else {
                syncTemporaryMessageWithDatabase(newMessage, MESSAGE_STATUS.DELIVERED, replyToMessage);
                deleteMessageFromMemory(item.clientMessageId);

                if (!isMessageDisplayed(item.messageId)) {
                    if (item.mediaIds?.length) {
                        ChatDataService.getMedias(item.mediaIds).then(medias => newMessage.medias = medias);
                    }
                    getReplyToMedias(newMessage, replyToMessage);

                    ctrl.messages.push(newMessage);
                }
            }

            ctrl.channel.item.lastMessageId = newMessage.item.messageId;

            if (item.authorId === ctrl.currentUserToken || (scrollContainer.offsetHeight + scrollContainer.scrollTop >= scrollContainer.scrollHeight)) {
                $scope.$applyAsync(() => scrollToBottom());
            }
        }

        function isMessageDisplayed(messageId) {
            return ctrl.messages.findIndex(msg => msg.item.messageId === messageId) >= 0;
        }

        function getReplyToMedias(newMessage, replyToMessage) {
            if (replyToMessage?.mediaIds?.length) {
                ChatDataService.getMedias(replyToMessage.mediaIds).then(medias => {
                    newMessage.replyToMessageMedias = medias;
                });
            }
        }

        function createTemporaryMessage(clientMessageId) {
            const messageItem = {
                text: ctrl.message,
                authorId: ctrl.currentUserToken,
                messageId: clientMessageId,
                clientMessageId: clientMessageId
            };
            const newMessage = {
                item: messageItem,
                replyToMessage: ctrl.replyingMessage?.item || null,
                isBlocked: false,
                status: MESSAGE_STATUS.SENDING,
                medias: ctrl.uploadedMedia
            };

            if (ctrl.replyingMessage?.item?.mediaIds?.length) {
                ChatDataService.getMedias(ctrl.replyingMessage.item.mediaIds).then(medias => {
                    newMessage.replyToMessageMedias = medias;
                });
            }

            ctrl.channel.item.lastMessageId = clientMessageId;
            ctrl.messages.push(newMessage);

            $timeout(() => {
                scrollToBottom()
            }, 0);
        }

        function openMembersPopup() {
            ChatPopupsService.openChatMembersPopup(ctrl.channel, close);
        }

        function updateMessageStatus(clientMessageId, status) {
            ctrl.messages = ctrl.messages.map(msg => {
                if (msg.item.clientMessageId && msg.item.clientMessageId === clientMessageId) {
                    return { ...msg, status: status };
                } else {
                    return msg;
                }
            });
        }

        function showMessageStatus(message) {
            const messageReads = ctrl.messageReadStats[message.item.messageId];

            if (messageReads && messageReads.length > 0) return false;
            if (message.item.authorId !== ctrl.currentUserToken) return false;
            if (message.hideStatus) return false;

            ctrl.messages = ctrl.messages.map((msg, index, messages) => {
                if (index > 0 && msg.status === messages[index - 1].status) {
                    messages[index - 1].hideStatus = true;
                }
                return msg;
            });

            if (message.status === MESSAGE_STATUS.SENDING ||
                message.status === MESSAGE_STATUS.FAILED ||
                message.status === MESSAGE_STATUS.POSTPONED
            ) return true;

            if (ctrl.channel.item.lastMessageId !== message.item.messageId) return false;
            if (!message.status || message.status === MESSAGE_STATUS.DELIVERED || message.status === MESSAGE_STATUS.EDITED) return true;
        }

        function syncTemporaryMessageWithDatabase(newMessage, status, replyToMessage) {
            ctrl.messages = ctrl.messages.map(msg => {
                if (msg.item.clientMessageId && msg.item.clientMessageId === newMessage.item.clientMessageId) {

                    if (replyToMessage?.mediaIds && (msg.replyToMessageMedias?.length !== replyToMessage?.mediaIds?.length)) {
                        getReplyToMedias(newMessage, replyToMessage);
                    } else {
                        newMessage['replyToMessageMedias'] = msg.replyToMessageMedias;
                    }

                    newMessage['status'] = status;
                    newMessage['medias'] = msg.medias;
                    return newMessage;
                } else {
                    return msg;
                }
            })
        }

        function onMessageUpdate({ item }) {
            const messageIndex = ctrl.messages.findIndex(msg => msg.item.messageId === item.messageId),
                repliedToMessageIndexes = ctrl.messages
                    .map((msg, index) => msg.replyToMessage?.messageId === item.messageId ? index : -1)
                    .filter(ind => ind !== -1);

            ctrl.messages = ctrl.messages.map(message => message.item.messageId === item.messageId ?
                { ...message, status: MESSAGE_STATUS.EDITED } : { ...message });

            if (messageIndex !== -1) {
                $scope.$apply(() => {
                    ctrl.messages[messageIndex].item.text = item.text;
                    ctrl.messages[messageIndex].item.mediaIds = item.mediaIds;
                    ctrl.messages[messageIndex].item.dateDeleted = item.dateDeleted;
                    if (item.dateDeleted) {
                        ctrl.messages[messageIndex].item.reactionsSummary = [];
                        item.isDeleted = true;
                    }
                    if (ctrl.messages.length - 1 === messageIndex) {
                        $rootScope.$broadcast('CHAT.LAST_MESSAGE_UPDATE', { ...item });
                    }
                })
            }
            if (repliedToMessageIndexes?.length) {
                $scope.$apply(() => {
                    repliedToMessageIndexes.forEach(msgIndex => {
                        ctrl.messages[msgIndex].replyToMessage = item;
                    })
                })
            }

            if (item && !item.medias && ctrl.messages[messageIndex]) {
                $scope.$apply(() => {
                    ctrl.messages[messageIndex].medias = [];
                });
            }

            if (item?.mediaIds?.length) {
                ChatDataService.getMedias(item.mediaIds).then(medias => {
                    ctrl.messages[messageIndex].medias = medias;
                    if (repliedToMessageIndexes?.length) {
                        repliedToMessageIndexes.forEach(msgIndex => {
                            ctrl.messages[msgIndex].replyToMessageMedias = medias;
                        })
                    }
                });
            }
        }

        function onReactionUpdate(message) {
            const messageIndex = ctrl.messages.findIndex(msg => msg.item.messageId === message.messageId);

            if (messageIndex !== -1) {
                $scope.$apply(() => {
                    ctrl.messages[messageIndex].item.reactionsSummary = message.reactionSummaryList;
                    ctrl.messages[messageIndex].item.requestingUserReactionTypeId = message.requestingUserReactionTypeId;
                })
            }
        }

        function onResend(clientMessageId, editedText) {
            const newClientMessageId = createClientMessageId();
            ctrl.inMemoryMessages = ctrl.inMemoryMessages.map((msg) => {

                if (msg.clientMessageId === clientMessageId) {
                    if (editedText !== undefined) {
                        msg.text = editedText;
                    }

                    return { ...msg, clientMessageId: newClientMessageId }
                } else return msg;
            });

            const messageIndex = ctrl.messages.findIndex(msg => msg.item.clientMessageId === clientMessageId);

            if (messageIndex !== -1) {
                ctrl.messages[messageIndex].item.clientMessageId = newClientMessageId;
                ctrl.messages[messageIndex].status = MESSAGE_STATUS.SENDING;
                ctrl.messages[messageIndex].hideStatus = false;
                if (ctrl.message && ctrl.message !== ctrl.messages[messageIndex].item.text) {
                    ctrl.messages[messageIndex].item.text = ctrl.message;
                }
                const [messageInList] = ctrl.messages.splice(messageIndex, 1);
                ctrl.messages.push(messageInList);
            }

            handleMessageSending(newClientMessageId);
        }

        function onChannelMarkAsRead(data) {
            const read = ctrl.channel.item.messageReadStats.find(item => item.userId === data.userId)
            const channel = data.channelLastMessages ? data.channelLastMessages.find(channel => channel.channelId === ctrl.channel.item.channelId) : data;
            if (read) {
                read.lastReadMessageId = data.lastReadMessageId || channel.lastReadMessageId;
            } else {
                ctrl.channel.item.messageReadStats.push({
                    lastReadMessageId: channel.lastReadMessageId, userId: data.userId
                })
            }
            ctrl.messageReadStats = [];
            $scope.$applyAsync(() => {
                generateMessageReads(ctrl.channel)
            })
        }

        function generateMessageReads(channel) {
            channel.item.messageReadStats.forEach(item => {
                if (!ctrl.messageReadStats[item.lastReadMessageId]) {
                    ctrl.messageReadStats[item.lastReadMessageId] = []
                }
                if (item.userId !== ctrl.currentUserToken && !alreadyHaveUser(item) && !isAuthor(item)) {
                    ctrl.messageReadStats[item.lastReadMessageId].push(item.userId);
                }

                function alreadyHaveUser(item) {
                    return !!ctrl.messageReadStats[item.lastReadMessageId].find(id => id === item.userId)
                }

                function isAuthor(item) {
                    if (ctrl.messages) {
                        const message = ctrl.messages.find(message => message.item.messageId === item.lastReadMessageId)
                        return message && message.item.authorId === item.userId
                    }
                }
            })
        }

        function onUserStartedTyping(ev) {
            $scope.$apply(() => {
                const userId = ev.userId;
                const member = getMember(userId);

                ctrl.typingUsers = ctrl.typingUsers || [];
                if (!ctrl.typingUsers.find(user => user.userId === userId)) {
                    ctrl.typingUsers.push(member);
                }

                // Clear the previous timeout for this user
                if (typingTimeouts[userId]) {
                    clearTimeout(typingTimeouts[userId]);
                }

                // Set a new timeout for this user
                typingTimeouts[userId] = setTimeout(() => {
                    $scope.$applyAsync(() => {
                        // Remove the user from the typingUsers array
                        const index = ctrl.typingUsers.findIndex(user => user.userId === userId);
                        if (index !== -1) {
                            ctrl.typingUsers.splice(index, 1);
                        }
                        // Remove the user's timeout from the object
                        delete typingTimeouts[userId];
                    });
                }, 1000 * 60 * 3); // stops after 3 minutes of no updates
            });
        }

        function onUserStoppedTyping(ev) {
            $scope.$apply(() => {
                if (ctrl.typingUsers?.length > 0) {
                    const userId = ev.userId;

                    // Remove the user from the typingUsers array
                    const index = ctrl.typingUsers.findIndex(user => user.userId === userId);
                    if (index !== -1) {
                        ctrl.typingUsers.splice(index, 1);
                    }

                    // Remove the user's timeout from the object
                    if (typingTimeouts[userId]) {
                        clearTimeout(typingTimeouts[userId]);
                    }
                }
            });
        }

        function getMember(userId) {
            let user = ctrl.channel.members.find(user => user.userId === userId)
            return user || ctrl.users.find(user => user.userId === userId);
        }

        function markAsRead() {
            if (ctrl.channel?.item) {
                ctrl.channel.item.unreadMessagesCount = 0;
                ctrl.channel.item.forceUnread = false;
                ChatDataService.markChannelAsRead(ctrl.channel.item.channelId);
            }
        }

        function onKeydown(e) {
            if (e) {
                if (ctrl.isDesktop && e.keyCode === 13 && !e.shiftKey) {
                    sendMessage(e);
                } else if (e.keyCode === 13 && e.ctrlKey) {
                    sendMessage(e);
                } else if (ctrl.channel?.item?.channelId && ChatConnectionService.isConnected()) {
                    if (isTyping) {
                        $timeout(function() {
                            if (isTyping) {
                                if (!ctrl.message || ctrl.message.length === 0) {
                                    $timeout.cancel(userTypingTimeout);
                                    ConversationService.typingStopped(ctrl.channel.item.channelId);
                                    isTyping = false;
                                } else{
                                    $timeout.cancel(userTypingTimeout);
                                    userTypingTimeout = $timeout(function() {
                                        if (isTyping) {
                                            ConversationService.typingStopped(ctrl.channel.item.channelId);
                                            isTyping = false;
                                        }
                                    }, 10000);
                                }
                            }
                        });
                        return;
                    }

                    $timeout(function() {
                        if (!isTyping && ctrl.message && ctrl.message.length > 0){
                            ConversationService.typingStarted(ctrl.channel.item.channelId);
                            isTyping = true;

                            $timeout.cancel(userTypingTimeout);
                            userTypingTimeout = $timeout(function() {
                                if (isTyping) {
                                    ConversationService.typingStopped(ctrl.channel.item.channelId);
                                    isTyping = false;
                                }
                            }, 10000);
                        }
                    })
                }
            }
        }


        function sendMessage(e) {
            if (e) {
                e.stopPropagation();
                e.preventDefault();
            }

            isTyping = false;
            $timeout.cancel(userTypingTimeout);            

            if ((!ctrl.message && !ctrl.uploadedMedia.length) || ctrl.sending) {
                return false;
            }

            ctrl.sending = true;

            if (ctrl.unsafeMessageClientId) {
                onResend(ctrl.unsafeMessageClientId, ctrl.message);
                resetMessageInput();
                return;
            }

            const clientMessageId = createClientMessageId();
            ctrl.inMemoryMessages.push(saveMessageInMemory(clientMessageId));

            if (!ctrl.editingMessage) {
                createTemporaryMessage(clientMessageId);
            }
            resetMessageInput();

            handleMessageSending(clientMessageId);
        }

        function handleMessageSending(clientMessageId) {
            if (ChatConnectionService.isConnected()) {
                sendMessageInner(clientMessageId);
            } else {
                ToastFactory.error('CHAT.NO_NETWORK');
                updateMessageStatus(clientMessageId, MESSAGE_STATUS.FAILED);
                ctrl.sending = false;
                ChatConnectionService.reconnect();
            }
        }

        function createClientMessageId() {
            const timestamp = new Date().toISOString();
            const randomNumber = Math.floor(Math.random() * 1000000);
            return `${ctrl.channel.item.channelId}-${ctrl.currentUserToken}-${timestamp}-${randomNumber}`;
        }

        function sendMessageInner(clientMessageId) {

            sendFunction(clientMessageId)
                .then(() => {
                    ctrl.sending = false;
                    ctrl.ignoreContentSafetyWarning = false;
                    ctrl.unsafeMessageClientId = null;
                })
                .catch((resp) => {
                    ctrl.sending = false;
                    if (resp?.data?.ShowContentSafetyWarning) {
                        ctrl.unsafeMessageClientId = getUnsafeMessageClientId(resp.config.data);
                        updateMessageStatus(ctrl.unsafeMessageClientId, MESSAGE_STATUS.POSTPONED);
                        showUnsafeMessageInEdit();

                        ContentSafetyWarningService
                            .openPopup(resp.data.SeverityLevel, resp.data.Action,
                                'CHAT.UPDATE_MESSAGE', () => {
                                    ctrl.ignoreContentSafetyWarning = true;
                                    resetMessageInput();
                                    sendMessageInner(clientMessageId);
                                },
                            )
                    } else {
                        ToastFactory.error('ERROR.GENERAL');
                        updateMessageStatus(clientMessageId, MESSAGE_STATUS.FAILED);
                    }
                })
        }

        function showUnsafeMessageInEdit() {
            const unsafeMessage = ctrl.inMemoryMessages.find(msg => msg.clientMessageId === ctrl.unsafeMessageClientId);

            if (!unsafeMessage) return;

            ctrl.message = unsafeMessage.text;

            if (ctrl.message) {
                ctrl.mentions = unsafeMessage.mentions;
                $scope.$broadcast('mention:reInit', ctrl.mentions);
            }

            if (unsafeMessage.medias && unsafeMessage.medias.length) {
                ctrl.uploadedMedia = _.clone(unsafeMessage.medias);
            }
        }

        function getUnsafeMessageClientId(data) {
            ctrl.editingMessage = true;

            if (data.ClientMessageId) return data.ClientMessageId;

            const messageInMemory = ctrl.inMemoryMessages.find(msg => msg.messageId === data.MessageId);
            return messageInMemory ? messageInMemory.clientMessageId : null;
        }

        function saveMessageInMemory(clientMessageId) {
            const messageObj = {
                channelId: ctrl.channel.item.channelId,
                text: ctrl.message,
                medias: ctrl.uploadedMedia,
                mentions: ctrl.mentions,
                ignoreContentSafetyWarning: ctrl.ignoreContentSafetyWarning,
                clientMessageId: clientMessageId
            }

            if (ctrl.editingMessage) {
                messageObj['messageId'] = ctrl.edittingMessageId;
            } else {
                messageObj['replyToMessageId'] = ctrl.replyingMessage?.item.messageId;
            }
            return messageObj;
        }

        function sendFunction(clientMessageId) {
            const message = ctrl.inMemoryMessages.find((msg) => msg.clientMessageId === clientMessageId);
            message.ignoreContentSafetyWarning = ctrl.ignoreContentSafetyWarning;

            if (message.messageId) {
                return ConversationService.updateMessage(message)
            } else {
                return ConversationService.sendMessage(message);
            }
        }

        function deleteMessageFromMemory(clientMessageId) {
            ctrl.inMemoryMessages = ctrl.inMemoryMessages.filter((msg) => msg.clientMessageId !== clientMessageId);
        }

        function resetMessageInput() {
            ctrl.editingMessage = false;
            delete ctrl.edittingMessageId;
            clearReplyingMessage();
            ctrl.message = '';
            ctrl.uploadedMedia = [];
            ctrl.unsafeMessageClientId = null;
        }

        function cancelEdit() {
            if (ctrl.unsafeMessageClientId) {
                markPostponedMessagesAsFailed();
            }
            resetMessageInput();
        }

        function markPostponedMessagesAsFailed() {
            ctrl.messages = ctrl.messages.map(msg => {
                if (msg.status === MESSAGE_STATUS.POSTPONED) {
                    return { ...msg, status: MESSAGE_STATUS.FAILED };
                } else {
                    return msg;
                }
            })
        }

        function openRepliedContent() {
            Page.stateGoLink(ctrl.channel.replyContent.appLinkModel);
            $timeout(() => {
                close();
            }, 1000);
        }

        function showChatInfo() {
            ChatPopupsService.openChatSettingsPopup(ctrl.channel, close);
        }

        function openAddUsers() {
            ChatPopupsService.openAddUsersPopup(ctrl.channel, close);
        }

        function close(isRemoved) {
            ctrl.closeChannel && ctrl.closeChannel(ctrl.channelId, ctrl.channel.members);
            ChatConnectionService.destroyChannelItemsEvent();
            ConversationService.onConversationClose();
            
            if (isTyping) {
                ConversationService.typingStopped(ctrl.channelId);
            }

            scrollContainer?.removeEventListener('scroll', handleScroll);
            scrollContainer?.removeEventListener('scroll', handleScrollButton);

            $window.removeEventListener('resize', onResize);

            $('.message-form button').off('touchend', forceSubmit);


            if (!isRemoved) {
                markAsRead();
            }
            removePushOpenWatcher();
            destroyBlockedUsersUpdateWatcher();
            destroyChannelUpdatedWatcher && destroyChannelUpdatedWatcher();
            $scope.$destroy();
            popup.remove();
            $element.remove();
        }

        function scrollToBottom(callback) {
            $timeout(function () {
                scrollContainer.scrollTop = scrollContainer.scrollHeight + 100;
                callback && callback();
            });
        }

        function expandCollapseMessage() {
            ctrl.isExpanded = !ctrl.isExpanded;
            scrollToBottom();
        }

        function checkOverflow() {
            const originalMessage = ctrl.channel.item.isGroupChat ? $filter('translate')('LEAVE_REGISTRATION.GROUP_CHAT_MESSAGE') : $filter('translate')(ctrl.channel.activeLeave.TranslatableEntity._currentLocale.Comment);
            ctrl.shortMessage = truncateToClosestWord(originalMessage);
            ctrl.longMessage = originalMessage;
            ctrl.isOverflowing = ctrl.shortMessage.length !== ctrl.longMessage.length;

            function truncateToClosestWord(text) {
                if (text.length <= 100) return text.trim();

                let truncated = text.slice(0, 100);
                const lastSpaceIndex = truncated.lastIndexOf(" ");

                if (lastSpaceIndex > 0) {
                    truncated = truncated.slice(0, lastSpaceIndex);
                }

                return truncated.trim();
            }
        }

        function scrollToMessage(message, highlightMessage) {
            $timeout(function () {
                scrollContainer.scrollTop = message[0].offsetTop - 100;
                ctrl.initialLoad = false;

                if (highlightMessage) {
                    message.removeClass('highlight');
                    message.addClass('highlight');
                    if (scrollContainer.scrollHeight > scrollContainer.offsetHeight) {
                        $timeout(() => {
                            const removeHighlight = () => {
                                message.removeClass('highlight');
                                scrollContainer.removeEventListener('scroll', removeHighlight);
                            };
                            scrollContainer.addEventListener('scroll', removeHighlight)
                        }, 100);
                    } else {
                        $timeout(() => {
                            message.removeClass('highlight');
                        }, 3000)
                    }
                }
            });
        }

        function onMediaUpdated(media) {
            ctrl.uploadedMedia.push(media);
        }

        function removeMedia(index) {
            ctrl.uploadedMedia.splice(index, 1);
        }

        function openChannelInfo() {
            if (ctrl.channel.item.isGroupChat) {
                showChatInfo();
            } else {
                ProfileViewerService.showProfileCard(ctrl.currentChannelCompanion.userId);
            }
        }

        function onResize() {
            scrollToBottom();
        }

        function onBlur() {
            // fix ios viewport shift issue
            window.scrollTo(0, 0);

            if (isTyping && ctrl.channel?.item?.channelId && ChatConnectionService.isConnected()) {
                ConversationService.typingStopped(ctrl.channel.item.channelId);
                isTyping = false;
            }
        }

        function forceSubmit(e) {
            e.stopPropagation();
            sendMessage(e)
            return false;
        }

        function replyToMessage(message) {
            ctrl.replyingMessage = message;
        }

        function editMessage(message) {
            if (message) {
                ctrl.unsafeMessageClientId = null;
                ctrl.editingMessage = true;
                ctrl.edittingMessageId = message.item.messageId;
                ctrl.message = message.item.text;

                if (ctrl.message) {
                    message.item.taggedUsers = MentionService.mentionsFromString(message.item.text);
                    ctrl.mentions = ctrl.channel.members.filter(member => message.item.taggedUsers.includes(member.userId));
                    $scope.$broadcast('mention:reInit', ctrl.mentions);
                }

                if (message.medias && message.medias.length) {
                    ctrl.uploadedMedia = _.clone(message.medias);
                }
            }
        }

        function clearReplyingMessage() {
            delete ctrl.replyingMessage;
        }

        function onOpenOptions(message) {
            if ($element.find('.message-wrapper:last').data('index') === message.item.messageId) {
                scrollContainer.removeEventListener('scroll', handleScrollButton);
                $timeout(() => {
                    scrollToBottom(() => {
                        $timeout(() => {
                            scrollContainer.addEventListener('scroll', handleScrollButton);
                        })
                    });
                }, 200)
            }
        }

        function isUserNotBlocked(member) {
            return !ctrl.channel.blockedUsers?.includes(member.userId);
        }

        function onRenderCompleted() {
            PopupWrapperService.fixKeyboardShift($element.find('.text-input mention-textarea textarea')[0], scrollToBottom);
        }

        function showAwayIcon() {
            return ctrl.channel && !ctrl.channel.item?.hideAuthor &&
                ctrl.channel.members?.find(member => member.away && member.userId !== ctrl.currentUserToken);
        }

        function destroy() {
            $element.off('click', '.post-tag', showMentionProfile);

            if (ctrl.btnHammer) {
                ctrl.btnHammer.destroy();
            }
        }
    }
})();
