(function () {
    'use strict';

    angular
        .module('App')
        .component('serviceForm', {
            template: require('./ServiceFormComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$q', '$rootScope', '$timeout', '$stateParams', '$state', '$element', '$http', 'PopupWrapperService', 'ServiceFormRenderService',
                'ServiceFormRestService', 'TemplateFactory', 'HeaderButtonsFactory', 'StagesService', 'ToastFactory', 'Page', 'Menu',
                'ResponsiveService', ServiceFormController],
        });

    function ServiceFormController($q, $rootScope, $timeout, $stateParams, $state, $element, $http, PopupWrapperService, ServiceFormRenderService,
                                   ServiceFormRestService, TemplateFactory, HeaderButtonsFactory, StagesService,
                                   ToastFactory, Page, Menu, ResponsiveService) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        ctrl.changeView = changeView;
        ctrl.fillingCreated = fillingCreated;
        ctrl.getHeaderButtons = getHeaderButtons;
        ctrl.openFiltersPopup = openFiltersPopup;
        ctrl.onGetNewFillings = onGetNewFillings;
        ctrl.openCreateFilling = openCreateFilling;
        ctrl.onUpdateAppliedFilters = onUpdateAppliedFilters;
        ctrl.applyFilter = applyFilter;

        const goToLimitedReportsEvent = $rootScope.$on('goToLimitedReports', applyFilter);

        function init() {
            ctrl.isLoading = true;
            getServiceFormData().then((formData) =>
            {
                bindData(formData);
                addServiceFormClass();
                ctrl.isLoading = false;

                var onPopupRegistered = $q(function (resolve) {
                    ctrl.onPopupRegistered = resolve;
                }), isFullPopupRegistered = $q(function (resolve) {
                    ctrl.onFullPopupRegistered = resolve;
                });

                ctrl.template = TemplateFactory.getTemplate();
                ctrl.isMultipleColumn = true;
                HeaderButtonsFactory.createButtonsList(ctrl.getHeaderButtons);

                // Add data to fillings
                ServiceFormRenderService.setFeedbackOptions({
                    IsFeedbackEnabled: ctrl.serviceFormData.IsFeedbackEnabled,
                    IsFeedbackDrawingEnabled: ctrl.serviceFormData.IsFeedbackDrawingEnabled
                });

                ServiceFormRenderService.setFeedbackRanges(ctrl.serviceFormData.FeedbackRanges);
                ServiceFormRenderService.setFeedbackRatingRange(ctrl.serviceFormData.RatingRange);
                ServiceFormRenderService.saveFillings(ctrl.serviceFormData.Fillings);
                ServiceFormRenderService.indexGroups(ctrl.serviceFormData.Form);
                ServiceFormRenderService.setDatalists(ctrl.serviceFormData.DataLists);
                ServiceFormRenderService.setLabelSettings(ctrl.serviceFormData.LabelSettings);
                ServiceFormRenderService.setGpsSettings({
                    ShowCustomersOnMapEnabled: ctrl.serviceFormData.ShowCustomersOnMapEnabled,
                    GpsToAddressEnabled: ctrl.serviceFormData.GpsToAddressEnabled,
                    IsGpsTrackingEnabled: ctrl.serviceFormData.IsGpsTrackingEnabled
                });

                ServiceFormRenderService.fillingInteractionSettings = {
                    IsCommentingEnabled: ctrl.serviceFormData.IsCommentingEnabled,
                    IsReactingEnabled: ctrl.serviceFormData.IsReactingEnabled,
                    ShowCustomersOnMapEnabled: ctrl.serviceFormData.ShowCustomersOnMapEnabled,
                    AllowAutomaticTranslation: ctrl.serviceFormData.AllowAutomaticTranslation,
                    PreferTranslatedComments: ctrl.serviceFormData.PreferTranslatedComments,
                    PreferTranslatedFillings: ctrl.serviceFormData.PreferTranslatedFillings,
                };

                ctrl.createFillingPopupId = ServiceFormRenderService.createFillingPopupId;
                ctrl.updateFillingPopupId = ServiceFormRenderService.updateFillingPopupId;
                ctrl.filterFillingPopupId = ServiceFormRenderService.filterFillingPopupId;

                ctrl.appliedFiltersCount = 0;
                ctrl.fillingsOverviewPopupId = ServiceFormRenderService.fillingsOverviewPopupId;

                onPopupRegistered.then(function () {
                    if (ctrl.serviceFormData.CanUserCreateFillings && ctrl.serviceFormData.openCreateFilling) {
                        ctrl.openCreateFilling();
                    }
                });
                isFullPopupRegistered.then(function () {
                    if (ctrl.fillingData) {
                        PopupWrapperService.getPopup('fillingPreviewPopupId').open(ctrl.fillingData);
                    }
                });
            })
            .catch(er => {
                ToastFactory.error('ERROR.GENERAL');
                ctrl.isLoading = false;
            });
        }

        function addServiceFormClass() {
            if (ctrl.serviceFormData && ctrl.serviceFormData.Form && ctrl.serviceFormData.Form.ServiceFormId) {
                const serviceFormId = ctrl.serviceFormData.Form.ServiceFormId;
                const element = $element[0]

                if (element) {
                    element.classList.add(`service-form-view-${serviceFormId}`);
                }
            }
        };

        function getServiceFormData() {
            let requests = [];
            const urlSearchParams = new URLSearchParams($stateParams.extra)

            requests.push(
                $http.post('/ServiceForm/GetAllServiceFormData/' + $stateParams.token, {
                    serviceFormId: urlSearchParams.get('ServiceFormId')
                })
            );

            if (urlSearchParams.get('ServiceFormFillingToken')) {
                requests.push(ServiceFormRestService.getFillingData(urlSearchParams.get('ServiceFormFillingToken')))
            }

            return $q.all(requests).then(function (resp) {
                const response = {
                    PageData: resp[0].data
                };

                if (urlSearchParams.get('ServiceFormFillingToken')) {
                    response.FillingData = resp[1];
                }

                response.ServiceFormFieldId = urlSearchParams.get('ServiceFormFieldId');
                response.ShowFeedbackPage = urlSearchParams.get('ShowFeedbackPage') === "true";
                response.CommentId = urlSearchParams.get('CommentId');

                return response
            })
        }

        function bindData(formData) {
            Page.setTitle(formData.PageData.PageTitle);

            // Update menu activity

            const navigationBadge = formData.PageData.NavigationBadge;

            Menu.removeActivity($stateParams.data.badge, 'serviceFormOverview', $state.params.token);
            Menu.removeLandingBadge($stateParams.data.badge, navigationBadge.AccountModuleId, navigationBadge.ModuleId, navigationBadge.ModuleItemId);

            // Bind data to scope
            if (formData.FillingData) {
                ctrl.fillingData = formData.FillingData;
                ctrl.fillingData.ShowFeedbackPage = formData.ShowFeedbackPage;
                ctrl.fillingData.ScrollToFeedback = formData.ServiceFormFieldId;
                ctrl.fillingData.CommentId = formData.CommentId;
                ctrl.fillingData.Filling = ServiceFormRenderService.formatFillingItemMedia(ctrl.fillingData.Filling);
            }
            ctrl.serviceFormData = formData.PageData;
            ctrl.serviceFormData.openCreateFilling = $stateParams.data.openCreateFilling;
        }

        function getHeaderButtons() {
            const buttons = [
                {
                    icon: 'arrow-bottom',
                    activeIcon: 'arrow-top',
                    onClick: function () {
                        ctrl.changeView(ctrl.isMultipleColumn = !ctrl.isMultipleColumn);
                    },
                    caption: 'SERVICEFORM.CHANGE_VIEW.TITLE',
                    place: 'title',
                    items: [
                        {
                            title: 'SERVICEFORM.CHANGE_VIEW.GRID',
                            onClick: function () {
                                ctrl.changeView(true);
                            },
                            active: ctrl.isMultipleColumn
                        },
                        {
                            title: 'SERVICEFORM.CHANGE_VIEW.LIST',
                            onClick: function () {
                                ctrl.changeView(false);
                            },
                            active: !ctrl.isMultipleColumn
                        }
                    ]
                }
            ];

            !ResponsiveService.isDesktop() && buttons.push(
                {
                    icon: 'filter',
                    onClick: function () {
                        ctrl.openFiltersPopup();
                    },
                    place: 'right',
                    active: !!ctrl.appliedFiltersCount,
                    badges: ctrl.appliedFiltersCount
                }
            )

            return buttons;
        }


        function openCreateFilling() {
            if (ctrl.serviceFormData.IsStagesEnabled) {
                if (!ctrl.serviceFormData.Form.Stages.length) {
                    ToastFactory.error('SERVICEFORM.FORM_EMPTY');
                } else {
                    StagesService.openStagesPopup(
                        {
                            stages: ctrl.serviceFormData.Form.Stages,
                            onClose: latestFilling => updateUncompletedFilling(latestFilling)
                        }
                    );
                }
            } else {
                if (!ctrl.serviceFormData.Form.Steps.length) {
                    ToastFactory.error('SERVICEFORM.FORM_EMPTY');
                } else {
                    ctrl.createFillingPopup = PopupWrapperService.getPopup(ctrl.createFillingPopupId);
                    ctrl.createFillingPopup.open({stage: null});
                }
            }
        }

        function applyFilter(event, data) {
            const formattedData = {
                CascadingFilters: [],
                FeedbackRangeList: [],
                OnlyMyFillings: [],
                limit: 24,
                offset: 0,
                DataListFilters: [data.DataListFilters],
                IncludeDrafts: true,
                NewReports: false
            }

            ServiceFormRestService.applyFilters(formattedData);
            ServiceFormRestService.loadFilteredFillings(formattedData, ctrl.serviceFormData.Form.ServiceFormId).then((data) => {
                onGetNewFillings(data);
                onUpdateAppliedFilters(1);
            });

            ctrl.serviceFormData.Filters = ctrl.serviceFormData.Filters.map(filter =>
                filter.ServiceFormFieldId === data.DataListFilters.ServiceFormFieldId ? {
                    ...filter,
                    inputModel: data.listData.map(item => ({...item, inUse: false})),
                    model: [{...data.item, inUse: false}]
                } : {
                    ...filter,
                    inputModel: data.listData.map(item => ({...item, inUse: false})),
                    model: []
                });
        }

        function openFiltersPopup() {
            ctrl.filterFillingPopup = PopupWrapperService.getPopup(ctrl.filterFillingPopupId);
            ctrl.filterFillingPopup.open();
        }

        function onGetNewFillings(data) {
            ctrl.serviceFormData.Fillings = data.Fillings;
            ctrl.serviceFormData.HasMoreFillings = data.HasMoreFillings;
        }

        function updateUncompletedFilling(fillingLatest) {
            fillingCreated(fillingLatest);
        }

        function onUpdateAppliedFilters(count) {
            ctrl.appliedFiltersCount = count;
            HeaderButtonsFactory.createButtonsList(ctrl.getHeaderButtons);
        }

        function fillingCreated(data) {
            if (data && data.Filling) {
                var fillingIndex = _.findIndex(ctrl.serviceFormData.Fillings, {ServiceFormFillingToken: data.Filling.ServiceFormFillingToken});

                if (fillingIndex !== -1) {
                    $rootScope.$broadcast('fillingUpdated', data)
                    ctrl.serviceFormData.Fillings[fillingIndex] = data.Filling;
                } else {
                    ctrl.serviceFormData.Fillings.unshift(data.Filling);
                    if (ctrl.serviceFormData.IsStagesEnabled) {
                        PopupWrapperService.getPopup('fillingPreviewPopupId')
                            .open(data.Filling.ServiceFormFillingToken);
                        $timeout(function () {
                            $rootScope.$broadcast('fillingCreated', data)
                        })
                    }
                }
            } else {
                ServiceFormRestService
                    .lazyLoadFillings(null, null, ctrl.serviceFormData.Form.ServiceFormId)
                    .then(function (data) {
                        ctrl.serviceFormData.Fillings = data.Fillings;
                        ctrl.serviceFormData.HasMoreFillings = data.HasMoreFillings;
                    });
            }
        }

        function changeView(state) {
            ctrl.isMultipleColumn = state;
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            ServiceFormRenderService.destroyData();
            goToLimitedReportsEvent();
        }
    }
})();
