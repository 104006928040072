(function () {
    'use strict';

    var app = angular.module('App');

    app.component('commentItem', {
        template: require('./CommentItemComponent.html'),
        controllerAs: 'ctrl',
        controller: ['$scope', '$http', '$timeout', 'Profile', 'ToastFactory', 'MentionService', 'Page',
            'TranslationService', CommentItemComponentController],
        bindings: {
            postId: '<',
            comment: '<',
            settings: '<',
            onShowOptions: '<',
            onReply: '<',
            onLike: '<',
            onSendReaction: '<',
            onRemoveReaction: '<',
            onToggleReactionsList: '<',
            onShowReactionsViewer: '<',
            enableReply: '<',
            onRenderComplete: '<',
            disableReactions: '<',
            module: '@'
        },
    });


    function CommentItemComponentController($scope, $http, $timeout, Profile, ToastFactory, MentionService, Page,
        TranslationService) {
        const ctrl = this;
        let destroyTranslateWatcher;

        ctrl.like = ctrl.onLike;
        ctrl.reply = ctrl.onReply;
        ctrl.sendReaction = ctrl.onSendReaction;
        ctrl.removeReaction = ctrl.onRemoveReaction;
        ctrl.showOptions = ctrl.onShowOptions;
        ctrl.showReactionsViewer = ctrl.onShowReactionsViewer;

        ctrl.showUserTitle = Page.getSettings().Base.ShowUserTitleInComments;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.getSummaryReactions = getSummaryReactions;
        ctrl.toggleTranslation = toggleTranslation;
        ctrl.isTranslationAllowed = isTranslationAllowed;

        function init() {
            //TODO: change when unified logic implemented for all modules
            if (ctrl.module === 'Social' || ctrl.module === 'ContentModule' || ctrl.module === 'TaskManagement') {
                ctrl.enableTranslation = isTranslationAllowed();
                ctrl.comment._currentLocale = getCurrentLocale();
                const selectedTranslation = getCurrentLocale()?.Text;
                ctrl.comment._currentLocale.Text = MentionService.parseMentionsInText(
                    selectedTranslation,
                    ctrl.comment.TaggedUsers,
                    ctrl.comment.TaggedDepartments,
                    ctrl.comment.TaggedUserGroups,
                );

                if (ctrl.enableTranslation) {
                    ctrl.showTranslated = selectedTranslation === ctrl.comment.TranslatableEntity.LocalizedAlternativeLocale?.Text;
                }
            } else {
                ctrl.enableTranslation = ctrl.settings && (ctrl.settings.EnableAutoTranslation || ctrl.settings.AllowAutomaticTranslation);

                if (ctrl.comment.TranslatedText) {
                    ctrl.comment.TranslatedText = MentionService.parseMentionsInText(
                        ctrl.comment.TranslatedText,
                        ctrl.comment.TaggedUsers,
                        ctrl.comment.TaggedDepartments,
                        ctrl.comment.TaggedUserGroups,
                    );
                }
                if (ctrl.settings && ctrl.enableTranslation) {
                    ctrl.isAlreadyTranslated = !!ctrl.comment.TranslatedText;

                    if (ctrl.isAlreadyTranslated && ctrl.settings.PreferTranslatedComments) {
                        ctrl.showTranslated = true;
                    }
                }
            }


            $timeout(function () {
                ctrl.onRenderComplete && ctrl.onRenderComplete();
            })

            destroyTranslateWatcher = $scope.$on('comments:toggleTranslate', (ev, { showTranslated }) => {
                if ((ctrl.module === 'Social' || ctrl.module === 'ContentModule' || ctrl.module === 'TaskManagement') ? ctrl.comment._currentLocale?.Text : ctrl.comment.TranslatedText) {
                    ctrl.showTranslated = showTranslated;
                }
            })
        }


        function isTranslationAllowed() {
            return ctrl.comment.TranslatableEntity && (ctrl.comment.TranslatableEntity.AllowAutoTranslation ||
                (!ctrl.comment.TranslatableEntity.AllowAutoTranslation && !!ctrl.comment.TranslatableEntity.LocalizedAlternativeLocale))
        }

        function getCurrentLocale() {
            return ctrl.comment.TranslatableEntity.PreferTranslatedVersion && ctrl.comment.TranslatableEntity.LocalizedAlternativeLocale ?
                ctrl.comment.TranslatableEntity.LocalizedAlternativeLocale : ctrl.comment.TranslatableEntity.LocalizedLocale
        }

        function getSummaryReactions(reactions) {
            return reactions
                .filter(function (r) {
                    return r.Count;
                })
                .splice(0, 3);
        }

        function toggleTranslation(val) {
            if (ctrl.module === 'Social' || ctrl.module === 'ContentModule' || ctrl.module === 'TaskManagement') {
                if (ctrl.isTranslating) {
                    return;
                }

                ctrl.isTranslating = true;

                if (!(ctrl.comment.TranslatableEntity.LocalizedAlternativeLocale?.Text && ctrl.comment.TranslatableEntity.LocalizedLocale?.Text)) {
                    var translateRequest = TranslationService.translateComment(ctrl.module,
                        ctrl.postId,
                        getCommentId(ctrl.comment),
                        ctrl.comment.TranslatableEntity.LocalizedLocale.Text
                    ).then(res => res.data.Comment);
                }

                TranslationService.switchTranslateableLocale(ctrl.comment.TranslatableEntity, translateRequest).then(res => {
                    ctrl.comment._currentLocale = res;
                    ctrl.comment.TranslatableEntity._currentCultureId = res.CultureId;
                    ctrl.comment._currentLocale.Text = MentionService.parseMentionsInText(
                        ctrl.comment._currentLocale.Text,
                        ctrl.comment.TaggedUsers,
                        ctrl.comment.TaggedDepartments,
                        ctrl.comment.TaggedUserGroups,
                    );
                    ctrl.isTranslating = false;

                    if (!ctrl.comment.TranslatableEntity.LocalizedAlternativeLocale && res.Text !== ctrl.comment.TranslatableEntity.LocalizedLocale.Text) {
                        ctrl.comment.TranslatableEntity.LocalizedAlternativeLocale = res;
                        ctrl.comment.TranslatableEntity.LocalizedAlternativeLocale.Text = MentionService.parseMentionsInText(
                            ctrl.comment.TranslatableEntity.LocalizedAlternativeLocale.Text,
                            ctrl.comment.TaggedUsers,
                            ctrl.comment.TaggedDepartments,
                            ctrl.comment.TaggedUserGroups,
                        );
                    }

                    ctrl.showTranslated = val;
                }).catch(() => {
                    ToastFactory.error('ERROR.GENERAL');
                });
            } else oldToggleTranslation(val);
        }


        function oldToggleTranslation(val) {
            if (!ctrl.comment.TranslatedText) {
                ctrl.isTranslating = true;
                TranslationService.translateComment(
                    ctrl.module,
                    ctrl.postId,
                    getCommentId(ctrl.comment),
                    ctrl.comment.originalText || ctrl.comment.Text
                ).then(resp => {
                    if (resp.data.Text) {
                        ctrl.comment.TranslatedText = MentionService.parseMentionsInText(
                            resp.data.Text,
                            ctrl.comment.TaggedUsers,
                            ctrl.comment.TaggedDepartments,
                            ctrl.comment.TaggedUserGroups,
                            ctrl.settings.HashtagsEnabled
                        );
                        ctrl.showTranslated = val;
                    } else {
                        ToastFactory.error('TRANSLATION.CANT_TRANSLATE');
                        ctrl.comment.CanBeTranslated = false;
                    }
                }).catch(() => {
                    ToastFactory.error('TRANSLATION.CANT_TRANSLATE');
                    ctrl.comment.CanBeTranslated = false;
                }).finally(() => {
                    ctrl.isTranslating = false;
                })
            } else {
                ctrl.showTranslated = val;
            }
        }

        function getCommentId(comment) {
            return comment.ContentCommentId || comment.SocialCommentId || comment.IssueCommentId ||
                comment.ServiceFormFillingCommentId || comment.ServiceFormFeedbackCommentId ||
                comment.ServiceFormStageCommentId;
        }

        function destroy() {
            destroyTranslateWatcher && destroyTranslateWatcher();
        }
    }
})();
