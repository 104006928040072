(function () {
    'use strict';

    angular.module('App')
        .component('departmentPopup', {
            templateUrl: 'Scripts/Components/DepartmentPopup/DepartmentPopupComponent.html',
            controllerAs: 'ctrl',
            controller: ['$timeout', '$element', '$http', 'ActionSheetService', '$translate', 'BasicHelper', DepartmentPopupComponent],
            bindings: {
                onPopupRegistered: '<',
                onClose: '<',
                showContacts: '<',
                moduleToken: '<'
            }
        });

    function DepartmentPopupComponent($timeout, $element, $http, ActionSheetService, $translate, BasicHelper) {
        var ctrl = this, actionSheet, scrolledContainer, throttledScroll = _.throttle(onScrollEvent, 300);
        var popup;

        ctrl.popupId = 'DepartmentPopupId';
        ctrl.onOpen = onOpen;
        ctrl.close = close;
        ctrl.getLinearBackground = getLinearBackground;
        ctrl.callAction = callAction;
        ctrl.emailAction = emailAction;

        ctrl.hasContent = false;
        ctrl.hasUsers = false;
        ctrl.scrolledToTop = true;

        ctrl.$onInit = function () {

        };

        function onOpen(token, popupCtrl) {
            ctrl.departmentToken = token;
            LoadDepartment(ctrl.departmentToken);
            popup = popupCtrl;
        }

        function LoadDepartment(token) {
            ctrl.department = null;
            ctrl.loading = true;

            $http.get('/Department/Load/' + token).then(function (response) {
                if (response.data !== null && typeof response.data === 'object') {
                    ctrl.department = response.data;
                    LoadUsers(token);
                }
            }, function () {
                ctrl.department = null;
            });
        }

        function LoadUsers(token) {
            return $http.get('/Contact/LoadUsers/' + token).then(function (resp) {
                ctrl.department.Users = resp.data.Users;
                ctrl.loading = false;

                if (ctrl.department.Users.length > 0) {
                    ctrl.hasUsers = true;
                }

                setContactsHeadline();
                showDepartmentContent();
                showDepartmentEmployees();

                $timeout(function () {
                    scrolledContainer = angular.element(document.querySelector(".department-popup-container"));
                    scrolledContainer.on('scroll', throttledScroll);
                }, 500)
                return resp.data.Users
            })
        }

        function onScrollEvent() {
            $timeout(function () {
                if (scrolledContainer.scrollTop() > 50) {
                    ctrl.scrolledToTop = false;
                } else {
                    ctrl.scrolledToTop = true;
                }
            }, 50)
        }

        function callAction(event) {
            var contactButtons = [];

            var headData = {
                description: 'Call ' + ctrl.department.Name
            }

            if (ctrl.department.PhoneCode > 0 && ctrl.department.Phone) {
                contactButtons.push(
                    {
                        subText: 'PHONE',
                        text: ('+' + ctrl.department.PhoneCode + ' ' + ctrl.department.Phone),
                        icon: 'phone',
                        onClick: function () {
                            window.location.href = 'tel:+' + ctrl.department.PhoneCode + '-' + ctrl.department.Phone;
                        }
                    }
                );
            }

            actionSheet = ActionSheetService.create(contactButtons, event.currentTarget, headData, { name: 'department-popup', token: ctrl.moduleToken});
            actionSheet.show();
        }

        function emailAction(event) {
            var contactButtons = [];

            var headData = {
                description: 'Send e-mail to ' + ctrl.department.Name
            }

            if (ctrl.department.Email) {
                contactButtons.push(
                    {
                        subText: 'Primary mail',
                        text: (ctrl.department.Email),
                        icon: 'email',
                        onClick: function () {
                            BasicHelper.mailTo(ctrl.department.Email)
                        }
                    }
                );
            }

            actionSheet = ActionSheetService.create(contactButtons, event.currentTarget, headData, { name: 'department-popup', token: ctrl.moduleToken});
            actionSheet.show();
        }

        function setContactsHeadline() {
            if (ctrl.department.ContactsTitle === null || ctrl.department.ContactsTitle.length < 1) {
                $translate('CONTACT.DEPARTMENT_CONTACTS_TITLE').then(function (translation) {
                    ctrl.department.ContactsTitle = translation;
                });
            }
        }

        function getLinearBackground() {
            if (ctrl.scrolledToTop) {
                return {
                    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), url('${ctrl.department.Image.Url}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                };
            } else {
                return {
                    background: '#FFFFFF',
                    top: '110px'
                };
            }
        }

        function showDepartmentContent() {
            if (ctrl.department.Description ||
                ctrl.department.Email ||
                ctrl.department.Address ||
                ctrl.department.Phone ||
                (ctrl.department.DepartmentContacts
                    && ctrl.department.DepartmentContacts.length && ctrl.showContacts && ctrl.department.ShowContactList)) {
                ctrl.hasContent = true;
            }

            if (ctrl.department.DepartmentManager) {
                ctrl.hasContent = true;
            }
        }

        function showDepartmentEmployees() {
            if ((ctrl.department.Users.length > 0) && ctrl.department.ShowDepartmentUsersInPublicContacts) {
                ctrl.hasUsers = true;
            }
        }

        function close() {
            ctrl.department = null;
            ctrl.onClose && ctrl.onClose();
            popup.remove();
            $element.remove();
        }

    }

})();
