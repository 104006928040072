import {Directive, ElementRef, Inject, Injector, Input} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";
import {type RelesysIconPrefixesType, type RelesysIconsType} from "./icons";

@Directive({
    selector: 'rl-icon',
})
export class RlIconDirective extends UpgradeComponent {
    @Input() icon?: RelesysIconsType;
    @Input() prefix?: RelesysIconPrefixesType;
    @Input() iconClass?: string;
    @Input() svg?: string;

    constructor(@Inject(ElementRef) elementRef: ElementRef, @Inject(Injector) injector: Injector) {
        // We must pass the name of the directive as used by AngularJS to the super
        super('rlIcon', elementRef, injector);
    }
}