import "@angular/compiler";
import 'zone.js';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RelesysAppModule } from "./rl-app.module";
import { UpgradeModule } from '@angular/upgrade/static';
import { enableProdMode } from "@angular/core";

declare const process: {
    env: {
        NODE_ENV: 'development' | 'production';
    };
};

if (process.env.NODE_ENV === 'production') {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(RelesysAppModule)
    .then(ref => {
        const upgrade = ref.injector.get(UpgradeModule) as UpgradeModule;
        upgrade.bootstrap(document.body, ['App']);
    })
    .catch(err => console.error(err));
 