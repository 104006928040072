(function () {
    'use strict';

    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('userManagement', {
                parent: 'base',
                url: '/userManagement/:token/:extra',
                template: require('./UserManagement.html'),
                controller: 'UserManagementController as ctrl'
            });
        }])
        .controller('UserManagementController', ['$rootScope', '$scope', '$stateParams', '$timeout', '$http', 'base', 'Page', 'events',
            'ModuleSearchService', 'HeaderButtonsFactory', 'UserManagementService', 'HowToGuideService', 'ToastFactory',
            'ResponsiveService',
            function ($rootScope, $scope, $stateParams, $timeout, $http, base, Page, events, ModuleSearchService,
                      HeaderButtonsFactory, UserManagementService, HowToGuideService, ToastFactory, ResponsiveService) {
                const ctrl = this;
                let userList = [];
                let searchUsers = [];
                ctrl.paging = paging;
                ctrl.search = search;
                ctrl.editUser = editUser;
                ctrl.createUser = createUser;
                ctrl.openFilters = openFilters;
                ctrl.openInfoPopup = openInfoPopup;
                ctrl.getManagementFilters = getManagementFilters;
                ctrl.closeFilters = closeFilters;
                ctrl.noUsersFound = noUsersFound;
                ctrl.noPendingUsersExist = noPendingUsersExist;
                ctrl.noActiveUsersExist = noActiveUsersExist;
                ctrl.noDisabledUsersExist = noDisabledUsersExist;
                ctrl.switchTab = switchTab;
                ctrl.getUsersForActiveView = getUsersForActiveView;
                ctrl.searchSettings = {
                    onModelChange: ctrl.search,
                    model: ''
                };
                ctrl.isDesktop = ResponsiveService.isDesktop();
                ctrl.isLoading = true;
                ctrl.placeholderUsers = ctrl.isDesktop 
                    ? Array.from({ length: 12 }, (_, i) => i + 1) 
                    : Array.from({ length: 6 }, (_, i) => i + 1);

                if (!ctrl.isDesktop) {
                    HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                }

                // Listen for events
                $scope.$on(events.DEPARTMENT_CHANGED, function () {
                    Page.stateReload();
                });
                
                $scope.$on("$destroy", destroy);

                initUsersList().then((receivedUserList) => {
                    userList = receivedUserList;
                    setPageInfo();
                    initializeVariables();
                    ctrl.isLoading = false;
                })

                function initUsersList() {
                    if (base) {
                        const url = '/UserManagement/GetUsers/' + $stateParams.token +
                            ($stateParams.extra === 'recent' ? '?showOnlyRecent=true' : '')

                        return $http.get(url).then(resp => ({
                            ...resp.data,
                            Recent: $stateParams.extra === 'recent'
                        }));
                    }
                    return [];
                }

                function setPageInfo() {
                    Page.showBackButton();
                    Page.setTitle(userList.PageTitle);
                }
                
                function initializeVariables() {
                    ctrl.filtersCount = userList.Recent ? 1 : 0;
                    ctrl.activeView = 1;
                    ctrl.animateTabChange = true;
                    initAllTabs();
                    ctrl.settings = userList.Settings;
                    ctrl.UserSignUpEnabled = userList.UserSignUpEnabled;
                    ctrl.enableHowToGuide = userList.HowToGuideEnabled;
                    ctrl.userCreationAllowed = userList.UserCreationAllowed;
                    ctrl.userEditingAllowed = userList.UserEditingAllowed;
                    ctrl.userCount = userList.Users.length;
                    ctrl.showFilters = false;
                    updateActivityWidget(userList);
                    $rootScope.HaveInfoButton = userList.HowToGuideEnabled;

                    if (userList.Recent) {
                        ctrl.filterModel = UserManagementService.getCleanFilterModel();
                        ctrl.filterModel.filters.Recent = userList.Recent;
                    }
                }
                
                function paging() {
                    if (!ctrl.searchSettings.model?.trim()) {
                        applyPagingWithNoSearch();
                        return;
                    } else {
                        applyPagingWithSearch();
                    }
                }

                function applyPagingWithSearch() {
                    switch (ctrl.activeView) {
                        case 1: {
                            if (ctrl.users.length !== searchUsers.view1.length) {
                                ctrl.users = searchUsers.view1.slice(0, ctrl.users.length + 20);
                            }
                            break;
                        }
                        case 2: {
                            if (ctrl.pendingUsers.length !== searchUsers.view2.length) {
                                ctrl.pendingUsers = searchUsers.view2.slice(0, ctrl.pendingUsers.length + 20);
                            }
                            break;
                        }
                        case 3: {
                            if (ctrl.disabledUsers.length !== searchUsers.view3.length) {
                                ctrl.disabledUsers = searchUsers.view3.slice(0, ctrl.disabledUsers.length + 20);
                            }
                            break;
                        }
                        default:
                            break;                    
                    }
                }

                function applyPagingWithNoSearch() {
                    switch (ctrl.activeView) {
                        case 1: {
                            if (ctrl.users.length !== userList.Users.length) {
                                ctrl.users = userList.Users.slice(0, ctrl.users.length + 20);
                            }
                            break;
                        }
                        case 2: {
                            if (ctrl.pendingUsers.length !== userList.PendingUsers.length) {
                                ctrl.pendingUsers = userList.PendingUsers.slice(0, ctrl.pendingUsers.length + 20);
                            }
                            break;
                        }
                        case 3: {
                            if (ctrl.disabledUsers.length !== userList.DisabledUsers.length) {
                                ctrl.disabledUsers = userList.DisabledUsers.slice(0, ctrl.disabledUsers.length + 20);
                            }
                            break;
                        }
                        default:
                            break;
                    }
                }

                function getUsersForActiveView() {
                    switch (ctrl.activeView) {
                        case 1: {
                            return ctrl.users || [];
                        }
                        case 2: {
                            return ctrl.pendingUsers || [];
                        }
                        case 3: {
                            return ctrl.disabledUsers || [];
                        }
                        default:
                            return [];
                    }
                }

                function switchTab(tab) {
                    ctrl.animateTabChange = false;
                    clearToasts();

                    $timeout(() => {
                        ctrl.animateTabChange = true;
                        ctrl.activeView = tab;
                    }, 300);

                    angular.element('.user-management-scroll-container')[0]?.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
                
                function clearToasts() {
                    ToastFactory.clear();
                }

                function search(name) {
                    const searchIsEmpty = !name || name.trim() === "";

                    if (searchIsEmpty) {
                        initAllTabs();
                        return;
                    }

                    const activeView1 = searchFilter(userList.Users, name);
                    const activeView2 = searchFilter(userList.PendingUsers, name);
                    const activeView3 = searchFilter(userList.DisabledUsers, name);

                    ctrl.users = activeView1.slice(0, 50);
                    ctrl.pendingUsers = activeView2.slice(0, 50);
                    ctrl.disabledUsers = activeView3.slice(0, 50);

                    searchUsers = { view1: activeView1, view2: activeView2, view3: activeView3 };
                    
                    angular.element('.user-management-scroll-container')?.scrollTop(0);
                }

                function searchFilter(users, name) {
                    return (users || [])
                        .filter(user => user.Name.toLowerCase().indexOf(name.toLowerCase()) !== -1 ||
                            user.Title.toLowerCase().indexOf(name.toLowerCase()) !== -1)
                }

                function updateUsersOnClose(newUser) {
                    if (newUser) {
                        ctrl.isLoading = true;
                        UserManagementService.getUsers($stateParams.token).then((resp) => {
                            ctrl.searchSettings.model = '';
                            ctrl.filterModel =  UserManagementService.getCleanFilterModel();
                            ctrl.filtersCount = 0;
                            userList.PendingUsers = resp.data.PendingUsers;
                            userList.Users = resp.data.Users;
                            userList.DisabledUsers = resp.data.DisabledUsers;
                            initAllTabs();
                            ModuleSearchService.searchReset && ModuleSearchService.searchReset();
                        }).finally(() => ctrl.isLoading = false);
                    }
                }

                function initAllTabs() {
                    ctrl.users = userList.Users.slice(0, 50);
                    ctrl.pendingUsers = userList.PendingUsers.slice(0, 50);
                    ctrl.disabledUsers = userList.DisabledUsers.slice(0, 50);
                }

                function noUsersFound() {
                    const noUsersInActiveView = ctrl.activeView === 1 && !ctrl.users.length;
                    const noUsersInPendingView = ctrl.activeView === 2 && !ctrl.pendingUsers.length;
                    const noUsersInDisabledView = ctrl.activeView === 3 && !ctrl.disabledUsers.length;
                    const usersSearchedOrFiltered = ctrl.filtersCount > 0 || ctrl.searchSettings.model;
                    return  usersSearchedOrFiltered && (noUsersInActiveView || noUsersInPendingView || noUsersInDisabledView);
                }

                function noPendingUsersExist() {
                    const noUsersInPendingView = ctrl.activeView === 2 && userList.PendingUsers.length === 0;
                    const usersNotSearchedOrFiltered = ctrl.filtersCount === 0 && !ctrl.searchSettings.model;
                    return  usersNotSearchedOrFiltered && noUsersInPendingView;
                }

                function noDisabledUsersExist() {
                    const noUsersInDisabledView = ctrl.activeView === 3 && userList.DisabledUsers?.length === 0;
                    const usersNotSearchedOrFiltered = ctrl.filtersCount === 0 && !ctrl.searchSettings.model;
                    return  usersNotSearchedOrFiltered && noUsersInDisabledView;
                }

                function noActiveUsersExist() {
                    const noUsersInPendingView = ctrl.activeView === 1 && userList.Users.length === 0;
                    const usersNotSearchedOrFiltered = ctrl.filtersCount === 0 && !ctrl.searchSettings.model;
                    return  usersNotSearchedOrFiltered && noUsersInPendingView;
                }
                
                function applyFilters(users, pendingUsers, disabledUsers) {
                    const filteredUsers = searchFilter(users, ctrl.searchSettings.model);
                    const filteredPendingUsers = searchFilter(pendingUsers, ctrl.searchSettings.model);
                    const filteredDisabledUsers = searchFilter(disabledUsers, ctrl.searchSettings.model);
                    
                    ctrl.users = filteredUsers.slice(0, 50);
                    ctrl.pendingUsers = filteredPendingUsers.slice(0, 50);
                    ctrl.disabledUsers = filteredDisabledUsers.slice(0, 50);
                }

                function editUser(user) {
                    if (ctrl.userEditingAllowed) {
                        user.Selected = true;
                        UserManagementService.openUpdateUserPopup($stateParams.token, user.UserToken, updateUsersOnClose)
                    }
                }

                function createUser() {
                    if (ctrl.userCreationAllowed) {
                        UserManagementService.openCreateUserPopup($stateParams.token, userList.Users, updateUsersOnClose);
                    }
                }

                function updateActivityWidget(filterUsersResponse) {
                    const {TotalUsers, TotalActiveUsersLast30Days, TotalActiveUsersLast7Days, TotalUsersMatchingFilters} = filterUsersResponse;
                    ctrl.totalUsersCount = TotalUsers;
                    ctrl.activeLastWeekCount = TotalActiveUsersLast7Days;
                    ctrl.activeLastMonthCount = TotalActiveUsersLast30Days;
                    ctrl.filterMatchingUsersCount = TotalUsersMatchingFilters;
                }

                function getHeaderButtons() {
                    return [{
                        icon: 'info',
                        place: 'right',
                        onClick: openInfoPopup,
                        hidden: !ctrl.enableHowToGuide,
                    }, {
                        icon: 'filters',
                        activeIcon: 'filters-active',
                        place: 'right',
                        onClick: openFilters,
                        active: !!ctrl.filtersCount,
                    }];
                }

                function destroy() {
                    HeaderButtonsFactory.resetButtonsList();
                    ModuleSearchService.disableSearch();
                }

                function closeFilters() {
                    ctrl.showFilters = false;
                }
                
                function getManagementFilters() {
                    ctrl.showFilters = true;
                    ctrl.managementFilters = {
                        allowedDepartments: userList.AllowedDepartments,
                        filterModel: structuredClone(ctrl.filterModel),
                        onFiltered: (response, filterModel, filtersCount) => {
                            ctrl.filtersCount = filtersCount;
                            ctrl.filterModel = filterModel;
                            userList.Users = response.Users;
                            userList.PendingUsers = response.PendingUsers;
                            userList.DisabledUsers = response.DisabledUsers;
                            
                            applyFilters(response.Users, response.PendingUsers, response.DisabledUsers);
                            updateActivityWidget(response);
                            
                            HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                        },
                        managerViewEnabled: ctrl.settings.ActivityManagerViewEnabled
                    };
                }

                function openFilters() {
                    getManagementFilters();
                    const { allowedDepartments, filterModel, onFiltered, managerViewEnabled } = ctrl.managementFilters;
                    UserManagementService
                        .openFiltersPopup(allowedDepartments, filterModel, onFiltered, managerViewEnabled);
                }

                function openInfoPopup() {
                    HowToGuideService.renderHowToGuidePopup($stateParams.token);
                }
            }
        ]);
})();   