(function () {
    'use strict';

    angular.module('App')
        .component('contents', {
            template: require('./ContentComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$http', '$state', '$element', '$interval', '$timeout', 'TimeLocale',
                'NotificationFactory', 'Menu', 'ContentWrapperService', 'ReactionsService', 'ToastFactory', 'MentionService',
                'ContentCreateService', 'CookieService', 'HashtagsService', ContentController],
            bindings: {
                settings: '<',
                contents: '=?',
                categories: '<',
                navigationBadge: '<',
                allowCreation: '<',
                categoryToken: '<',
                showReadCount: '<',
                accountModuleToken: '<',
                openCreatePopup: '=',
                scrollToToken: '<',
                onContentRead: '<',
                allowPostPinning: '<',
                includeFuturePosts: '<',
                isRemindersEnabled: '<',
                isLiveTile: '<',
                openComments: '=?',
                nextOffset: '<',
                sortType: '<',
                contentLoading: '<',
                startDate: '<',
                endDate: '<',
                archivePostsOnly: '<',
                isSingleView: '<',
                isHashtagsOverview: '<',
                hashtagName: '<',
                morePostsToLoad: '<',
                onPostsChange: '&',
            }
        });

    function ContentController($scope, $http, $state, $element, $interval, $timeout, TimeLocale,
        NotificationFactory, Menu, ContentWrapperService, ReactionsService, ToastFactory,
        MentionService, ContentCreateService, CookieService, HashtagsService) {
        const ctrl = this;
        let destroyPostUpdateWatcher, contentItemsWatcher;

        ctrl.$onInit = init;
        ctrl.$onChanges = onChanges;
        ctrl.$onDestroy = destroy;
        ctrl.loadPosts = loadPosts;
        ctrl.paging = paging;
        ctrl.swipeContent = swipeContent;
        ctrl.readContent = readContent;
        ctrl.swipeCategory = swipeCategory;
        ctrl.createContent = createContent;
        ctrl.onRemoved = onRemoved;
        ctrl.onEdit = onEdit;
        ctrl.openReactionsViewer = openReactionsViewer;
        ctrl.updateBadges = updateBadges;
        ctrl.getCurrentLocale = ContentWrapperService.selectCurrentLocale;
        
        function init() {
            ctrl.loadingItems = false;
            ctrl.time = TimeLocale;
            ctrl.settings.accountModuleToken = ctrl.accountModuleToken;
            ctrl.morePostsToLoad = ctrl.morePostsToLoad || false;

            if (ctrl.openCreatePopup) {
                ctrl.createContent();
            }

            ContentWrapperService.resetContentIdsToExclude();

            const removeLoadingWatcher = $scope.$watch('ctrl.contentLoading', (contentLoading) => {
                if (contentLoading === false) {
                    ctrl.scrollToToken && scrollToContentItem(ctrl.scrollToToken);
                    removeLoadingWatcher();
                }
            })
            
            contentItemsWatcher = $scope.$watch('ctrl.contents', (contents) => {
                if (contents?.length && ctrl.settings.SwipeCategory && !ctrl.nextOffset) {
                    countReadPosts();
                }
            })

            destroyPostUpdateWatcher = $scope.$watch('[ctrl.sortType, ctrl.includeFuturePosts, ctrl.startDate, ctrl.endDate, ctrl.archivePostsOnly]', (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    updatePosts();
                }
            });

            ctrl.category = ctrl.categories?.find(category => category.ContentCategoryToken === ctrl.categoryToken)
        }

        function onChanges(changes) {
            if (!changes.sortType?.isFirstChange() &&
                changes.sortType?.previousValue !== changes.sortType?.currentValue) {
                updatePosts();
            }
        }

        function updatePosts() {
            CookieService.set('sortTypeId-' + $state.params.token, ctrl.sortType);
            ContentWrapperService.resetContentIdsToExclude();
            loadPosts();
        }

        function createContent() {
            $http.get('/ContentModule/GetContentCreatePage/' + $state.params.token + '?category=' + ($state.params.extra || ctrl.categoryToken))
                .then(contentCreatePage => {
                    contentCreatePage.data.navigationBadge = ctrl.navigationBadge;
                    contentCreatePage.data.mode = 'create';
                    ContentCreateService.openCreatePopup(loadPosts, contentCreatePage.data, ctrl.accountModuleToken)
                });
        }

        function paging() {
            if (ctrl.isHashtagsOverview) {
                getHashtagOverviewPosts();
            } else {
                loadPosts(10, true)
            }
        }

        function getHashtagOverviewPosts() {
            if (!ctrl.loadingItems && ctrl.morePostsToLoad) {
                ctrl.loadingItems = true;

                HashtagsService.getContentPostsWithHashtag(
                    ctrl.hashtagName, 
                    ctrl.contents.length
                ).then(data =>{
                    ctrl.contents.push(...data.Posts);
                    ctrl.morePostsToLoad = data.MorePosts;
                })
                .catch(() => {
                    ctrl.morePostsToLoad = false;
                })
                .finally(() => {
                    $timeout(() => ctrl.loadingItems = false)
                });
            }
        }

        function loadPosts(limit, isPaging) {
            if (ctrl.loadingItems === true) return;
            ctrl.loadingItems = true;
            
            if (!isPaging) {
                ContentWrapperService.resetContentIdsToExclude();
                ctrl.contents = [];
                document.querySelector('html').scrollTop = 0;
            }
            
            return ContentWrapperService.getContents({
                token: ctrl.accountModuleToken,
                categoryToken: ctrl.categoryToken,
                offset: isPaging ? ctrl.nextOffset : 0,
                limit: limit,
                sortType: ctrl.sortType,
                contentIdsToExclude: ContentWrapperService.getContentIdsToExclude(),
                startTime: ctrl.startDate,
                endTime: ctrl.endDate,
                includeFuturePosts: ctrl.includeFuturePosts,
                archivePostsOnly: ctrl.archivePostsOnly
            }).then(data => {
                if (data.Items) {
                    data.Items = data.Items.map(item => setCurrentCulture(item));
                }
                
                if (isPaging) {
                    ctrl.contents = [
                        ...ctrl.contents,
                        ...data.Items.map(item => parseMentions(item))
                    ];
                } else {
                    ctrl.contents = data.Items.map(item => parseMentions(item));
                }
                ctrl.nextOffset = data.NextOffset
                if (!ctrl.nextOffset) {
                    countReadPosts();
                }
            }).catch(() => {
                ctrl.nextOffset = null;
                ToastFactory.error('Could not load more as an error occured');
            }).finally(() => $timeout(() => ctrl.loadingItems = false));
        }
        
        function setCurrentCulture(item) {
            item.TranslatableEntity._currentCulture = ContentWrapperService.selectCurrentLocale(item.TranslatableEntity);

            if (item.ContentCategoryTranslatableEntity) {
                item.ContentCategoryTranslatableEntity._currentCulture = ContentWrapperService.selectCurrentLocale(item.ContentCategoryTranslatableEntity);
            }
        
            return item
        }
        
        function parseMentions(item) {
            if (item.BodyText) {
                item.BodyText = parse(item.BodyText);
            }

            if (item.TranslatableEntity?.LocalizedLocale?.BodyText) {
                item.TranslatableEntity.LocalizedLocale.BodyText = parse(item.TranslatableEntity.LocalizedLocale.BodyText);
                
                if (item.TranslatableEntity.LocalizedAlternativeLocale?.BodyText) {
                    item.TranslatableEntity.LocalizedAlternativeLocale.BodyText = parse(item.TranslatableEntity.LocalizedAlternativeLocale.BodyText);
                }
            }

            function parse(text) {
                return MentionService.parseMentionsInText(
                    text,
                    item.TaggedUsers,
                    item.TaggedDepartments,
                    item.TaggedUserGroups,
                    false
                )
            }

            return item
        }

        function swipeCategory() {
            const contentToSwipe = [];
            ctrl.contents.forEach(content => {
                if (!content.IsSwiped) {
                    contentToSwipe.push(content.ContentId);
                    setSwiped(content.ContentId);
                }
            });

            return $http.post('/ContentModule/SwipeContentCategory', {
                contentIds: contentToSwipe
            }).then(resp => {
                const categoryToken = ctrl.settings.IsCategoryViewEnabled ? null : ctrl.categoryToken;
                Menu.removeActivity(resp.data.Subtract, "content", ctrl.accountModuleToken, categoryToken);
                Menu.removeLandingBadge(resp.data.Subtract, ctrl.navigationBadge.AccountModuleId, ctrl.navigationBadge.ModuleId, ctrl.navigationBadge.ModuleItemId);
            });
        }

        function swipeContent(content, isSingleView) {
            const alreadySwiped = isSingleView && ctrl.contents ? ctrl.contents[0].IsSwiped : content.IsSwiped;

            if (alreadySwiped) {
                return unswipeContentItem(content);
            }

            return swipeContentItem(content);
        }


        function swipeContentItem(content) {
            return $http.post('/ContentModule/SwipeContent', {
                contentId: content.ContentId
            }).then(resp => {
                ContentWrapperService.addContentToExclude(content.ContentId);
                setSwiped(content.ContentId);
                updateBadges(resp.data, content)
            })
        }


        function unswipeContentItem(content) {
            return $http.post('/ContentModule/Unswipe', {
                token: content.ContentToken
            }).then(resp => {
                ContentWrapperService.removeContentIdFromExclude(content.ContentId);
                content.IsSwiped = false;
                updateBadges(resp.data, content);
            })
        }

        function updateBadges(data, content) {
            const contentCategoryToken = ctrl.settings.IsCategoryViewEnabled ? null : content.ContentCategoryToken;

            data.Subtract > 0 ? decreaseBagdes(data.Subtract, contentCategoryToken) : increaseBagdes(data, contentCategoryToken);

            NotificationFactory.markNotifications(data.NotificationStatus);
        }

        function decreaseBagdes(subtract, contentCategoryToken) {
            Menu.removeActivity(subtract, "content",  ctrl.accountModuleToken, contentCategoryToken);
            Menu.removeLandingBadge(subtract, ctrl.navigationBadge.AccountModuleId, ctrl.navigationBadge.ModuleId, ctrl.navigationBadge.ModuleItemId);
        }

        function increaseBagdes({AddBadge, NavigationBadge}, contentCategoryToken) {
            if (!AddBadge) return;

            Menu.addActivity(1, "content", ctrl.accountModuleToken, contentCategoryToken);
            Menu.addLandingBadge(1, NavigationBadge.AccountModuleId, NavigationBadge.ModuleId, NavigationBadge.ModuleItemId);
        }

        function readContent(content) {
            $http.post('/ContentModule/MarkAsSeen', {
                contentId: content.ContentId
            }).then(resp => {
                ContentWrapperService.addContentToExclude(content.ContentId);
                updateBadges(resp.data, content)
            })
        }

        function scrollToContentItem(contentToken) {
            const interval = $interval(() => {
                const element = angular.element($element).find('[data-token=' + contentToken + ']');
                if (element.length) {
                    angular.element('html').scrollTop(element.offset().top - 60);
                    $interval.cancel(interval);
                }
            }, 100);
        }

        function openReactionsViewer(token) {
            ReactionsService.openReactionsViewer(token, ctrl.settings?.AllowExtendedReactions, 'ContentModule');
        }

        function setSwiped(contentId) {
            ctrl.contents = ctrl.contents.map(item => {
                if (item.ContentId === contentId) {
                    return {...item, isSwiped: !item.isSwiped}
                }
                return item;
            })
        }

        function onRemoved(badgeSubtractCount, post) {
            ctrl.contents = ctrl.contents.filter(item => item.ContentToken !== post.ContentToken);
            updateBadges({Subtract: badgeSubtractCount}, post);
            if (ctrl.isHashtagsOverview) {
                ctrl.onPostsChange && ctrl.onPostsChange();
            }
        }

        function onEdit(data) {
            data.mode = 'edit';
            ContentCreateService.openCreatePopup(loadPosts, data, ctrl.accountModuleToken);
        }

        function countReadPosts() {
            ctrl.isCategoryRead = ctrl.contents.every(content => content.IsSwiped);
        }

        function destroy() {
            destroyPostUpdateWatcher && destroyPostUpdateWatcher();
            contentItemsWatcher && contentItemsWatcher();
        }
    }
})();
