(() => {
    angular
        .module('App')
        .component('userManagementUpdate', {
            template: require('./UserManagementUpdate.html'),
            controllerAs: 'ctrl',
            bindings: {
                accountModuleToken: '<',
                userToken: '<',
                updateUsersOnClose: '<'
            },
            controller: ['$rootScope', '$scope', '$stateParams', '$element',
                '$translate', '$timeout', '$http', 'ConfirmPopupService', 'events', 
                'Page', 'ToastFactory', 'ProfileSettingsService', 'UserManagementService', 
                UserManagementUpdatePopupController]
        })

    function UserManagementUpdatePopupController($rootScope, $scope, $stateParams, $element,
                $translate, $timeout, $http, ConfirmPopupService, events, 
                Page, ToastFactory, ProfileSettingsService, UserManagementService) {
        const ctrl = this;
        let userChangeWatcher, popup;

        ctrl.close = close;
        ctrl.onOpen = onOpen;
        ctrl.onClose = onClose;
        ctrl.onPopupRegistered = onPopupRegistered;
        ctrl.saveUser = saveUser;
        ctrl.sendLoginInfo = sendLoginInfo;
        ctrl.deleteUser = deleteUser;
        ctrl.approveUser = approveUser;
        ctrl.showDeclineUserPopup = showDeclineUserPopup;
        ctrl.isLoading = true;
        ctrl.showDisableUserPopup = showDisableUserPopup;
        ctrl.enableUser = enableUser;
        ctrl.hideErrorMsg = hideErrorMsg;
        ctrl.closeAfterSave = closeAfterSave;
        ctrl.clearToasts = clearToasts;
        ctrl.jobTitleChanged = jobTitleChanged;
        ctrl.usersListUpdateNeeded = false;
        ctrl.reinitUserGroupSelector = false;
        ctrl.isProcessing = false;

        // ------- Open & Init -------
        function onPopupRegistered(popup) {
            popup.open();
        }

        function onOpen(data, popupCtrl) {
            popup = popupCtrl;

            UserManagementService.getUser(ctrl.userToken, $stateParams.token).then((userData) => {
                UserManagementService.getSettings($stateParams.token).then((settingsData) => {
                    const combinedUserSettings = {
                        userData,
                        settingsData
                    };

                    setUserEditSettings(combinedUserSettings);
                }).finally(() => ctrl.isLoading = false);
            });
        }

        function clearToasts() {
            ToastFactory.clear();
        }

        function setUserEditSettings(userConfigurationSettings) {
            // Reset validation data
            ctrl.validationData = [];
            initUserChangeWatcher();

            // Hook up to for submit event
            const userSubmit = $rootScope.$on('GlobalFormSubmit', function () {
                ctrl.validationData = UserManagementService.validate(ctrl.renderModel, ctrl.settings.Settings);
                if (ctrl.validationData.length === 0) {
                    saveUser(ctrl.closeAfterSave);
                }
            });
            const userCancel = $rootScope.$on('GlobalFormCancel', function () {
                Page.stateGoPrevious('userManagement', $stateParams.token);
            });
            $scope.$on('$destroy', userSubmit);
            $scope.$on('$destroy', userCancel);

            ctrl.phoneCodes = [];
            ctrl.renderModel = {
                UserToken: null,
                AccountModuleToken: null,
                Name: '',
                TitleObj: '',
                Alias: '',
                PhoneObj: {
                    number: '',
                    country: 1,
                },
                PhoneCountryId: 1,
                PhoneCode: 45,
                Email: '',
                UserGroups: [],
                AdditionalDepartments: []
            };

            // handle custom user alias label (can be defined under UserModuleSettings)
            if (!(userConfigurationSettings.settingsData.data.UserAliasLabel)) {
                $translate('USER_MANAGEMENT.ALIAS').then(function (translation) {
                    userConfigurationSettings.settingsData.data.UserAliasLabel = translation;
                });
            }

            // add "formatet" array with job titles
            ctrl.settings = userConfigurationSettings.settingsData.data;
            ctrl.User = userConfigurationSettings.userData.data.User;
            ctrl.AllowDelete = userConfigurationSettings.userData.data.AllowDelete;
            ctrl.isDisabled = ctrl.User.ActivityStatus === 9;
            ctrl.headerTitle = ctrl.User.ActivityStatus === 6 
                ? 'USER_MANAGEMENT.APPROVE_TITLE' 
                : 'USER_MANAGEMENT.UPDATE_TITLE';

            ctrl.departmentSettings = {
                departments: userConfigurationSettings.settingsData.data.AllowedDepartments
            };

            if (ctrl.settings.Settings.PredefinedJobTitlesEnabled) {
                ctrl.titleOptions = getTitleOptions();

                if (isTitleSelected()) {                
                    ctrl.userGroupSettings = setUserGroupsSettingsWithJobTitles();
                } else {
                    setUserGroupsSettingsWithJobTitles(false, true)
                }
            } 
            
            if (!ctrl.userGroupSettings) {
                ctrl.userGroupSettings = setUserGroupsSettingsNoJobTitles();
            }

            // Map properties to renderModel
            ctrl.renderModel.UserToken = ctrl.User.UserToken;
            ctrl.renderModel.AccountModuleToken = $stateParams.token;
            ctrl.renderModel.Name = ctrl.User.Name;
            ctrl.renderModel.TitleObj = getUserTitle();
            ctrl.renderModel.Alias = ctrl.User.Alias;
            ctrl.renderModel.Email = ctrl.User.Email;
            ctrl.renderModel.PhoneCountryId = ctrl.User.PhoneCountryId;
            ctrl.renderModel.PhoneObj = { number: ctrl.User.Phone, country: ctrl.User.PhoneCountryId };
            ctrl.renderModel.PhoneCode = ctrl.User.PhoneCode;
            ctrl.renderModel.UserGroups = ctrl.User.SelectedUserGroups;
            ctrl.renderModel.DepartmentId = ctrl.User.DepartmentId;
            ctrl.renderModel.AdditionalDepartments = ctrl.User.AdditionalDepartments;

            ProfileSettingsService.getPhoneCodes().then(phoneCodes => {
                ctrl.phoneCodes = phoneCodes;           
            })

            // Listen for events
            $scope.$on(events.DEPARTMENT_CHANGED, function () {
                Page.stateGoBack('userManagement', $stateParams.token);
            });
        }

        function initUserChangeWatcher() {
            let initializing = true;

            userChangeWatcher = $scope.$watch(() => ctrl.renderModel,
                () => {
                    if (initializing) {
                        $timeout(() => {
                            initializing = false;
                        });
                    } else {
                        ctrl.isChanged = true;
                    }
                }, true)
        }

        function getTitleOptions() {
            return ctrl.settings.PredefinedJobTitleOptionsList.map((
                (option, index) => {
                    const isCurrentTitle = ctrl.User.Title?.trim() === option.JobTitle;
                    return {
                        id: index,
                        name: option.JobTitle,
                        value: option.JobTitle,
                        selected: isCurrentTitle && isJobTitleValid(option.JobTitle)
                    }
                }
            ))
        }

        function isTitleSelected() {
            return ctrl.titleOptions.find(opt => opt.selected);
        }

        function getUserTitle() {
            if (!ctrl.settings.Settings.PredefinedJobTitlesEnabled) {
                return ctrl.User.Title;
            }

            return ctrl.titleOptions.find(titleObj => titleObj.selected);
        }

        function setUserGroupsSettingsNoJobTitles() {
            const settings = ctrl.settings.Settings;
            const allowedUserGroups = ctrl.settings.AllowedUserGroups;
            const importedUserGroups = ctrl.User.ImportedUserGroups;
            
            const selectableRoles =
                settings.SelectableRoles?.length
                    ? settings.SelectableRoles
                    : settings.DefaultRoleList;

            let lockedGroups = [
                ...(importedUserGroups || []),
                ...(settings.DefaultRoleList || [])
            ];

            lockedGroups = lockedGroups.filter(value => ctrl.User.SelectedUserGroups?.includes(value));

            return {
                usergroups: allowedUserGroups,
                locked: lockedGroups,
                selectableRoles: selectableRoles
            };
        }

        function setUserGroupsSettingsWithJobTitles(titleChanged, invalidPreviousTitle) {
            const settings = ctrl.settings.Settings;
            const allowedUserGroups = ctrl.settings.AllowedUserGroups;
            const importedUserGroups = ctrl.User.ImportedUserGroups;

            if (invalidPreviousTitle) {
                if (!ctrl.lockedGroupsByPermissions) {
                    setLockedByPermissionsGroups(allowedUserGroups, [], settings.SelectableRoles);
                }

                return {
                    usergroups: allowedUserGroups,
                    locked: [...importedUserGroups || []],
                    selectableRoles: settings.SelectableRoles
                };
            }

            const predefinedOptions = ctrl.settings.PredefinedJobTitleOptionsList;
            const userTitle = getUserTitle();
            const titleGroupIds = predefinedOptions.find(option => option.JobTitle === userTitle.name)?.UserGroupIds || [];

            const selectableRoles =
                settings.SelectableRoles?.length
                    ? settings.SelectableRoles
                    : titleGroupIds;

            let lockedGroups =  [...new Set([
                ...(importedUserGroups || []),
                ...(titleGroupIds || [])
            ])];

            if (ctrl.lockedGroupsByPermissions?.length) {
                lockedGroups = [...new Set([
                    ...lockedGroups,
                    ...ctrl.lockedGroupsByPermissions
                ])];
            }

            if (titleChanged) {
                resetSelectorToLockedGroups(lockedGroups);
            }

            lockedGroups = lockedGroups.filter(value => ctrl.User.SelectedUserGroups?.includes(value));
            
            if (!ctrl.lockedGroupsByPermissions) {
                setLockedByPermissionsGroups(allowedUserGroups, lockedGroups, selectableRoles);
            }

            return {
                usergroups: allowedUserGroups,
                locked: lockedGroups,
                selectableRoles: selectableRoles
            };
        }

        function setLockedByPermissionsGroups(allowedUserGroups, lockedGroups, selectableRoles) {
            const settings = {
                usergroups: allowedUserGroups,
                locked: lockedGroups,
                selectableRoles: selectableRoles,
                selected: ctrl.User.SelectedUserGroups
            }

            $http.post('/Directives/UserGroupSelector/GetSelector', settings)
                .then(resp => {
                    const lockedGroups = resp.data.Selected;

                    if (isJobTitleValid(getUserTitle()?.name)) {
                        const userGroups = ctrl.settings.PredefinedJobTitleOptionsList.find(option => option.JobTitle === getUserTitle().name)?.UserGroupIds || [];
                        ctrl.lockedGroupsByPermissions = lockedGroups.filter(group => !userGroups.includes(group.UserGroupId)).map(group => group.UserGroupId);
                    } else {
                        ctrl.lockedGroupsByPermissions = lockedGroups.map(group => group.UserGroupId);
                    }
                })
        }

        function isJobTitleValid(selectedTitle) {
            const matchingTitle = ctrl.settings.PredefinedJobTitleOptionsList.find(option => {
                return option.JobTitle === selectedTitle
            })
            
            if (!matchingTitle) return false;
            
            const isAllTitleGroupsSelected = matchingTitle.UserGroupIds?.every(id => ctrl.User.SelectedUserGroups?.includes(id));
            return isAllTitleGroupsSelected;
        }

        // ------- Job title changes -------
        function jobTitleChanged() {
            ctrl.hideErrorMsg('Title');
            ctrl.hideErrorMsg('UserGroups');
            if (!ctrl.settings.Settings.PredefinedJobTitlesEnabled) return;
            
            $timeout(() => {
                ctrl.userGroupSettings = setUserGroupsSettingsWithJobTitles(true);
            })
        }

        function resetSelectorToLockedGroups(lockedGroups) {
            ctrl.User.SelectedUserGroups = lockedGroups;
            ctrl.renderModel.UserGroups = lockedGroups;

            if (isLockedGroupsNotChanged(lockedGroups, ctrl.userGroupSettings?.locked)) {
                refreshUserGroupSelector();
            }
        }

        function refreshUserGroupSelector() {
            ctrl.reinitUserGroupSelector = !ctrl.reinitUserGroupSelector;
        }

        function isLockedGroupsNotChanged(newLockedGroups, oldLockedGroups) {
            if (newLockedGroups?.length !== oldLockedGroups?.length) return false;
            if (newLockedGroups.length === 0) return true;
            return newLockedGroups.every((value, index) => value === oldLockedGroups[index]);
        }

        // ------- Send login info -------
        function sendLoginInfo() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;

                const opts = {
                    title: 'USER_MANAGEMENT.SEND_LOGIN_INFO_CONFIRM.TITLE',
                    message: 'USER_MANAGEMENT.SEND_LOGIN_INFO_CONFIRM.TEXT',
                    yesText: 'USER_MANAGEMENT.SEND_LOGIN_INFO_CONFIRM.BUTTON_TEXT',
                    class: 'user-management-confirm-popup',
                    onClose: () => ctrl.isSaving = false,
                    isAlert: true
                };

                ConfirmPopupService.open(opts).then(() => {
                    Page.startLoading();

                    $http.post('/UserManagement/SendInfo', {
                        token: ctrl.renderModel.UserToken,
                        accModuleToken: ctrl.renderModel.AccountModuleToken
                    }).then(() => {
                        ToastFactory.success('USER_MANAGEMENT.SEND_LOGIN_INFO.TOAST_MESSAGE');
                    }).catch((errorBody) => {
                        ToastFactory.error(errorBody.data[0].Message);
                    }).finally(() => {
                        ctrl.isSaving = false;
                        Page.stopLoading();
                    });

                }).finally(() => {
                    ctrl.isSaving = false
                });
            }
        }

        // ------- Enable & disable -------
        function enableUser() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;

                UserManagementService.enableUser(ctrl.renderModel.UserToken).then(() => {
                    ctrl.isDisabled = false;
                    ctrl.usersListUpdateNeeded = true;
                    ToastFactory.success(
                        'USER_MANAGEMENT.ENABLE_SUCCESSFUL', 
                        null,
                        { variable: { text: ctrl.User.Name, type: 'front' } }
                    );
                }).catch( () => {
                    ToastFactory.error('USER_MANAGEMENT.ENABLE_UNSUCCESSFUL');
                }).finally(() => ctrl.isSaving = false);
            }
        }

        function disableUser() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;

                UserManagementService.disableUser(ctrl.renderModel.UserToken)
                .then(() => {
                    ctrl.isDisabled = true;
                    ctrl.usersListUpdateNeeded = true;
                    ToastFactory.error(
                        'USER_MANAGEMENT.DISABLE_SUCCESSFUL', 
                        null,
                        { variable : { text: ctrl.User.Name, type: 'front' } }, 
                    );
                }).catch( () => {
                    ToastFactory.error('USER_MANAGEMENT.DISABLE_UNSUCCESSFUL');
                }).finally(() => ctrl.isSaving = false);
            }
        }

        function showDisableUserPopup() {
            const opts = {
                title: 'USER_MANAGEMENT.DISABLE_USER_CONFIRMATION.TITLE',
                message: 'USER_MANAGEMENT.DISABLE_USER_CONFIRMATION.MESSAGE',
                yesText: 'USER_MANAGEMENT.DISABLE_USER_CONFIRMATION.YES',
                noText: 'USER_MANAGEMENT.DISABLE_USER_CONFIRMATION.NO',
                class: 'user-management-confirm-popup'
            };

            ConfirmPopupService.open(opts).then(() => {
                disableUser()
            })
        }

        // ------- Delete -------
        function deleteUser() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;

                const opts = {
                    title: 'USER_MANAGEMENT.DELETE_USER_CONFIRM.TITLE',
                    message: 'USER_MANAGEMENT.DELETE_USER_CONFIRM.MESSAGE',
                    yesText: 'DELETE',
                    noText: 'KEEP',
                    class: 'user-management-confirm-popup',
                    onClose: () => ctrl.isSaving = false
                };

                ConfirmPopupService.open(opts).then(function () {
                    // Confirmed
                    Page.startLoading();

                    $http.get('/UserManagement/DeleteUser?token=' + ctrl.User.UserToken +
                        '&accModuleToken=' + $stateParams.token)
                        .then(response => {
                            if (response.data) {

                                ToastFactory.error(
                                    'USER_MANAGEMENT.DELETED.TOAST_MESSAGE',
                                    'USER_MANAGEMENT.DELETED.TOAST_TITLE',
                                    { variable: { text: ctrl.User.Name , type: 'front' } }
                                );

                                ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.User);
                                ctrl.close();
                            } else {
                                ToastFactory.error('ERROR.GENERAL');
                            }
                        }).catch(() => {
                            ToastFactory.error('ERROR.GENERAL');
                        })
                }).finally (() => {
                    Page.stopLoading();
                    ctrl.isSaving = false;
                });
            }
        }

        // ------- Save & Validate -------
        function saveUser(afterSaveCallback) {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;
                Page.startLoading();
                formatRenderModel();

                $http.post('/UserManagement/SaveUser', ctrl.renderModel)
                    .then(() => {
                        if (afterSaveCallback) {
                            Promise.resolve(afterSaveCallback())
                                .finally(() => {
                                    ctrl.isSaving = false;
                                    Page.stopLoading();
                                })
                        }
                        else {
                            ctrl.isSaving = false;
                            Page.stopLoading();
                        }
                    }).catch((response) => {
                        if (response.status === 400) {
                            ctrl.validationData = response.data;
                            ToastFactory.error('ERROR.FORM_VALIDATION');
                        } else {
                            ToastFactory.error('ERROR.GENERAL');
                        }
                        ctrl.isSaving = false;
                        Page.stopLoading();
                    });
            }
        }

        function hideErrorMsg(propertyName) {
            ctrl.validationData = ctrl.validationData.filter(error => error.Name !== propertyName);
        }

        function formatRenderModel() {
            formatPhoneNumber();
            formatTitle();

            function formatPhoneNumber() {
                ctrl.renderModel.PhoneCountryId = ctrl.renderModel.PhoneObj.country;
                ctrl.renderModel.Phone = ctrl.renderModel.PhoneObj.number;
            }
    
            function formatTitle() {
                if (!ctrl.settings.Settings.PredefinedJobTitlesEnabled) {
                    ctrl.renderModel.Title = ctrl.renderModel.TitleObj;
                    return;
                }
    
                ctrl.renderModel.Title = ctrl.renderModel.TitleObj?.value || '';
            }
        }

        //  ------- Approve & Decline -------
        function approveUser() {
            ctrl.isProcessing = true;
            return UserManagementService.approveUser(ctrl.User.UserToken)
                .then(() => {
                    ToastFactory.success(
                        'USER_MANAGEMENT.APPROVED.TOAST_MESSAGE',
                        'USER_MANAGEMENT.APPROVED.TOAST_TITLE',
                        { variable: { text: ctrl.User.Name, type: 'end' } }
                    );
                }).finally(() => {
                    ctrl.isProcessing = false;
                    ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.User);
                    ctrl.close();
                });
        }

        function showDeclineUserPopup() {
            const opts = {
                title: 'USER_MANAGEMENT.DECLINE_APPLICATION.TITLE',
                message: 'USER_MANAGEMENT.DECLINE_APPLICATION.TEXT',
                yesText: 'USER_MANAGEMENT.DECLINE',
                noText: 'CANCEL',
                class: 'user-management-confirm-popup'
            }

            ConfirmPopupService.open(opts).then(()=> {
                declineUser();
            })
        }

        function declineUser() {
            if (!ctrl.isSaving) {
                ctrl.isSaving = true;

                UserManagementService.declineUser(ctrl.User.UserToken)
                    .then(() => {
                        ToastFactory.error(
                            'USER_MANAGEMENT.DECLINED.TOAST_MESSAGE',
                            null,
                            { variable: { text: ctrl.User.Name , type: 'front' }}
                        )})
                    .finally(() => {
                        ctrl.isSaving = false;
                        ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.User);
                        ctrl.close();
                    });
            }
        }

        // ------- Close -------
        function closeAfterSave() {
            ToastFactory.success('USER_MANAGEMENT.USER_SAVED');
            ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.User);
            ctrl.close(); 
        }

        function close() {
            ctrl.onClose && ctrl.onClose();
            ctrl.isChanged = false;
            popup.remove();
            $element.remove();
            userChangeWatcher && userChangeWatcher();
        }

        function onClose() {
            if (ctrl.usersListUpdateNeeded) {
                ctrl.updateUsersOnClose && ctrl.updateUsersOnClose(ctrl.User);
            }

            $rootScope.disableVisibilitychange = false;
        }
    }
})();