(() => {
        'use strict';

        angular.module('App')
            .component('profileSettingsPersonalInformation', {
                template: require('./ProfileSettingsPersonalInformationComponent.tpl.html'),
                controller: ['ProfileSettingsService', '$rootScope', '$translate', 'ConfirmPopupService', 'ToastFactory',
                    'Page', 'AttachmentsViewerService', 'ActionSheetService', '$element', 'ResponsiveService', '$q', ProfileSettingsPersonalInformationComponentController],
                controllerAs: 'ctrl',
                bindings: {
                    canGoBackRequester: '='
                }
            });

        function ProfileSettingsPersonalInformationComponentController(ProfileSettingsService, $rootScope, $translate, ConfirmPopupService, ToastFactory,
                                                                       Page, AttachmentsViewerService, ActionSheetService, $element, ResponsiveService, $q) {

            var ctrl = this,
                screenResizeWatcher,
                submitListener = null,
                cancelListener = null;

            ctrl.$onInit = onInit;
            ctrl.$onDestroy = onDestroy;

            ctrl.openProfileImage = openProfileImage;
            ctrl.onImageEdit = onImageEdit;
            ctrl.onImageUploaded = onImageUploaded;
            ctrl.saveChanges = saveChanges;

            ctrl.overrideHasChanges = false;

            function onInit() {
                ctrl.model = null;
                ctrl.imageHasChanged = false;
                ctrl.isSavingChanges = false;
                ctrl.originalProfile = null;
                ctrl.isLoadingProfileData = true;
                ctrl.phoneCodes = [];

                ctrl.canGoBackRequester = canGoBack;

                ctrl.isDesktop = ResponsiveService.isDesktop();
                screenResizeWatcher = ResponsiveService.watch(() => {
                    ctrl.isDesktop = ResponsiveService.isDesktop();
                    screenSizeChange();
                })

                ProfileSettingsService.getFullProfile().then(res => {
                    ctrl.originalProfile = res;

                    ProfileSettingsService.getPhoneCodes().then(phoneCodes => {
                        ctrl.phoneCodes = phoneCodes;
                        initForm(res);
                        screenSizeChange();
                        ctrl.isLoadingProfileData = false;
                    }).catch(() => {
                        ctrl.isLoadingProfileData = false;
                    });
                }).catch(() => {
                    ctrl.isLoadingProfileData = false;
                });
            }

            function onDestroy() {
                if (submitListener) {
                    submitListener();
                }

                if (cancelListener) {
                    cancelListener();
                }

                if (screenResizeWatcher) {
                    screenResizeWatcher();
                }
            }

            function initForm(profile) {
                ctrl.defaultImage = profile.DefaultImage;
                ctrl.hasBio = profile.EnableBio;

                ctrl.model = {
                    image: profile.Image,
                    name: profile.Name,
                    title: profile.Title,
                    bio: profile.Bio,
                    email: profile.Email,
                    secondaryEmail: profile.SecondaryEmail,
                    phone: {
                        number: profile.PhoneNumber.Phone,
                        country: profile.PhoneNumber.CountryId
                    },
                    businessPhone: {
                        number: profile.BusinessPhoneNumber.Phone,
                        country: profile.BusinessPhoneNumber.CountryId
                    },
                    customFields: [],
                    enableTitleEdit: profile.EnableTitleEdit,
                    enableBioEdit: profile.EnableBioEdit,
                    enablePhoneEdit: profile.EnablePhoneEdit,
                    enableBusinessPhoneEdit: profile.EnableBusinessPhoneEdit,
                    enableEmailEdit: profile.EnableEmailEdit,
                    enableSecondaryEmailEdit: profile.EnableSecondaryEmailEdit
                };

                if (profile.UserCustomFields && profile.UserCustomFields.length) {
                    profile.UserCustomFields.forEach(o => {
                        if (o.CanEdit) {
                            ctrl.model.customFields.push({
                                id: o.UserCustomFieldId,
                                title: o.Title,
                                value: o.Value
                            });
                        }
                    });
                }

                submitListener = $rootScope.$on('GlobalFormSubmit', () => {
                    saveChanges();
                });
                cancelListener = $rootScope.$on('GlobalFormCancel', () => {
                    goBack();
                });
            }

            function screenSizeChange() {
                $rootScope.ShowFormSubmit = !ctrl.isDesktop;
            }

            function saveChanges() {
                if (ctrl.isSavingChanges) {
                    return;
                }

                var imageId = null;
                if (ctrl.model.image) {
                    imageId = ctrl.model.image.MediaId;
                }

                ctrl.isSavingChanges = true;
                ProfileSettingsService.updateBaseProfile({
                    name: ctrl.model.name,
                    title: ctrl.model.title,
                    bio: ctrl.model.bio,
                    email: ctrl.model.email,
                    secondaryEmail: ctrl.model.secondaryEmail,
                    phone: ctrl.model.phone.number,
                    phoneCountryId: ctrl.model.phone.country,
                    businessPhone: ctrl.model.businessPhone.number,
                    businessPhoneCountryId: ctrl.model.businessPhone.country,
                    imageId: imageId,
                    userCustomFields: mapCustomFieldsForSaving()
                }).then(() => {
                    ToastFactory.success('PROFILE.SETTINGS.CHANGES_SAVED');
                    ctrl.overrideHasChanges = true;
                    Page.triggerBackButton();
                }).catch(res => {
                    ctrl.isSavingChanges = false;
                    if (res && res.length > 0) {
                        ToastFactory.error(res[0]);
                    } else {
                        ToastFactory.error('ERROR.GENERAL');
                    }
                });
            }

            function mapCustomFieldsForSaving() {
                var result = [];

                ctrl.model.customFields.forEach(f => {
                    if (customFieldHasChanges(f)) {
                        result.push({
                            UserCustomFieldId: f.id,
                            Value: f.value
                        });
                    }
                });

                return result;
            }

            function canGoBack() {
                var d = $q.defer();

                if (hasChanges() && !ctrl.overrideHasChanges) {
                    confirmClose(() => {
                        d.resolve();
                    });
                } else {
                    d.resolve();
                }

                return d.promise;
            }

            function goBack() {
                if (hasChanges()) {
                    confirmClose();
                    return;
                }

                Page.triggerBackButton();
            }

            function hasChanges() {
                var hasCustomFieldChanges = false;
                if (ctrl.model.customFields.length) {
                    ctrl.model.customFields.forEach(o => {
                        if (customFieldHasChanges(o)) {
                            hasCustomFieldChanges = true;
                        }
                    });
                }

                return hasCustomFieldChanges ||
                    ctrl.imageHasChanged ||
                    ctrl.model.name !== ctrl.originalProfile.Name ||
                    ctrl.model.title !== ctrl.originalProfile.Title ||
                    ctrl.model.bio !== ctrl.originalProfile.Bio ||
                    ctrl.model.email !== ctrl.originalProfile.Email ||
                    ctrl.model.secondaryEmail !== ctrl.originalProfile.SecondaryEmail ||
                    ctrl.model.phone.number !== ctrl.originalProfile.PhoneNumber.Phone ||
                    ctrl.model.phone.country !== ctrl.originalProfile.PhoneNumber.CountryId ||
                    ctrl.model.businessPhone.number !== ctrl.originalProfile.BusinessPhoneNumber.Phone ||
                    ctrl.model.businessPhone.country !== ctrl.originalProfile.BusinessPhoneNumber.CountryId
                    ;
            }

            function customFieldHasChanges(field) {
                var originalField = ctrl.originalProfile.UserCustomFields.find(f => f.UserCustomFieldId === field.id);
                if (originalField && originalField.Value !== field.value) {
                    return true;
                }
                return false;
            }

            function confirmClose(callback) {
                $translate([
                    'PROFILE.UNSAVED_CHANGES.TITLE',
                    'PROFILE.UNSAVED_CHANGES.MESSAGE',
                    'PROFILE.UNSAVED_CHANGES.DISCARD',
                    'CANCEL'
                ]).then((res) => {
                    ConfirmPopupService.open({
                        title: res['PROFILE.UNSAVED_CHANGES.TITLE'],
                        message: res['PROFILE.UNSAVED_CHANGES.MESSAGE'],
                        yesText: res['PROFILE.UNSAVED_CHANGES.DISCARD'],
                        noText: res['CANCEL'],
                        icon: null
                    }).then(() => {
                        ctrl.overrideHasChanges = true;
                        if (callback) {
                            callback();
                        } else {
                            Page.triggerBackButton();
                        }
                    });
                });
            }

            function openProfileImage() {
                if (!ctrl.model.image) {
                    return;
                }

                AttachmentsViewerService.openViewerWithMedia([ctrl.model.image]);
            }

            function onImageEdit($event) {
                const editImageActionSheet = ActionSheetService.create([
                    {
                        text: 'PROFILE.SETTINGS.IMAGE.EDIT_IMAGE',
                        icon: 'edit',
                        onClick: uploadProfileImage
                    },
                    {
                        text: 'PROFILE.SETTINGS.IMAGE.DELETE_IMAGE',
                        icon: 'delete',
                        iconClass: 'red',
                        onClick: deleteProfileImage
                    }
                ], $event.currentTarget, null, {name: 'profile-settings-personal-information'});

                editImageActionSheet.show();
            }

            function uploadProfileImage() {
                $element.find('.upload').trigger('click');
            }

            function deleteProfileImage() {
                ctrl.model.image = null;
                ctrl.imageHasChanged = true;
            }

            function onImageUploaded($uploaded) {
                ctrl.model.image = $uploaded;
                ctrl.imageHasChanged = true;
            }
        }
    }
)();