(function () {
    'use strict';

    angular.module('App')
        .factory('ContentCreateService', ['RichMentionService', 'PopupWrapperService',
            function (RichMentionService, PopupWrapperService) {
                return {
                    openCreatePopup: openCreatePopup,
                    formatCreateData: formatCreateData,
                    formatEditData: formatEditData
                };

                function openCreatePopup(reloadItems, contentCreateData, token) {
                    PopupWrapperService.createDynamic('<content-create-popup ' +
                        'on-update="reloadItems()" ' +
                        'module-token="{{ token }}" ' +
                        'on-popup-registered="onPopupRegistered"' +
                        '></content-create-popup>', {
                        reloadItems: reloadItems,
                        onPopupRegistered: function (popup) {
                            popup.open(contentCreateData)
                        }
                    })
                }

                function formatCreateData(content, ctrl) {
                    const { userMentions, userGroupMentions, departmentMentions } = RichMentionService
                        .parseMentionsFromMultipleTexts(ctrl.content.selectedLanguages.map(lang => lang.Body));
                    let hashtags = [];

                    if (ctrl.settings.HashtagsEnabled && !ctrl.LimitedRteToolset) {
                        hashtags = RichMentionService.parseHashTagsFromMultipleTexts(content.selectedLanguages.map(lang => lang.Body));

                        if (hashtags.length) {
                            content.selectedLanguages = parseNewHashtags(content)
                        }
                    }

                    return {
                        Media: _.map(content.Media, function (media) {
                            return { MediaId: media.MediaId };
                        }),
                        Point: (content.Point !== null) ? content.Point : 0,
                        AccountModuleToken: ctrl.AccountModuleToken,
                        CategoryToken: ctrl.CategoryToken,
                        Hashtags: hashtags,
                        IgnoreContentSafetyWarning: ctrl.ignoreContentSafetyWarning,
                        Permissions: content.Permissions,
                        StartTime: content.StartTime ? moment(content.StartTime).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                        EndTime: content.EndTime ? moment(content.EndTime).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                        SendNotification: content.SendNotification,
                        SendPushNotification: content.SendNotification && content.SendPushNotification,
                        ForceInstantPush: content.ForceInstantPush,
                        TaggedUsers: userMentions,
                        TaggedDepartments: departmentMentions,
                        TaggedUserGroups: userGroupMentions,
                        DisableReactions: content.DisableReactions,
                        DisableComments: content.DisableComments,
                        ArchiveEnabled: content.ArchiveEnabled,
                        HideSwipeToConfirm: content.HideSwipeToConfirm,
                        OriginalCultureId: ctrl.content.selectedLanguages[0].CultureId,
                        LocaleList: ctrl.content.selectedLanguages
                            .filter(function (culture) {
                                return culture.Title && culture.Body
                            }).map(function (culture) {
                                return {
                                    CultureId: culture.CultureId,
                                    Title: culture.Title,
                                    BodyText: culture.Body,
                                    AutoTranslated: culture.AutoTranslated
                                }
                            })
                    }
                }

                function formatEditData(content, ctrl) {
                    const { userMentions, userGroupMentions, departmentMentions } =
                        RichMentionService
                            .parseMentionsFromMultipleTexts(ctrl.content.selectedLanguages.map(lang => lang.Body)) 
                    let hashtags = [];

                    if (ctrl.settings.HashtagsEnabled && !ctrl.LimitedRteToolset) {
                        hashtags = RichMentionService.parseHashTagsFromMultipleTexts(content.selectedLanguages.map(lang => lang.Body));

                        if (hashtags.length) {
                            content.selectedLanguages = parseNewHashtags(content)
                        }
                    }
                    
                    return {
                        Media: content.Media.map(media => ({ MediaId: media.MediaId })),
                        Point: (content.Point !== null) ? content.Point : 0,
                        Permissions: content.Permissions,
                        CultureId: _.get(content.Culture, 'CultureId'),
                        CategoryToken: ctrl.CategoryToken,
                        Hashtags: hashtags,
                        IgnoreContentSafetyWarning: ctrl.ignoreContentSafetyWarning,
                        StartTime: content.StartTime ? moment(content.StartTime).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                        EndTime: content.EndTime ? moment(content.EndTime).format('YYYY-MM-DD[T]HH:mm:ss') : null,
                        SendNotification: content.SendNotification,
                        SendPushNotification: content.SendNotification && content.SendPushNotification,
                        ContentToken: content.ContentToken,
                        TaggedUsers: userMentions,
                        TaggedDepartments: departmentMentions,
                        TaggedUserGroups: userGroupMentions,
                        DisableReactions: content.DisableReactions,
                        DisableComments: content.DisableComments,
                        ArchiveEnabled: content.ArchiveEnabled,
                        HideSwipeToConfirm: content.HideSwipeToConfirm,
                        OriginalCultureId: ctrl.content.selectedLanguages[0].CultureId,
                        LocaleList: ctrl.content.selectedLanguages
                            .filter(culture => culture.Title && culture.Body)
                            .map(culture => ({
                                CultureId: culture.CultureId,
                                Title: culture.Title,
                                BodyText: culture.Body,
                                AutoTranslated: culture.AutoTranslated
                            }))
                    }
                }

                function parseNewHashtags(model) {
                    return model.selectedLanguages.map(language => {
                        return {
                            ...language, 
                            Body: RichMentionService.parseNewHashtags(language.Body)
                        }
                    })
                }
            }]);
})();
