(function () {
    'use strict';
    var app = angular.module('App');

    app.directive('landingPageChart', [
        function () {
            return {
                restrict: 'E',
                templateUrl: '/Scripts/Components/LandingPage/Modules/Templates/landingpage-chart.tpl.html',
                scope: {
                    tile: '=',
                    page: '='
                },
                controller: ['$scope', '$element', '$attrs', '$rootScope', 'Page', '$http', 'ToastFactory', '$translate',
                    function ($scope, $element, $attrs, $rootScope, PageService, $http, ToastFactory, $translate) {

                        var localStorageFavoriteKey = 'LandingPageTileChartFavorite_[ID]';

                        // Init
                        InitDirective();
                        function InitDirective() {
                            $scope.loading = true;
                            $scope.Dashboard = null;
                            $scope.currentIsFavorite = false;
                            $scope.showAllTabs = ($scope.tile.Settings && $scope.tile.Settings.ShowAllTabs);

                            $scope.swiper = null;
                            $scope.swiperParams = {
                                pagination: {
                                    el: '.swiper-pagination',
                                    paginationType: 'bullets'
                                },
                                navigation: {
                                    nextEl: '.swiper-button-next',
                                    prevEl: '.swiper-button-prev',
                                }
                            };

                            LoadDashboard();
                        }

                        // Methods
                        function LoadDashboard() {
                            if ($scope.tile.AppLink && $scope.tile.AppLink.PathExtra && $scope.tile.AppLink.PathExtra.length > 0) {
                                $http.get('/Chart/GetCharts/' + $scope.tile.AppLink.PathExtra).then(function (response) {
                                    $scope.Dashboard = response.data.Dashboard;

                                    if ($scope.showAllTabs) {
                                        // Remove swipe if only 1 tab is present
                                        $scope.showAllTabs = ($scope.Dashboard.Tabs.length > 1);
                                        // Set favorite as first index
                                        var favorite = getFavorite();
                                        if (favorite >= 0) {
                                            $scope.swiperParams.initialSlide = favorite;
                                            $scope.currentIsFavorite = true;
                                        }
                                    }

                                    $scope.loading = false;
                                }, function () {
                                    $scope.loading = false;
                                });
                            } else {
                                $scope.loading = false;
                            }
                        }

                        function listenForEvents(swiper) {
                            $scope.swiper = swiper;

                            swiper.on('slideChange', function () {
                                $rootScope.safeApply(function () {
                                    var index = $scope.swiper.realIndex;
                                    $scope.currentIsFavorite = ($scope.Dashboard.Tabs[index].isFavorite);
                                });
                            });
                        }

                        function getFavorite() {
                            // We can only retrieve favorites if we have local storage
                            if (typeof (Storage) !== "undefined") {
                                var key = localStorageFavoriteKey.replace('[ID]', $scope.tile.LandingPageTileId);
                                var value = localStorage.getItem(key);
                                if (value) {
                                    var dashboardId = value.split('-')[0];
                                    var tabId = value.split('-')[1];

                                    for (var i = 0; i < $scope.Dashboard.Tabs.length; i++) {
                                        var tab = $scope.Dashboard.Tabs[i];
                                        if (tab.ChartDashboardId === parseInt(dashboardId) && tab.ChartDashboardTabId === parseInt(tabId)) {
                                            tab.isFavorite = true;
                                            return i;
                                        }
                                    }
                                }
                            }
                            return -1;
                        }

                        function setFavorite(tab, showToast) {
                            // We can only save it if we have local storage
                            if (typeof (Storage) !== "undefined") {
                                // Find Tab
                                if (tab) {
                                    var key = localStorageFavoriteKey.replace('[ID]', $scope.tile.LandingPageTileId);

                                    // We check if it is already saved as the favorite
                                    if (tab.isFavorite) {
                                        // It already is the favorite, so we will remove it
                                        localStorage.removeItem(key);

                                        // Remove current tab as favorite
                                        tab.isFavorite = false;
                                        $scope.currentIsFavorite = false;

                                        if (showToast) {
                                            ToastFactory.success('LANDINGPAGE.FAVORITE_REMOVED');
                                        }

                                        return;

                                    } else {
                                        // It is not the favorite, so we set it to be so
                                        var value = tab.ChartDashboardId + '-' + tab.ChartDashboardTabId
                                        localStorage.setItem(key, value);

                                        // Remove favorite from all tabs
                                        $scope.Dashboard.Tabs.forEach(function (t) {
                                            t.isFavorite = false;
                                        });
                                        // Set current tab as favorite
                                        tab.isFavorite = true;
                                        $scope.currentIsFavorite = true;

                                        if (showToast) {
                                            ToastFactory.success('LANDINGPAGE.FAVORITE_SAVED');
                                        }

                                        return;
                                    }
                                }
                            }

                            if (showToast) {
                                ToastFactory.error('LANDINGPAGE.FAVORITE_SAVE_FAILED');
                            }
                        }

                        function saveFavorite() {
                            if ($scope.swiper) {
                                var index = $scope.swiper.realIndex;
                                var tab = $scope.Dashboard.Tabs[index];
                                setFavorite(tab, true);
                            }
                        }

                        // Objects

                        // Bind scope
                        $scope.listenForEvents = listenForEvents;
                        $scope.saveFavorite = saveFavorite;
                    }
                ]
            }
        }
    ]);
})();