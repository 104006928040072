(function () {
    'use strict';

    angular
        .module('App')
        .component('fillingContent', {
            template: require('./FillingContentComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$http', '$scope', '$timeout', '$rootScope', 'ServiceFormRenderService', 'FillingPreviewService',
                'FeedbackCommentsService', 'ToastFactory', FillingContentController],
            bindings: {
                filling: '<',
                childFillings: '<',
                hasMedia: '<',
                isDraft: '<',
                showFeedbackOnly: '<',
                feedbackData: '<',
                showOriginal: '<',
                moduleToken: '@'
            }
        })

    function FillingContentController($http, $scope, $timeout, $rootScope, ServiceFormRenderService, FillingPreviewService,
                                      FeedbackCommentsService, ToastFactory) {
        const ctrl = this;

        ctrl.settings = ServiceFormRenderService.fillingInteractionSettings;
        ctrl.isFeedbackDrawingEnabled = ServiceFormRenderService.getFeedbackOptions().IsFeedbackDrawingEnabled;
        ctrl.getFeedbackIcon = ServiceFormRenderService.getRatingMediaById;
        ctrl.getRatingColorClass = ServiceFormRenderService.getFeedbackRatingColorClass;


        $scope.$on('fillingFeedbackUpdated', onFillingFeedbackUpdated);

        ctrl.$onInit = init;

        ctrl.getDateValue = getDateValue;
        ctrl.createSubTitles = createSubTitles;
        ctrl.openMap = openMap;
        ctrl.openGiveFeedback = openGiveFeedback;
        ctrl.openEditFeedback = openEditFeedback;
        ctrl.openFormFeedbackComments = openFormFeedbackComments;
        ctrl.replyComment = replyComment;
        ctrl.sendFeedbackToAuthor = sendFeedbackToAuthor;
        ctrl.onImageComment = onImageComment;
        ctrl.rateReport = rateReport;

        function init() {
            $timeout(() => {
                $rootScope.$broadcast('fillingPreviewRendered')
            }, 100)
        }

        function getDateValue(field) {
            var value = field.Values[0];
            if (value) {
                return value.DateTimeLocaleString;
            }

            return null;
        }

        function createSubTitles(dataListItem) {
            var subTitle = "";
            if (dataListItem.DataListType === 3 && dataListItem.AdditionalValues) // customer 
            {
                if (dataListItem.AdditionalValues.Street) {
                    subTitle += dataListItem.AdditionalValues.Street + "<br/>";
                }
                if (dataListItem.AdditionalValues.ZipCode) {
                    subTitle += dataListItem.AdditionalValues.ZipCode + " ";
                }
                if (dataListItem.AdditionalValues.City) {
                    subTitle += dataListItem.AdditionalValues.City
                }
            }
            return subTitle;
        }

        function openMap(event) {
            event.stopPropagation();
            ServiceFormRenderService
                .showMap(ctrl.filling.Latitude, ctrl.filling.Longitude);
        }

        function openGiveFeedback(field) {
            FillingPreviewService
                .showRatePopup('SERVICEFORM.FEEDBACK.GIVE_FEEDBACK',
                    (rating, message) => {
                        return FillingPreviewService
                            .createFeedback(ctrl.filling.ServiceFormFillingId, rating, message, field.ServiceFormFieldId)
                            .then(resp => {
                                field.FeedbackData.Feedback = resp.data.Feedback;
                                FillingPreviewService.getFillingStatus(ctrl.filling.ServiceFormFillingToken)
                                    .then(newStatus => ctrl.filling.ServiceFormFillingStatus = newStatus);
                            });
                    })
        }

        function openEditFeedback(field, isEdit) {
            FillingPreviewService
                .showRatePopup(isEdit ? 'SERVICEFORM.FEEDBACK.EDIT_RATING' : 'SERVICEFORM.FEEDBACK.GIVE_FEEDBACK',
                    (rate) => {
                        const path = '/ServiceForm/' + (rate !== null ? 'UpdateFeedback' : 'DeleteFeedback');

                        return $http.post(path, {
                            serviceFormFeedbackId: field.FeedbackData.Feedback.ServiceFormFeedbackId,
                            rating: rate
                        }).then((resp) => {
                            field.FeedbackData.Feedback = resp.data.Feedback;
                        })
                    }, true, field.FeedbackData.Feedback.Rating)
        }

        function openFormFeedbackComments(field) {
            FillingPreviewService.openFormFeedbackComments(
                field.FeedbackData.Feedback,
                null,
                null,
                field.ServiceFormFieldId,
                ctrl.filling.ServiceFormFillingId,
                ctrl.moduleToken
            )
        }

        function replyComment(replyingMessage, field) {
            FillingPreviewService.openFormFeedbackComments(
                field.FeedbackData.Feedback,
                replyingMessage,
                null,
                field.ServiceFormFieldId, ctrl.filling.ServiceFormFillingId,
                ctrl.moduleToken
            );
        }

        function onFillingFeedbackUpdated(event, feedbackData) {
            let field;

            for (let i = 0; i < ctrl.filling.Groups.length; i++) {
                field = ctrl.filling.Groups[i].Fields.find(field => field.ServiceFormFieldId === feedbackData.ServiceFormFieldId);
                if (field) {
                    field.FeedbackData = feedbackData;
                    break
                }
            }
        }

        function sendFeedbackToAuthor() {
            $http.post('/ServiceForm/InformAboutFeedback', {
                serviceFormFillingId: ctrl.filling.ServiceFormFillingId
            }).then(() => {
                ToastFactory.success('SERVICEFORM.FEEDBACK.WAS_SENT');
            })
        }

        function onImageComment({comment, media, field}) {
            return FeedbackCommentsService.postComment('/ServiceForm/CreateFeedbackComment', {
                ServiceFormFeedbackId: field.FeedbackData.Feedback && field.FeedbackData.Feedback.ServiceFormFeedbackId,
                ServiceFormFieldId: field.ServiceFormFieldId,
                ServiceFormFillingId: ctrl.filling.ServiceFormFillingId,
                Comment: comment,
                MediaList: media.MediaId
            }).then((resp) => {
                field.FeedbackData.Feedback = resp.data.Feedback;
                ToastFactory.success('COMMENTS.SAVED');
            });
        }

        function rateReport() {
           const averageScore = ServiceFormRenderService.getAverageFeedbackRating(ctrl.filling.Fields);

            FillingPreviewService.showRatePopup('SERVICEFORM.FEEDBACK.RATE_FORM', (rating, message) => {
                return FillingPreviewService
                    .createFeedback(ctrl.filling.ServiceFormFillingId, rating, message)
                    .then(resp => {
                        ctrl.feedbackData.FillingFeedback = resp.data
                        ctrl.filling.Rating = resp.data.Rating;
                        ctrl.filling.ServiceFormFeedbackRangeId = resp.data.ServiceFormFeedbackRangeId;
                        FillingPreviewService.getFillingStatus(ctrl.filling.ServiceFormFillingToken)
                            .then(newStatus => ctrl.filling.ServiceFormFillingStatus = newStatus);
                    })
            }, false, null, averageScore);
        }
    }
})();