(function () {
    'use strict';

    angular.module('App')
        .component('chat', {
            template: require('./ChatComponent.tpl.html'),
            controllerAs: 'ctrl',
            bindings: {
                newChannelData: '<',
                selectedChannelId: '<',
                messageId: '<',
            },
            controller: ['$rootScope', 'HeaderButtonsFactory', 'ChatPopupsService', 'ChatDataService',
                'ChatConnectionService', 'ActionSheetService', 'ResponsiveService', ChatController]
        });

    function ChatController($rootScope, HeaderButtonsFactory, ChatPopupsService, ChatDataService,
                            ChatConnectionService, ActionSheetService, ResponsiveService) {
        var ctrl = this;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.onChannelClose = onChannelClose;
        ctrl.reload = reload;

        function init() {
            ctrl.isLoaded = false;
            ctrl.isLoading = true;
            
            if (ctrl.newChannelData) {
                $rootScope.$broadcast('CHAT.OPEN_FROM_PUSH');
                ChatDataService
                    .createChannel([ctrl.newChannelData.user], ctrl.newChannelData.appLinkId, ctrl.newChannelData.hideAuthor)
                    .then(channelId => {
                        ChatConnectionService.openChannel(channelId, {}, onChannelClose)
                    })
            }

            if (ctrl.selectedChannelId) {
                $rootScope.$broadcast('CHAT.OPEN_FROM_PUSH');
                ChatConnectionService.openChannelFromPush(ctrl.selectedChannelId, ctrl.messageId, onChannelClose);
            }

            ChatDataService.getUserChannels().then(channelData => {
                if (!ResponsiveService.isDesktop()) {
                    HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                }
                ChatDataService.setBlockedIds(channelData.blockedUsers);
                ctrl.blockedUsers = channelData.blockedUsers;
                ctrl.userChannels = channelData.channels;
                ctrl.isLoaded = true;
                ctrl.isLoading = false;
            }, () => {
                ctrl.isLoading = false;
            })
        }

        function reload() {
            if (ctrl.isLoaded) {
                return;
            }

            // reconnect to chat as well if needed
            ChatConnectionService && !ChatConnectionService.isConnected() && ChatConnectionService.reconnect();

            ctrl.isLoading = true;
            ChatDataService.getUserChannels().then(channelData => {
                if (!ResponsiveService.isDesktop()) {
                    HeaderButtonsFactory.createButtonsList(getHeaderButtons);
                }
                ChatDataService.setBlockedIds(channelData.blockedUsers);
                ctrl.blockedUsers = channelData.blockedUsers;
                ctrl.userChannels = channelData.channels;
                ctrl.isLoaded = true;
                ctrl.isLoading = false;
            }, () => {
                ctrl.isLoading = false;
            })
        }

        function getHeaderButtons() {
            return [
                {
                    icon: 'options-app',
                    onClick: function () {
                        showOptions();
                    }
                },
                {
                    icon: 'chat-create',
                    onClick: function () {
                        openCreateChat();
                    }
                }
            ];
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
            ChatDataService.setBlockedIds(null);
            ctrl.listener && ctrl.listener();
        }

        function showOptions() {
            let buttons = [];
            if ($rootScope) {
                buttons.push({
                    text: 'CHAT.ACTIONSHEET.MARK_ALL_READ',
                    icon: 'comment-check',
                    onClick: function () {
                        markAllAsRead();
                    }
                })
            }

            function markAllAsRead() {
                ChatDataService.markAllChannelsAsRead()
                    .then(() => $rootScope.$broadcast('CHAT.MARK_ALL_CHANNELS_READ'));
            }

            const actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, {
                name: 'chat-channels',
            });
            actionSheet.show();
        }

        function onChannelClose(channelId, members) {
            if (channelId && members) {
                updateChannelMembers(channelId, members);
            }

            if (ctrl.userChannels) {
                const channel = ctrl.userChannels.find(channel => channel.item.channelId === channelId);
                markAsRead(null, channel)
            }
        }

        function updateChannelMembers(channelId, members) {            
            const updatedIds = members.map(member => member.userId);
        
            ctrl.userChannels = ctrl.userChannels.map(channel => 
                channel.item.channelId === channelId 
                    ? { 
                        ...channel, 
                        members, 
                        item: {...channel.item, memberIds: updatedIds} 
                    }
                    : channel
            );
        }

        function markAsRead($event, channel) {
            if (channel?.item) {
                $event && $event.stopPropagation();
                channel.item.unreadMessagesCount = 0;
                channel.item.forceUnread = false;
                ChatDataService.markChannelAsRead(channel.item.channelId);
            }
        }

        function openCreateChat() {
            ChatPopupsService.openCreateChatPopup();
        }
    }
})();