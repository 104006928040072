import { Component } from "@angular/core";

@Component({
    selector: 'app-root',
    template: `
        <div class="main-view" ng-class="{ 'is-fixed-layout': IsStaticLayout }" ui-view></div>`,
    // We use NgModule to bootstrap the hybrid cirkus. This can not be standalone
    // eslint-disable-next-line @angular-eslint/prefer-standalone
    standalone: false
})
export class AppComponent {}
