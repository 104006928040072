(() => {
    'use strict';
    angular
        .module('App')
        .config(['$stateProvider', function ($stateProvider) {
            $stateProvider.state('link', {
                parent: 'base',
                url: '/link/?PathState&PathToken&PathExtra',
                resolve: {
                    link: ['$stateParams', ($stateParams) => {
                        return $stateParams;
                    }]
                },
                controller: 'LinkController as LinkCtrl'
            })
        }])
        .controller('LinkController', ['ToastFactory', 'Page', 'link', function (ToastFactory, Page, link) {
            if (link.PathState) {
                Page.stateGo(link.PathState, link.PathToken, link.PathExtra);
            } else {
                Page.stateGo('home');
                ToastFactory.error('ERROR.PAGE_NOT_LOADED');
            }
        }]);
})();
