(() => {
    'use strict';

    angular
        .module('App')
        .component('contentLibraryItem', {
            template: require('./ContentLibraryItem.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['$element', '$timeout', '$scope', '$http', 'BookmarkService', 'ContentWrapperService',
                'RemindersService', 'MODULE_ID', 'AttachmentsViewerService', 'Page', 'Menu', 'NotificationFactory',
                'Profile', 'ContentLibraryService', 'HeaderButtonsFactory', 'GlobalSearchService',
                'MentionService', 'TranslationService', 'ToastFactory', contentLibraryItemController],
            bindings: {
                item: '<',
                contentData: '<',
            }
        });

    function contentLibraryItemController($element, $timeout, $scope, $http, BookmarkService, ContentWrapperService,
                                          RemindersService, MODULE_ID, AttachmentsViewerService, Page, Menu,
                                          NotificationFactory, Profile, ContentLibraryService, HeaderButtonsFactory,
                                          GlobalSearchService, MentionService, TranslationService, ToastFactory) {
        var ctrl = this,
            winHeight, docHeight, maxVal, vPos,
            $container = angular.element(window),
            $contentItem,
            loadSeenBy,
            isBookmarkUpdating = false;

        ctrl.BookmarkService = BookmarkService;
        ctrl.tocProgressHeight = 'auto';
        ctrl.openReminder = openReminder;
        ctrl.ShowComments = showComments;
        ctrl.ToggleBookmark = toggleBookmark;
        ctrl.OpenNext = openNext;
        ctrl.Swipe = swipe;
        ctrl.CalcStickyHeadHeight = calcStickyHeadHeight;
        ctrl.OpenTableOfContents = openTableOfContents;
        ctrl.CloseTableOfContents = closeTableOfContents;
        ctrl.scrollToSection = scrollToSection;
        ctrl.isArray = isArray;
        ctrl.goToFeed = goToFeed;
        ctrl.$onDestroy = destroy;
        ctrl.selectCurrentLocale = selectCurrentLocale;
        ctrl.toggleTranslation = toggleTranslation;

        ctrl.$onInit = function () {
            ctrl.item.TranslatableEntity._currentCulture = selectCurrentLocale(ctrl.item.TranslatableEntity);
            
            if (isTranslationAllowed()) {
                ctrl.showOriginal = !ctrl.item.TranslatableEntity.PreferTranslatedVersion;
            }

            initialize();
            ctrl.isFromSearch = Object.keys(ContentWrapperService.getSearchState()).length || Object.keys(GlobalSearchService.getSearchState()).length;
        };

        function isTranslationAllowed() {
            return ctrl.item.TranslatableEntity.AllowAutoTranslation ||
                (!ctrl.item.TranslatableEntity.AllowAutoTranslation && ctrl.item.TranslatableEntity.LocalizedAlternativeLocale);
        }

        function toggleTranslation() {
            if (ctrl.isTranslating) {
                return;
            }

            ctrl.isTranslating = true;

            const translatableEntities = {
                Post: ctrl.item.TranslatableEntity,
            }

            TranslationService.switchTranslatableLocaleContent(translatableEntities, ctrl.item.ContentId).then(res => {
                ctrl.item.TranslatableEntity = res.Post && res.Post;
                const bodyText = ctrl.item.TranslatableEntity._currentCulture.BodyText.replace(/id="isPasted"/gm, ''),
                    result = ContentLibraryService.prepareLibraryItem(bodyText);

                ctrl.item.BodyText = result.bodyText;
                ctrl.item.BodyHeaders = result.bodyHeaders;
                ctrl.showOriginal = !ctrl.showOriginal;
            })
                .catch(() => ToastFactory.error('TRANSLATION.CANT_TRANSLATE'))
                .finally(() => {
                    ctrl.isTranslating = false;
                });
        }

        function goToFeed() {
            ContentWrapperService.cleanSearchState();
            Page.stateGo('content', ctrl.contentData.AccountModuleToken, ctrl.item.ContentToken);
        }

        function selectCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
        }

        function initialize() {
            HeaderButtonsFactory.resetButtonsList();
            const bodyText = ctrl.item.TranslatableEntity._currentCulture.BodyText.replace(/id="isPasted"/gm, ''),
                result = ContentLibraryService.prepareLibraryItem(bodyText);

            ctrl.item.BodyText = result.bodyText;
            ctrl.item.BodyHeaders = result.bodyHeaders;

            if (ctrl.contentData?.Items) {
                const currentIndex = ctrl.contentData.Items.findIndex(t => t.ContentToken === ctrl.item.ContentToken);
                ctrl.nextItem = ctrl.contentData.Items[currentIndex + 1];
                
                if (ctrl.nextItem?.TranslatableEntity) {
                    ctrl.nextItem.TranslatableEntity._currentCulture = selectCurrentLocale(ctrl.nextItem.TranslatableEntity);
                }
            }

            ctrl.currentReadingSectionIndex = 0;
            ctrl.lastReadingSectionIndex = 0;
            closeTableOfContents();

            $timeout(function () {
                $contentItem = angular.element('content-library-item');
                docHeight = $contentItem.height();
                winHeight = $container.height();
                maxVal = docHeight - winHeight;
                vPos = ctrl.item.readingProgress * maxVal / 100;

                handleProgress();
                updateReadingSectionStatus();
                initImagesListener();

                if (!ctrl.item.IsSwiped) {
                    $container.scrollTop(vPos);
                }
            }, 200);
        }

        function showComments(item, event) {
            event && event.stopPropagation();
            ContentWrapperService.showCommentsModal(item, ctrl.contentData.Settings, null, ctrl.contentData.AccountModuleToken);
        }

        function toggleBookmark(item, event) {
            event && event.stopPropagation();

            if (!isBookmarkUpdating) {
                if (item.BookmarkId) {
                    // Delete
                    isBookmarkUpdating = true;
                    BookmarkService.deleteBookmark(item.BookmarkId).then(function (success) {
                        if (success) {
                            isBookmarkUpdating = false;
                            item.BookmarkId = null;
                        }
                    });
                } else {
                    // Create
                    isBookmarkUpdating = true;
                    BookmarkService.createContentBookmark(item.ContentToken).then(function (bookmarkId) {
                        if (bookmarkId) {
                            isBookmarkUpdating = false;
                            item.BookmarkId = bookmarkId;
                        }
                    });
                }
            }
        }

        function updateReadingProgress(val) {
            var progressPercent = parseInt(val > 95 ? 100 : val);

            if (ctrl.item.Swipe && (val > 95)) {
                progressPercent = parseInt(ctrl.item.isSwiped ? 100 : 95);
            }

            onChangeProgress(ctrl.item, progressPercent, progressPercent > ctrl.item.readingTotal ? progressPercent : ctrl.item.readingTotal);
        }

        function updateReadingSectionProgress() {
            angular.element('.article-section').each(function (index, item) {
                // track when header was scrolled
                if (angular.element(item).offset().top - winHeight - $container.scrollTop() <= 0) {
                    ctrl.currentReadingSectionIndex = index;
                    ctrl.lastReadingSectionIndex = index > ctrl.lastReadingSectionIndex ? index : ctrl.lastReadingSectionIndex;
                }

                if ($container.scrollTop() + winHeight > angular.element('content-library-item').height() - 70) {
                    ctrl.currentReadingSectionIndex = ctrl.item.BodyText.length - 1;
                    ctrl.lastReadingSectionIndex = ctrl.item.BodyText.length;
                }
            });
        }

        function updateReadingSectionStatus() {
            angular.element('.article-section').each(function (index, item) {
                if (angular.element(item).offset().top <= 0) {
                    ctrl.currentReadingSectionIndex = index;
                    ctrl.lastReadingSectionIndex = index > ctrl.lastReadingSectionIndex ? index : ctrl.lastReadingSectionIndex;
                }
            });
        }

        function handleProgress() {
            if (isScrollable()) {
                updateReadingProgress((ctrl.item.Swipe && !ctrl.item.IsSwiped) ? 95 : 100);
            } else {
                $container.on('scroll', _.throttle(calculateProgress, 70));
            }
        }

        function isScrollable() {
            return docHeight <= winHeight;
        }

        function calculateProgress() {
            if (!angular.element('content-library-item').height()) {
                return;
            }

            updateReadingProgress($container.scrollTop() / (angular.element('content-library-item').height() - winHeight) * 100);
            updateReadingSectionProgress();
            $scope.$apply();
        }

        function openNext(item) {
            const libraryData = {
                contentData: ctrl.contentData,
                item: item,
            }

            Page.stateGo('contentLibrary', item.ContentToken, '?AccountModuleToken=' + libraryData.contentData.AccountModuleToken, libraryData);
        }

        function swipe() {
            ctrl.lastReadingSectionIndex = ctrl.item.BodyText && ctrl.item.BodyText.length;

            if (ctrl.item.IsSwiped) {
                onChangeProgress(ctrl.item, 95, 95);
                return unswipeContent(ctrl.item);
            }

            updateReadingProgress(100);
            return swipeContent(ctrl.item);
        }

        function calcStickyHeadHeight() {
            return ctrl.showTableOfContents ? (70 + ctrl.item.BodyHeaders.length * 37) + 'px' : '70px';
        }

        function openTableOfContents() {
            ctrl.showTableOfContents = true;

            $timeout(function () {
                ctrl.tocProgressHeight = (angular.element('.content-list ul').height() - 28) + 'px';
                $container.addClass('no-overflow');
            });
        }

        function closeTableOfContents() {
            ctrl.showTableOfContents = false;
            ctrl.tocProgressHeight = 'auto';
            $container.removeClass('no-overflow');
        }

        function initImagesListener() {
            $element
                .find('.article-content')
                .on('click', 'img', function (e) {
                    var media = ContentWrapperService.generateMediaFromSource(e.currentTarget.src);
                    AttachmentsViewerService.openViewerWithMedia([media]);
                })
        }

        function openReminder(item, event) {
            event && event.stopPropagation();

            RemindersService.openSetReminders(
                ctrl.contentData.AccountModuleToken,
                item.ContentId,
                MODULE_ID.CONTENT,
                _.get(item, 'Reminder.ReminderDateTimeLocal') ?
                    moment(_.get(item, 'Reminder.ReminderDateTimeLocal')).toDate() : null,
                function (reminder) {
                    item.Reminder = reminder;
                })
        }

        function isArray(val) {
            return angular.isArray(val);
        }

        function scrollToSection(key) {
            var $article = angular.element('content-library-item article')[key];
            closeTableOfContents();

            $timeout(function () {
                $container.scrollTop($article.offsetTop - 120);
            }, 100);
        }

        function onChangeProgress(item, progress, total) {
            item.readingProgress = progress;
            item.readingTotal = total;

            if (ctrl.contentData.Settings.SeenByEnabled && !item.IsSeen && progress >= 80 && !loadSeenBy) {
                loadSeenBy = true;
                return $http.post('/ContentModule/MarkAsSeen', {
                    contentId: item.ContentId
                }).then(resp => {
                    ContentWrapperService.addContentToExclude(item.ContentId);
                    item.IsSeen = true;
                    updateBadges(resp.data);
                    loadSeenBy = false;
                });
            }

            ContentLibraryService.saveReadingProgress(ctrl.contentData.AccountModuleToken, item.ContentToken, {
                current: progress,
                total: total
            });
        }

        function swipeContent(content) {
            return $http.post('/ContentModule/SwipeContent', {
                contentId: content.ContentId
            }).then(resp => {
                ContentWrapperService.addContentToExclude(content.ContentId);
                content.IsSwiped = true;
                updateBadges(resp.data);
            })
        }

        function unswipeContent(content) {
            return $http.post('/ContentModule/Unswipe', {
                token: ctrl.item.ContentToken
            }).then(resp => {
                ContentWrapperService.removeContentIdFromExclude(content.ContentId);
                content.IsSwiped = false;
                updateBadges(resp.data);
            })
        }

        function updateBadges(data) {
            data.Subtract > 0 ? decreaseBagdes(data.Subtract) : increaseBagdes(data);

            NotificationFactory.markNotifications(data.NotificationStatus);
            Profile.reloadPoints();
        }

        function decreaseBagdes(subtract) {
            Menu.removeActivity(subtract, "contentOverview", ctrl.contentData.AccountModuleToken, ctrl.contentData.CategoryToken);
            Menu.removeLandingBadge(subtract, ctrl.contentData.NavigationBadge.AccountModuleId, ctrl.contentData.NavigationBadge.ModuleId, ctrl.contentData.NavigationBadge.ModuleItemId);
        }

        function increaseBagdes({AddBadge, NavigationBadge}) {
            if (!AddBadge) return;

            Menu.addActivity(1, "contentOverview", ctrl.contentData.AccountModuleToken, ctrl.contentData.CategoryToken);
            Menu.addLandingBadge(1, NavigationBadge.AccountModuleId, NavigationBadge.ModuleId, NavigationBadge.ModuleItemId);
        }

        function destroy() {
            $container.off('scroll');
        }
    }
})();