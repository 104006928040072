import {Component, EventEmitter, Input, Output} from '@angular/core';
import {type RelesysIconPrefixesType, type RelesysIconsType} from "../icon/icons";
import {RlIconDirective} from "../icon/relesys-icon.directive";

@Component({
    selector: 'rl-button',
    template: require('./relesys-button.component.html'),
    imports: [RlIconDirective],
})
export class RlButtonComponent {
    @Input() type = 'standard';
    @Input() disabled = false;
    @Input() text?: string;
    @Input() icon?: RelesysIconsType
    @Input() iconPosition?: 'left' | 'right';
    @Input() iconPrefix?: RelesysIconPrefixesType;

    // eslint ignore - lets just stick to "click" since we consume this in lots of places
    // in angularJs. We should update this.
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click = new EventEmitter<void>();

    handleClick() {
        if (!this.disabled) {
            this.click.emit();
        }
    }
}
