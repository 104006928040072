(function () {
    'use strict';

    angular
        .module('App')
        .factory('DepartmentSwapService', [
            'Profile', '$http', '$q', '$filter',
            'ToastFactory', 'PopupWrapperService', '$rootScope', DepartmentSwapService]);

    function DepartmentSwapService(Profile, $http, $q, $filter,
        ToastFactory, PopupWrapperService, $rootScope) {

        var departments = [],
            loadingPromises = [],
            isLoaded = false,
            isLoading = false,
            isDropdownVisible = false,
            selectedDepartmentId = null;

        return {
            loadDepartments: loadDepartments,
            getDepartments: () => departments,
            changeDepartment: changeDepartment,
            isEnabled: isEnabled,
            openPopup: openPopup,
            isLoading: () => isLoading,
            isLoaded: () => isLoaded,
            toggleDropdownVisibility: toggleDropdownVisibility,
            getDropdownVisibility: () => isDropdownVisible
        };

        function loadDepartments() {
            var d = $q.defer();

            loadingPromises.push(d);

            if (!isLoading) {
                isLoading = true;
                $http.get('/Profile/GetMultipleDepartments')
                    .then((response) => {
                        departments = mapData(response.data);
                        isLoaded = true;
                        isLoading = false;
                        loadingPromises.forEach(p => {
                            p.resolve(true);
                        });
                        loadingPromises = [];
                    })
                    .catch(() => {
                        isLoading = false;
                        loadingPromises.forEach(p => {
                            p.resolve(false);
                        });
                        loadingPromises = [];
                    });
            }

            return d.promise;
        }

        function mapData(data) {
            if (!data || !data.Departments || !data.Departments.length) {
                return [];
            }

            var result = [{
                title: $filter('translate')('PROFILE.DEPARTMENT_SWAP.ALL'),
                id: null,
                selected: data.CurrentFilter === null
            }];

            data.Departments.forEach(o => {
                result.push({
                    title: o.Name,
                    id: o.DepartmentId,
                    selected: data.CurrentFilter == o.DepartmentId
                });
            });

            selectedDepartmentId = data.CurrentFilter;
            return result;
        }

        function refreshSelection() {
            departments.forEach(o => {
                o.selected = selectedDepartmentId == o.id
            });
        }

        function changeDepartment(departmentId) {
            var d = $q.defer();

            if (departmentId !== selectedDepartmentId) {
                isLoading = true;
                Profile.setSelectedMultipleDepartment(departmentId, true)
                    .then(() => {
                        selectedDepartmentId = departmentId;
                        refreshSelection();
                        isLoading = false;
                        ToastFactory.success('PROFILE.DEPARTMENT_SWAP.UPDATED');
                        d.resolve();
                    })
                    .catch(() => {
                        refreshSelection();
                        isLoading = false;
                        ToastFactory.error('ERROR.GENERAL');
                        d.reject();
                    })
            } else {
                d.resolve();
            }

            return d.promise;
        }

        function isEnabled() {
            return Profile.getProfile().HasMultiDepartment;
        }

        function toggleDropdownVisibility(visible) {
            if (_.isBoolean(visible)) {
                isDropdownVisible = visible;
            } else {
                isDropdownVisible = !isDropdownVisible;
            }

            if (isDropdownVisible && !isLoaded) {
                loadDepartments();
            } else {
                refreshSelection();
            }
        }

        function openPopup() {
            PopupWrapperService.createDynamic(
                '<department-swap-popup ' +
                'on-close="onClose" ' +
                'on-popup-registered="registeredCallback"></department-swap-popup>',
                {
                    registeredCallback: function (popup) {
                        popup.open();
                    },
                    onClose: function () {
                        $rootScope.disableVisibilitychange = false;
                    }
                }
            )
        }
    }
})();