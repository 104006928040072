(() => {
    'use strict';

    angular
        .module('App')
        .component('pinCodeBiometricSetup', {
            template: require('./PinCodeBiometricSetupComponent.html'),
            controller: ['PinCodeService', 'ToastFactory', PinCodeBiometricSetupController],
            controllerAs: 'ctrl',
            bindings: {
                isCompleted: '<',
                userToken: '<',
                pinCode: '<',
                onClose: '&'
            }
        });

    function PinCodeBiometricSetupController(PinCodeService, ToastFactory) {
        var ctrl = this;
        ctrl.$onInit = init;

        ctrl.onSheetClose = onSheetClose;
        ctrl.onConfirm = onConfirm;
        ctrl.onSkip = onSkip;
        ctrl.onCloseConfirm = onCloseConfirm;

        function init(){
            ctrl.showConfirm = !ctrl.isCompleted;
            ctrl.showSuccess = !ctrl.showConfirm;
        }

        function onSheetClose(){
            ctrl.onClose && ctrl.onClose();
        }

        function onConfirm(){
            PinCodeService.saveBiometricPin(ctrl.userToken, ctrl.pinCode).then(() => {
                ctrl.showConfirm = false;
                ctrl.showSuccess = true;
            }, (err) => {
                if (err.isCancelEvent) {
                    ToastFactory.error('PIN.BIOMETRIC.ERROR.SAVE_CANCELLED');
                    return;
                } else if (err.errorCode) {
                    ToastFactory.error(err.errorCode);
                    return;
                } else if (err.errorMessage) {
                    ToastFactory.error(err.errorMessage);
                    return;
                }
                
                ToastFactory.error('ERROR.GENERAL');
                return;
            })
        }

        function onSkip(){
            PinCodeService.optOutOfSavingBiometric(ctrl.userToken);
            ctrl.showConfirm = false;
            onSheetClose();
        }

        function onCloseConfirm(){
            onSheetClose();
        }
    }
})();