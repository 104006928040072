(function () {
    'use strict';

    angular
        .module('Relesys.Ui')
        .factory('ActionSheetCustomService', ['$rootScope', '$compile', 'ToastFactory', 'ResponsiveService', ActionSheetCustomService]);

    function ActionSheetCustomService($rootScope, $compile, ToastFactory, ResponsiveService) {

        return {
            create: create
        };

        function create(component, bindings, container) {
            ToastFactory.clear();
            
            const scope = Object.assign($rootScope.$new(true), bindings || {}),
                actionSheet = $compile(component)(scope);

            const appendContainer = ResponsiveService.isDesktop() && container ? container : 'body';

            angular.element(appendContainer).append(actionSheet);
        }
    }
})();