(() => {
    angular.module('Relesys.Ui')
        .component('rlSelect', {
            template: require('./SelectComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$document', '$timeout', '$element', '$window', 'ResponsiveService', 'ToastFactory', RlSelectController],
            require: {
                ngModelCtrl: 'ngModel'
            },
            bindings: {
                options: '<',
                placeholder: '<',
                ngDisabled: '=',
                customKey: '@',
                parentElementSelector: '@',
                showLock: '<',
                onChange: '&'
            }
        })

    function RlSelectController($scope, $document, $timeout, $element, $window, ResponsiveService, ToastFactory) {
        const ctrl = this;
        let container;

        ctrl.$onInit = init;
        ctrl.onShowOptions = onShowOptions;
        ctrl.getSelectedOption = getSelectedOption;
        ctrl.selectOption = selectOption;

        function init() {
            ctrl.customKey = ctrl.customKey || 'id';
            ctrl.isDesktop = ResponsiveService.isDesktop();
            ctrl.showOptions = false;
            ctrl.render = false;

            $scope.$watch(function () {
                return ctrl.ngModelCtrl.$viewValue
            }, (option) => {
                if (option) {
                    const prevOption = ctrl.options.find(opt => opt.selected),
                        curOption = ctrl.options.find(opt => opt[ctrl.customKey] === option[ctrl.customKey]);

                    if (prevOption) {
                        delete prevOption.selected;
                    }
                    if (curOption) {
                        curOption.selected = true;
                    }
                }
            });
        }

        function onShowOptions() {
            if (ctrl.ngDisabled) {
                hide();
                return;
            }

            if (ctrl.showOptions) {
                hide();
            } else {
                if (!ctrl.render) {
                    ctrl.render = true;
                }

                ctrl.showOptions = true;

                if (!ctrl.isDesktop) {
                    ToastFactory.clear();
                }

                $timeout(() => {
                    container = $element.find('.scroll-container');
                    if (ctrl.parentElementSelector) {
                        const elementRect = $element[0]?.getBoundingClientRect();
                        const heightAboveButton = document.querySelector(ctrl.parentElementSelector)?.offsetHeight - elementRect.bottom;
                        const dropdownHeights = container[0]?.offsetHeight;
                        ctrl.displayOnTop = heightAboveButton < dropdownHeights;
                    }

                    !ctrl.isDesktop && bodyScrollLock.disableBodyScroll(container);
                    $document.on('click', hideOptions)
                    $window.addEventListener('keypress', keyClicked);
                })
            }
        }

        function getSelectedOption() {
            return ctrl.ngModelCtrl.$viewValue
        }

        function selectOption(option) {
            hide();
            if (ctrl.ngDisabled) {
                return;
            }
            ctrl.ngModelCtrl.$setViewValue(option);

            if (ctrl.onChange) {
                ctrl.onChange();
            }
        }

        function hide() {
            !ctrl.isDesktop && bodyScrollLock.enableBodyScroll(container);
            ctrl.showOptions = false
            $document.off('click', hideOptions);
            $window.removeEventListener('keypress', keyClicked);
        }

        function hideOptions(ev) {
            if (!angular.element(ev.target).parents('rl-select').length) {
                ev.preventDefault();
                !ctrl.isDesktop && bodyScrollLock.enableBodyScroll(container);
                $document.off('click', hideOptions);
                $window.removeEventListener('keypress', keyClicked);
                $scope.$apply(() => {
                    ctrl.showOptions = false;
                })
            }
        }

        var keyClickedTimer = null;
        var keysClicked = [];

        function keyClicked(ev) {
            try {
                const key = ev.key.toLowerCase();
                keysClicked.push(key);

                clearTimeout(keyClickedTimer);
                keyClickedTimer = setTimeout(() => {
                    var results = ctrl.options;

                    keysClicked.every((v, i) => {
                        const minLength = i + 1;
                        var subResults = results.filter(o => {
                            return (o.fullName && o.fullName.length >= minLength && o.fullName[i].toLowerCase() == v)
                                || (o.name && o.name.length >= minLength && o.name[i].toLowerCase() == v);
                        });

                        if (subResults.length) {
                            results = subResults;
                            return true;
                        }

                        return false;
                    });

                    if (results.length && results[0].id) {
                        var resultElement = $element.find('[data-option-id="' + results[0].id + '"]');
                        if (resultElement) {
                            resultElement.focus();
                        }
                    }

                    keysClicked = [];
                }, 250);
            } catch {
            }
        }
    }
})();