(() => {
    'use strict';

    angular
        .module('Relesys.Ui')
        .component('rlSearchResultItem', {
            template: require('./SearchResultItemComponent.html'),
            controller: ['GLOBAL_SEARCH_ITEM_LABEL', SearchResultItemController],
            controllerAs: 'ctrl',
            bindings: {
                item: '<'
            }
        });

    function SearchResultItemController(GLOBAL_SEARCH_ITEM_LABEL) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.getIcon = getIcon;
        ctrl.isCategory = isCategory;
        ctrl.isCatalog = isCatalog;
        ctrl.isSocial = isSocial;

        function init() {
            ctrl.GLOBAL_SEARCH_ITEM_LABEL = GLOBAL_SEARCH_ITEM_LABEL;
            ctrl.entityClass = getEntityClass();
        }

        function getIcon() {
            switch (ctrl.item.EntityLink.EntityType) {
                case 'Content.Post':
                    return 'newspaper';
                case 'Content.Library':
                    return 'library';
                case 'Catalog.Item':
                case 'Catalog.Category':
                    return 'folder-open'
                case 'Social.Post':
                    return 'comments'
            }
        }

        function isSocial() {
            return ctrl.item?.EntityLink.EntityType === 'Social.Post';
        }

        function isCategory() {
            return ctrl.item?.EntityLink.EntityType === 'Catalog.Category';
        }

        function isCatalog() {
            return ctrl.item?.EntityLink.EntityType === 'Catalog.Category' ||
                   ctrl.item?.EntityLink.EntityType === 'Catalog.Item';
        }

        function getEntityClass() {
            return ctrl.item.EntityLink.EntityType
                .toLowerCase()
                .split('.')
                .join('-')
        }
    }
})();