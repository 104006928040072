import {RELESYS_ICONS} from "./icons";

(function () {
    'use strict';

    angular.module('Relesys.Ui')
        .constant('RELESYS_ICONS', RELESYS_ICONS)
        .component('rlIcon', {
            template: require('./RelesysIconComponent.tpl.html'),
            controllerAs: 'ctrl',
            controller: ['RELESYS_ICONS', RlIconController],
            bindings: {
                prefix: '@',
                icon: '@',
                iconClass: '@',
                svg: '@'
            }
        });

    function RlIconController(RELESYS_ICONS) {
        const ctrl = this;

        ctrl.className = className;

        function className() {
            const
                prefix = ctrl.prefix || RELESYS_ICONS[ctrl.icon]?.prefix || 'fal',
                icon = RELESYS_ICONS[ctrl.icon]?.icon + (ctrl.iconClass ? ' ' + ctrl.iconClass : '');

            return prefix + ' ' + icon;
        }
    }
})();